import * as AppServiceUtils from './AppServices.utils';
import * as ResolveResponse from './ResolveResponse.utils';
import * as CookieUtils from './Cookie.utils';
import GISTool from './GIS.utils';

import * as WidgetUtils from './Widget.utils';

export default {
  ...AppServiceUtils,
  ...ResolveResponse,
  ...CookieUtils,
  ...WidgetUtils,
  GISTool,
};
