import { CommonColors } from '@/Themes';
import { SxProps, Theme } from '@mui/system';

export const mainStyles: SxProps<Theme> = {
  width: 1,
  height: '100%',
  position: 'relative',
};

export const mapContainerStyles: SxProps<Theme> = {
  position: 'relative',
  height: '100%',
  '& .map-content': {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: 1,
  },
};

