import { ACTION_TYPES } from '@constants';

const initialState = {
  allCloundFiles: [],
};

export default (
  state = initialState,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.REFRESH_DOWNLOAD_LIST:
      return {
        ...state,
        allCloundFiles: [],
      };

    case ACTION_TYPES.FETCH_ALL_DOWNLOAD_LIST_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: true,
        allCloundFiles: payload,
      };
    case ACTION_TYPES.FETCH_ALL_DOWNLOAD_LIST_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
      };
    default:
      return state;
  }
};
