export default {
  AUTH: {
    LOGIN: 'auth/login',
    CHANGE_PASSWORD: 'auth/changePassword',
    FORGOT_PASSWORD: 'auth/forgot-password',
    RESET_PASSWORD: 'auth/reset-password',
    REFRESH_TOKEN: 'auth/refresh',
    VERIFY_USER: 'auth/verify-account',
  },
  USER: {
    FETCH: 'users/',
    GET_BY_ID: 'users/get-by-id/',
    GET_SELF: 'users/get-for-self',
    UPDATE: 'users/update/',
    UPDATE_SELF: 'users/update-for-self',
    UPDATE_SELF_PASSWORD: 'users/update-password-for-self',
    ACTIVE: 'users/activate/',
    DEACTIVE: 'users/deactivate/',
    REGISTER: 'users/register-account',
    DELETE: 'users/delete/'
  },
  ROLE: {
    ROOT: 'roles/',
    GET_BY_ID: 'roles/get-by-id/',
    ACTIVE: 'roles/activate/',
    DEACTIVE: 'roles/deactivate/'
  },
  GEOJSON: {
    ROOT: 'geojsons/',
    GET_BY_ID: 'geojsons/get-by-id/',
    ACTIVE: 'geojsons/activate/',
    DEACTIVE: 'geojsons/deactivate/'
  },
  GEOJSON_GROUP: {
    ROOT: 'groups/',
    GET_BY_ID: 'groups/get-by-id/',
    ACTIVE: 'groups/activate/',
    DEACTIVE: 'groups/deactivate/'
  },
};
