import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import {
  Container,
  Typography,
  Grid,
  CircularProgress,
} from '@mui/material';
import { CommonColors } from '@/Themes';
import { AuthActions } from '@actions';
import { useTypedDispatch } from '@/store';
import { ROUTERS } from '@constants';
import Utils from '@/Utils';

const { verifyAccount } = AuthActions;

const VerifyAccount: React.FC = () => {
  // Constructors
  const queryParams = useLocation().search;
  const dispatch = useTypedDispatch();
  const [message, setMessage] = useState('Checking information...');

  useEffect(() => {
    if (!queryParams) Utils.redirect(ROUTERS.AUTH);
    const params = _.split(queryParams, '&');
    const email = _.replace(params[0], '?e=', '');
    const code = _.replace(params[1], 'c=', '');
    if (email && code) {
      dispatch(verifyAccount({ email, code }));
    } else {
      setMessage(
        'Your link has been expired! System will redirect after 5 seconds...'
      );
      Utils.sleep(5000).then(() => Utils.redirect(ROUTERS.AUTH));
    }
  }, []);

  // Events
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      bgcolor={CommonColors.mainBackground}
    >
      <Grid item>
        <Container maxWidth="xs" sx={{ padding: 3 }}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <CircularProgress />
            </Grid>
            <Grid item>
              <Typography variant="h4">{message}</Typography>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};

export default VerifyAccount;
