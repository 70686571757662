import React from 'react';
import _ from 'lodash';
import { Box, Grid, CssBaseline } from '@mui/material';

import {
  portalStyles,
  landingStyles,
  mainStyles,
} from './DefaultLayout.styles';

import { AppBarComponent, SideBarComponent } from '../LayoutPart';
import Widgets from '../Widgets';

// import Utils from '@utils';

export type LAYOUT_MODE = 'LANDING' | 'PORTAL';

interface SectionProps {
  content: JSX.Element;
  mode?: LAYOUT_MODE;
  currentPage?: string;
  screenTitle?: string;
}

// Declare utils
// const { checkAppMode } = Utils;
const DefaultLayout: React.FC<SectionProps> = (props: SectionProps) => {
  // Declare all props
  const { content, mode } = props;
  // Declare Reducers, Dispatch, Themes
  // Declare states

  // Landing Page
  const _renderLandingLayout = () => (
    <Box sx={landingStyles}>
      <AppBarComponent />
      <Box sx={{ display: 'flex', width: 1, height: '100%' }}>
        <CssBaseline />
        <SideBarComponent />
        {content}
      </Box>
    </Box>
  );

  // Potal page
  const _renderPortalLayout = () => (
    <Box sx={portalStyles}>
      <AppBarComponent />
      <Grid container>
        <Grid item xs={0} md={2} xl={3} lg={3} />
        <Grid item xs={12} md={10} xl={9} lg={9}>
          {content}
        </Grid>
        <Grid item xs={0} md={1} xl={2} lg={2} />
      </Grid>
    </Box>
  );

  const renderPotal = () =>
    mode === 'PORTAL' ? _renderPortalLayout() : _renderLandingLayout();

  return (
    <Box sx={mainStyles}>
      {renderPotal()}
      <Widgets.Notification />
      <Widgets.Loading />
      <Widgets.Alert />
    </Box>
  );
};

export default DefaultLayout;
