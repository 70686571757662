import { ROUTERS } from '@constants';
import { MainContainer } from '@containers';

import ProtectedRoute from './Protected.router';

const UserRouter = {
  path: ROUTERS.HOME,
  children: [
    {
      path: ROUTERS.HOME,
      element: (
        <ProtectedRoute location={ROUTERS.HOME}>
          <MainContainer.GISDashboard />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.AUTH,
      element: <MainContainer.Authentication />,
    },
    {
      path: ROUTERS.FORGOT_PASSWORD,
      element: <MainContainer.ForgotPassword />,
    },
    {
      path: ROUTERS.CHANGE_PASSWORD,
      element: <MainContainer.ResetPassword />,
    },
    {
      path: ROUTERS.VERIFY_ACCOUNT,
      element: <MainContainer.VerifyAccount />,
    },
    {
      path: ROUTERS.VERIFY_ACCOUNT_FAIL,
      element: <MainContainer.VerifyAccountFail />,
    },
    {
      path: ROUTERS.VERIFY_ACCOUNT_SUCCESS,
      element: <MainContainer.VerifyAccountSuccess />,
    },
  ],
};

export default UserRouter;
