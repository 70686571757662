interface ActionTypes {
  [key: string]: string;
}

const generateSyncActions = (actionList: string[]) => {
  const map: { [key: string]: string } = {};
  actionList.map((action) => {
    const name = action.trim();
    if (name !== '') {
      map[`${name}_SUCCESS`] = `${name}_SUCCESS`;
      map[`${name}_FAILURE`] = `${name}_FAILURE`;
    }
  });
  return map;
};

const _asyncActions: ActionTypes = generateSyncActions([
  'LOGIN',
  'REFRESH_TOKEN',
  'RESET_PASSWORD',
  'REQUEST_FORGOT_PASSWORD',
  'GET_SELF_PROFILE',
  'UPDATE_SELF_PASSWORD',
  'UPDATE_SELF_PROFILE',
  'FETCH_USERS',
  'GET_USER_BY_ID',
  'CREATE_USER',
  'UPDATE_USER',
  'DEACTIVE_USER',
  'ACTIVE_USER',
  'FETCH_GEOJSONS',
  'GET_GEOJSON_BY_ID',
  'UPDATE_GEOJSON',
  'UPLOAD_GEOJSON',
  'ACTIVE_GEOJSON',
  'DEACTIVE_GEOJSON',
  'FETCH_ALL_GEOJSONS',
  'FETCH_GROUPS',
  'GET_GROUP_BY_ID',
  'CREATE_GROUP',
  'UPDATE_GROUP',
  'DEACTIVE_GROUP',
  'ACTIVE_GROUP',
  'FETCH_ALL_GROUP',
  'FETCH_ALL_DOWNLOAD_LIST',
  'VERIFY_ACCOUNT',
  'DELETE_ACCOUNT',
]);

const _singleActions: ActionTypes = {
  // Locale actions
  SET_LOCALE: 'SET_LOCALE',
  // Dev tool actions
  SET_SELECTED_TOOL: 'SET_SELECTED_TOOL',
  // Widget actions
  SET_NOTIFICATION_MESSAGE: 'SET_NOTIFICATION_MESSAGE',
  SET_START_LOADING: 'SET_START_LOADING',
  SET_LOADING_INSIDE_COMPONENT: 'SET_LOADING_INSIDE_COMPONENT',
  SET_ALERT_MESSAGE: 'SET_ALERT_MESSAGE',
  // Authentication actions
  LOGOUT: 'LOGOUT',
  KEEP_LOGGED: 'KEEP_LOGGED',

  // Sidebar actions
  SET_SIDEBAR_MEASURE: 'SET_SIDEBAR_MEASURE',
  SET_MEASURE_STATUS: 'SET_MEASURE_STATUS',
  SET_SIDEBAR_VALUE: 'SET_SIDEBAR_VALUE',
  // GISDashboard actions
  SET_GIS_LAYERS: 'SET_GIS_LAYERS',
  SET_TABLE_ATTRIBUTES_DATA: 'SET_TABLE_ATTRIBUTES_DATA',
  SET_SELECTED_GIS_LAYERS: 'SET_SELECTED_GIS_LAYERS',
  SET_MOVE_TO_COORDINATES: 'SET_MOVE_TO_COORDINATES',
  SET_WMS_LAYERS: 'SET_WMS_LAYERS',
  RESET_GIS_LAYERS: 'RESET_GIS_LAYERS',
  // Users actions
  SET_USER_PAGINATION: 'SET_USER_PAGINATION',
  SET_USER_META: 'SET_USER_META',
  RESET_USER_REDUCER: 'RESET_USER_REDUCER',
  SET_USER_PROFILE: 'SET_USER_PROFILE',
  // Geojson actions
  SET_GEOJSON_PAGINATION: 'SET_GEOJSON_PAGINATION',
  SET_GEOJSON_META: 'SET_GEOJSON_META',
  RESET_GEOJSON_REDUCER: 'RESET_GEOJSON_REDUCER',
  SET_GEOJSON_DETAIL: 'SET_GEOJSON_DETAIL',
  SET_GEOJSON_DOWNLOADED: 'SET_GEOJSON_DOWNLOADED',
  SET_GEOJSON_DOWNLOADING: 'SET_GEOJSON_DOWNLOADING',
  // Group actions
  SET_GROUP_PAGINATION: 'SET_GROUP_PAGINATION',
  SET_GROUP_META: 'SET_GROUP_META',
  RESET_GROUP_REDUCER: 'RESET_GROUP_REDUCER',
  SET_GROUP_DETAIL: 'SET_GROUP_DETAIL',
  // Download actions
  REFRESH_DOWNLOAD_LIST: 'REFRESH_DOWNLOAD_LIST',
};

const ACTION_TYPES = {
  ..._asyncActions,
  ..._singleActions,
};

export default ACTION_TYPES;
