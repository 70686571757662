import 'leaflet-measure';
import 'leaflet-measure/dist/leaflet-measure.css';

import { useMemo, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useMap, ScaleControl } from 'react-leaflet';
import { createControlComponent } from '@react-leaflet/core';
import * as L from 'leaflet';
// import ReactToPrint from 'react-to-print';

import { ButtonGroup, Button, MenuItem, Menu, Box } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
// import PrintIcon from '@mui/icons-material/Print';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import LayersRoundedIcon from '@mui/icons-material/LayersRounded';
import StraightenIcon from '@mui/icons-material/Straighten';

import { AutocompleteInput } from '@/Components/Common';

import { controlStyles } from './GISParts.styles';

import { GIS_TYPES } from '@constants';
import { RootState } from '@/store';

// Declare constants
const { TITLE_LAYERS, POSITION_GIS_CLASSES } = GIS_TYPES;

interface IInteractiveControls {
  titleLayer: string;
  onChangeTitle(value: string): void;
  starterCoords: [Lat: number, Lng: number];
}

const MeasureControl = createControlComponent((props) => {
  const measureControl: L.Control = new L.Control.Measure(props);
  measureControl.setPosition('bottomleft');
  return measureControl;
});

// const PrintControl = createControlComponent((props) => {
//   const printControl: L.Control = new L.Control.easyPrint({
//     // tileLayer: tiles,
//     sizeModes: ['Current', 'A4Landscape', 'A4Portrait'],
//     // filename: 'myMap',
//     // exportOnly: true,
//     hideControlContainer: true,
//   });
//   printControl.setPosition('bottomleft');
//   return printControl;
// });

const MeasureControlEvents = () => {
  const map = useMap();

  map.on('measurefinish', (e) => {
    console.log(e);
  });

  map.on('measurestart', (e) => {
    console.log(e);
  });

  map.on('click', (e) => {
    console.log(e);
  });
  return null;
};

const ZoomControls = () => {
  const parentMap = useMap();

  const zoomControls = useMemo(() => {
    return (
      <ButtonGroup>
        <Button
          title="Zoom In"
          sx={controlStyles}
          onClick={() => {
            parentMap.zoomIn();
          }}
        >
          <ZoomInIcon />
        </Button>
        <Button
          title="Zoom Out"
          sx={controlStyles}
          onClick={() => {
            parentMap.zoomOut();
          }}
        >
          <ZoomOutIcon />
        </Button>
      </ButtonGroup>
    );
  }, []);

  return (
    <div className={POSITION_GIS_CLASSES.topleft}>
      <div className="leaflet-control leaflet-bar">{zoomControls}</div>
    </div>
  );
};

const SearchControl = () => {
  return (
    <div
      className={POSITION_GIS_CLASSES.topright}
      style={{ marginRight: '14em' }}
    >
      <div className="leaflet-control leaflet-bar">
        <Box sx={{ width: 250 }}>
          <AutocompleteInput
            data={[{ title: 'demo', value: 'text' }]}
            placeholder="Search..."
          />
        </Box>
      </div>
    </div>
  );
};

const ScaleControls = () => {
  return useMemo(() => <ScaleControl position="bottomright" />, []);
};

const ToggleControls = (props: IInteractiveControls) => {
  const { titleLayer, onChangeTitle, starterCoords } = props;
  const parentMap = useMap();
  const measureControlEl = useRef(null);
  // const printControlEL = useRef(null);
  const measureOptions = useSelector((state: RootState) =>
    _.get(state.SIDEBAR, 'measures')
  );
  const isEditMeasure = useSelector((state: RootState) =>
    _.get(state.SIDEBAR, 'isEditMeasure')
  );
  const [anchorTitleEl, setAnchorTitleEl] = useState<null | HTMLElement>(null);
  const isOpenTitleMenu = Boolean(anchorTitleEl);

  const measureControl = useMemo(() => {
    return <MeasureControl {...measureOptions} ref={measureControlEl} />;
  }, [measureOptions]);

  // const printControl = useMemo(() => {
  //   return <PrintControlDefault sizeModes={['Current', 'A4Portrait', 'A4Landscape']} hideControlContainer={false} />;
  // }, [measureOptions]);

  // Events
  const handleClickTitleLayers = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorTitleEl(event.currentTarget);
  };

  // Renders
  const _renderTitles = () => {
    return (
      <Menu
        anchorEl={anchorTitleEl}
        open={isOpenTitleMenu}
        onClose={() => setAnchorTitleEl(null)}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        {_.map(TITLE_LAYERS, (title, key) => {
          return (
            <MenuItem
              key={title}
              selected={titleLayer === title}
              onClick={() => onChangeTitle(title)}
              sx={{ textTransform: 'capitalize' }}
            >
              {key.replace('_', ' ').toLowerCase()}
            </MenuItem>
          );
        })}
      </Menu>
    );
  };

  return (
    <div
      className={POSITION_GIS_CLASSES.topleft}
      style={{ marginTop: '3.5em' }}
    >
      <div className="leaflet-control leaflet-bar">
        <ButtonGroup>
          <Button
            title="Basemaps"
            sx={controlStyles}
            onClick={(e) => handleClickTitleLayers(e)}
          >
            <LayersRoundedIcon />
          </Button>
          <Button
            title="Measure"
            sx={controlStyles}
            onClick={() => {
              if (measureControlEl) measureControlEl?.current?.$toggle?.click();
            }}
            disabled={isEditMeasure}
          >
            <StraightenIcon />
          </Button>
          <Button
            title="Home"
            sx={controlStyles}
            onClick={() => {
              parentMap.flyTo(starterCoords, 8);
            }}
          >
            <HomeIcon />
          </Button>
          {/* <Button
            title="Print"
            sx={controlStyles}
            onClick={() => {
              console.log(printControlEL);
              printControlEL?.current?.click();
            }}
          >
            <PrintIcon />
          </Button> */}
        </ButtonGroup>
        {_renderTitles()}
        {!isEditMeasure && measureControl}
        {/* <ReactToPrint
          trigger={() => <a ref={printControlEL} />}
          content={() => parentMap}
        /> */}
        {/* <TileLayer url={titleLayer} /> */}
      </div>
    </div>
  );
};

export default {
  ToggleControls,
  MeasureControlEvents,
  ZoomControls,
  ScaleControls,
  SearchControl,
};
