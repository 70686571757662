import { SxProps, Theme } from '@mui/system';
import { CommonColors } from '@/Themes';

export const mainStyles: SxProps<Theme> = {
  height: '100vh',
  position: 'relative',
  // background: CommonColors.mainBackground,
  '& ::-webkit-scrollbar': {
    height: 5,
    width: 5,
    cursor: 'pointer',
    backgroundColor: CommonColors.loblolly,
  },
  '& ::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.2)',
  },
  '& ::-webkit-scrollbar-thumb': {
    backgroundColor: CommonColors.mainBackground,
    outline: 'none',
  },
};

export const portalStyles: SxProps<Theme> = {
  flexGrow: 1,
  display: 'flex',
};

export const landingStyles: SxProps<Theme> = {
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100%'
};
