import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import {
  TextField,
  MenuItem,
  Divider,
  Grid,
  Typography,
  InputAdornment,
  Button,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import RegisterDialog from '../RegisterDialog';

import { LIST, ENUMS } from '@constants';
import { useTypedDispatch, RootState } from '@/store';
import { UserManagementActions } from '@actions';
import { IPaginationFilter } from '@interfaces/PaginationMeta.interface';
import Utils from '@/Utils';

// Actions
const { setUserPagination, fetchUsers } = UserManagementActions;
const { ROLES, STATUS } = ENUMS;

interface SectionProps {}

const UserManagementBar: React.FC<SectionProps> = () => {
  // Constructors
  const dispatch = useTypedDispatch();
  const paginationFilter: IPaginationFilter = useSelector((state: RootState) =>
    _.get(state.USER_MANAGEMENT, 'pagination')
  );
  const [open, setOpen] = useState(false);
  const userRole = Utils.getUserRole() || ROLES.USER;

  useEffect(() => {}, []);

  // Events

  // Renders
  const _renderMenu = () => {
    return _.map(LIST.FILTER_LIST.USER_SORT_FILTERS, (option, index) => (
      <MenuItem
        key={index}
        value={option.value}
        sx={{ textTransform: 'capitalize' }}
      >
        {option.label}
      </MenuItem>
    ));
  };

  const _renderFilterOptions = () => {
    return (
      <Grid container spacing={3} justifyContent="space-around">
        <Grid item xs={12}>
          <TextField
            fullWidth
            size="small"
            label="Keywords...."
            value={paginationFilter?.keyword}
            onChange={(e) => {
              dispatch(
                setUserPagination({
                  ...paginationFilter,
                  keyword: e.target.value,
                })
              );
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {/* <Grid item xs={6}>
          <TextField
            fullWidth
            size="small"
            label="Date from"
            value={paginationFilter?.dateFrom}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            size="small"
            label="Date to"
            value={paginationFilter?.dateTo}
            disabled
          />
        </Grid> */}
        <Grid item xs={6}>
          <TextField
            fullWidth
            select
            size="small"
            label="Sort By"
            value={paginationFilter?.sortBy}
            onChange={(e) => {
              dispatch(
                setUserPagination({
                  ...paginationFilter,
                  sortBy: e.target.value,
                })
              );
            }}
          >
            {_renderMenu()}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            select
            size="small"
            label="Order By"
            value={paginationFilter?.orderBy}
            onChange={(e) => {
              dispatch(
                setUserPagination({
                  ...paginationFilter,
                  orderBy: e.target.value,
                })
              );
            }}
          >
            <MenuItem value="ASC">ASC</MenuItem>
            <MenuItem value="DESC">DESC</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            select
            size="small"
            label="Status"
            value={paginationFilter?.status}
            onChange={(e) => {
              const status = e.target.value;
              dispatch(
                setUserPagination({
                  ...paginationFilter,
                  status,
                })
              );
            }}
          >
            <MenuItem value={'all'}>All</MenuItem>
            <MenuItem value={ENUMS.STATUS.ACTIVE}>Active</MenuItem>
            <MenuItem value={ENUMS.STATUS.INACTIVE}>Inactive</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={12}>
          <Button
            startIcon={<SearchIcon />}
            onClick={() => {
              Utils.dispatchRequests([dispatch(fetchUsers(paginationFilter))]);
            }}
            variant="contained"
            color="burntSienna"
          >
            Apply Now
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container direction="column" paddingX={2}>
      <RegisterDialog open={open} handleClose={() => setOpen(false)} />
      <Grid item sx={{ mb: 2 }}>
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs={1}>
            <FilterAltIcon />
          </Grid>
          <Grid item xs={8}>
            <Typography component="h1" variant="button">
              Filters:
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>{_renderFilterOptions()}</Grid>
      <Divider variant="middle" sx={{ marginY: 2 }} />
      <Grid item sx={{ mb: 2 }}>
        <Grid container alignItems="center" spacing={3}>
          {userRole === ROLES.SUPER_ADMIN && (
            <Grid item xs={12}>
              <Button
                fullWidth
                startIcon={<PersonAddIcon />}
                onClick={() => {
                  setOpen(true);
                }}
                variant="contained"
                color="oceanGreen"
              >
                New User
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserManagementBar;
