import _ from 'lodash';
import { Position } from 'geojson';
import API from '@/APIs';
import { ACTION_TYPES, ENUMS } from '@constants';
import {
  IGeoJsonObjectCollection,
  IGeojsonOptions,
  IWMSLayer,
} from '@interfaces/GIS.interface';
import { ILayersSettings } from '@interfaces/Menu.interface';
import Utils from '@utils';

// SINGLE ACTIONS
const setGISLayers = (payload: IGeojsonOptions[]) => {
  return {
    type: ACTION_TYPES.SET_GIS_LAYERS,
    payload,
  };
};

const setTableAttributeData = (payload: IGeoJsonObjectCollection | null) => {
  return {
    type: ACTION_TYPES.SET_TABLE_ATTRIBUTES_DATA,
    payload,
  };
};

const setSelectedGISLayers = (payload: ILayersSettings[] | null) => {
  return {
    type: ACTION_TYPES.SET_SELECTED_GIS_LAYERS,
    payload,
  };
};

const setMoveToTargetCoordinates = (payload: Position | null) => {
  return {
    type: ACTION_TYPES.SET_MOVE_TO_COORDINATES,
    payload,
  };
};

const setGeojsonDownloaded = (payload: IGeoJsonObjectCollection[] | null) => {
  return {
    type: ACTION_TYPES.SET_GEOJSON_DOWNLOADED,
    payload,
  };
};

const setWMSLayers = (payload: IWMSLayer[]) => {
  return {
    type: ACTION_TYPES.SET_WMS_LAYERS,
    payload,
  };
};

// ASYNC ACTIONS
const fetchSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_ALL_GEOJSONS_SUCCESS,
    payload,
  };
};

const fetchFail = () => {
  return {
    type: ACTION_TYPES.FETCH_ALL_GEOJSONS_FAILURE,
  };
};

const fetchAllLayers = () => {
  return async (dispatch: any) => {
    await API.fetchGroups({
      page: 0,
      limit: 0,
      status: ENUMS.STATUS.ACTIVE,
      orderBy: 'ASC',
      sortBy: 'name',
    })
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchFail());
        else {
          const items = _.get(result, 'items');
          const resolveResult = Utils.GISTool.generateGeojsonOptions(items);
          dispatch(fetchSuccess(resolveResult));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(fetchFail());
      });
  };
};

export default {
  setGISLayers,
  setTableAttributeData,
  setSelectedGISLayers,
  setMoveToTargetCoordinates,
  fetchAllLayers,
  setGeojsonDownloaded,
  setWMSLayers,
};
