import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Breadcrumbs, Typography, Tab, Box } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FolderIcon from '@mui/icons-material/Folder';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import _ from 'lodash';

import DefaultLayout from '@/Components/DefaultLayout';
import {
  GeojsonManagementTable,
  GeojsonInformationDialog,
  GroupTable,
  GroupInformationDialog,
  DownloadGeojsons
} from '@/Components/LayoutPart';

import {
  GFileManagementActions,
  GroupActions,
  DownloadActions,
} from '@actions';
import { useTypedDispatch, RootState } from '@/store';
import Utils from '@/Utils';

// Declare constants
const { fetchGeojsons, resetGeojsonReducer, setGeojsonDetail } =
  GFileManagementActions;
const { fetchGroups, resetGroupReducer, setGroupDetail } = GroupActions;
const { fetchAllDownloadList, refreshDownloadList } = DownloadActions;

const GfileManagement: React.FC = () => {
  // Constructors
  const dispatch = useTypedDispatch();
  const pagination = useSelector((state: RootState) =>
    _.get(state.GEOJSON_MANAGEMENT, 'pagination')
  );
  const geojsonDetail = useSelector((state: RootState) =>
    _.get(state.GEOJSON_MANAGEMENT, 'geojsonDetail')
  );
  const groupPagination = useSelector((state: RootState) =>
    _.get(state.GROUP, 'pagination')
  );
  const groupDetail = useSelector((state: RootState) =>
    _.get(state.GROUP, 'groupDetail')
  );
  const [value, setValue] = useState<'files' | 'groups' | 'downloads'>('files');

  useEffect(() => {
    switch (value) {
      case 'files': {
        Utils.dispatchRequests(
          [
            dispatch(fetchGeojsons(pagination)),
            dispatch(refreshDownloadList()),
          ],
          true
        );
        break;
      }
      case 'groups': {
        Utils.dispatchRequests(
          [
            dispatch(fetchGroups(groupPagination)),
            dispatch(refreshDownloadList()),
          ],
          true
        );
        break;
      }
      case 'downloads': {
        Utils.dispatchRequests([dispatch(fetchAllDownloadList())], true);
        break;
      }
    }
  }, [value]);

  useEffect(() => {
    return () => {
      dispatch(resetGeojsonReducer());
      dispatch(resetGroupReducer());
      dispatch(refreshDownloadList());
    };
  }, []);

  // Events
  const handleChange = (
    _event: React.SyntheticEvent,
    newValue: 'files' | 'groups'
  ) => {
    setValue(newValue);
  };

  // Renders
  const renderMain = () => {
    return (
      <Grid container direction="column" padding={2} pb={2} flexWrap="nowrap">
        <GeojsonInformationDialog
          open={Boolean(geojsonDetail)}
          handleClose={() => {
            dispatch(setGeojsonDetail(null));
          }}
        />
        <GroupInformationDialog
          open={Boolean(groupDetail)}
          handleClose={() => {
            dispatch(setGroupDetail(null));
          }}
        />
        <Grid item mb={2}>
          <Breadcrumbs>
            <Typography variant="h5">Geojsons Management</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item mb={2}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange}>
                <Tab
                  label="Files"
                  value="files"
                  icon={<InsertDriveFileIcon />}
                  iconPosition="start"
                />
                <Tab
                  label="Groups"
                  value="groups"
                  icon={<FolderIcon />}
                  iconPosition="start"
                />
                <Tab
                  label="Downloads"
                  value="downloads"
                  icon={<CloudDownloadIcon />}
                  iconPosition="start"
                />
              </TabList>
            </Box>
            <TabPanel value="files">
              <GeojsonManagementTable />
            </TabPanel>
            <TabPanel value="groups">
              <GroupTable />
            </TabPanel>
            <TabPanel value="downloads">
              <DownloadGeojsons />
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
    );
  };

  return <DefaultLayout mode="LANDING" content={renderMain()} />;
};

export default GfileManagement;
