import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import {
  TextField,
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  MenuItem,
  Button,
  Divider,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { TransitionSlide } from '@/Components/Common';
import { useTypedDispatch, RootState } from '@/store';
import { GFileManagementActions, GroupActions } from '@actions';
import Utils from '@/Utils';
import { IUpdateGeojson, ISublayers } from '@interfaces/Geojson.interface';

// Actions
const { updateGeojson, fetchGeojsons, setGeojsonDetail } =
  GFileManagementActions;
const { fetchAllGroups } = GroupActions;

interface SectionProps {
  open: boolean;
  handleClose(): void;
}
const NEW_LAYER_FORM = {
  name: '',
  key: '',
  value: '',
  color: '',
  descriptions: '',
};

type FORM_KEYS = 'name' | 'key' | 'value' | 'color' | 'descriptions';
const EXCLUDE_KEYS = ['createdAt', 'updatedAt', 'status'];

const GeojsonInformationDialog: React.FC<SectionProps> = (
  props: SectionProps
) => {
  // Constructors
  const { open, handleClose } = props;
  const dispatch = useTypedDispatch();
  const isLoading = useSelector((state: RootState) =>
    _.get(state.WIDGET, 'isLoading')
  );
  const pagination = useSelector((state: RootState) =>
    _.get(state.GEOJSON_MANAGEMENT, 'pagination')
  );
  const geojsonDetail: IUpdateGeojson = useSelector((state: RootState) =>
    _.get(state.GEOJSON_MANAGEMENT, 'geojsonDetail')
  );
  const allGroups: any =
    useSelector((state: RootState) => _.get(state.GROUP, 'allGroups')) || [];
  const [sublayers, setSublayers] = useState<ISublayers[]>([]);
  const [listSublayerRemove, setListSublayerRemove] = useState<string[]>([]);
  const [groupId, setGroupId] = useState<string>('');
  const [fileType, setFileType] = useState<string>('');
  const [parentColor, setParentColor] = useState<string>('');

  useEffect(() => {
    if (geojsonDetail) {
      let extraData = null;
      if (geojsonDetail?.extraData)
        extraData = JSON.parse(geojsonDetail.extraData);
      setSublayers(geojsonDetail?.sublayer || []);
      setFileType(_.get(extraData, 'type'));
      setParentColor(_.get(extraData, 'color'));
      setGroupId(_.get(geojsonDetail?.groupId, 'id') || '');
      Utils.dispatchRequests([dispatch(fetchAllGroups())], true);
    }
  }, [geojsonDetail]);

  // Events
  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (geojsonDetail) {
      const formData = new FormData();
      _.map(sublayers, (sub, index) => {
        if (_.has(sub, 'id')) {
          _.map(sub, (val, key) => {
            if (val && !_.isBoolean(val) && !_.includes(EXCLUDE_KEYS, key))
              formData.append(`listSublayerUpdate[${index}][${key}]`, val);
          });
        } else {
          _.map(sub, (val, key) => {
            if (val && !_.isBoolean(val))
              formData.append(`listSublayerCreate[${index}][${key}]`, val);
          });
        }
      });
      _.map(listSublayerRemove, (val) => {
        formData.append(`listSublayerRemove`, val);
      });
      formData.append(`groupId`, groupId);
      formData.append(
        `extraData`,
        JSON.stringify({ type: fileType, color: parentColor })
      );
      Utils.dispatchRequests(
        [dispatch(updateGeojson(formData, geojsonDetail.id))],
        true
      );
    }
  };

  const addMoreSublayer = () => {
    setSublayers([...sublayers, NEW_LAYER_FORM]);
  };

  const removeLayer = (index: number) => {
    const currentIds = [...listSublayerRemove];
    const newLayers = _.filter(sublayers, (e, i) => {
      if (_.has(e, 'id') && e?.id && i === index) {
        currentIds.push(e.id);
      }
      return i !== index;
    });
    setListSublayerRemove(currentIds);
    setSublayers(newLayers);
  };

  const onChangeForm = (
    key: FORM_KEYS,
    value: string,
    index: number,
    original: ISublayers
  ) => {
    const newPayload = { ...original, [key]: value };
    const newLayers = [...sublayers];
    newLayers[index] = newPayload;
    setSublayers(newLayers);
  };

  const onClose = () => {
    if (isLoading) return null;
    setSublayers([]);
    setListSublayerRemove([]);
    dispatch(setGeojsonDetail(null));
    Utils.dispatchRequests([dispatch(fetchGeojsons(pagination))], true);
    return handleClose();
  };

  // Renders
  const _renderFormLayer = (layer: ISublayers, index: number) => {
    return (
      <Grid
        container
        spacing={2}
        justifyContent="space-around"
        key={index}
        sx={{ py: 2, borderBottom: '1px solid' }}
      >
        <Grid item xs={12}>
          <TextField
            key={`${index}name`}
            defaultValue={layer.name}
            fullWidth
            disabled={!!isLoading}
            label="Sub-layers name"
            helperText="Name will display"
            required
            size="small"
            onChange={(e) => {
              onChangeForm('name', e.target.value, index, layer);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            key={`${index}key`}
            defaultValue={layer.key}
            fullWidth
            disabled={!!isLoading}
            label="Key"
            helperText="Name of column"
            required
            size="small"
            onChange={(e) => {
              onChangeForm('key', e.target.value, index, layer);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            key={`${index}value`}
            defaultValue={layer.value}
            fullWidth
            disabled={!!isLoading}
            label="Value"
            helperText="Value of Key"
            required
            size="small"
            onChange={(e) => {
              onChangeForm('value', e.target.value, index, layer);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            key={`${index}color`}
            defaultValue={layer.color}
            fullWidth
            disabled={!!isLoading}
            label="Color"
            helperText="Color of Sub-Layer"
            placeholder="#00000, black, etc,..."
            required
            size="small"
            onChange={(e) => {
              onChangeForm('color', e.target.value, index, layer);
            }}
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            key={`${index}descriptions`}
            defaultValue={layer.descriptions}
            fullWidth
            disabled={!!isLoading}
            label="Descriptions"
            size="small"
            onChange={(e) => {
              onChangeForm('descriptions', e.target.value, index, layer);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Button
            startIcon={<RemoveCircleIcon />}
            onClick={() => removeLayer(index)}
            variant="contained"
            disabled={!!isLoading}
            fullWidth
            color="fuzzyWuzzyBrown"
          >
            Remove
          </Button>
        </Grid>
        {index + 1 === sublayers.length && (
          <Grid item xs={4}>
            <Button
              startIcon={<AddIcon />}
              onClick={addMoreSublayer}
              variant="contained"
              disabled={!!isLoading}
              fullWidth
              color="oceanGreen"
            >
              More sub-Layer
            </Button>
          </Grid>
        )}
      </Grid>
    );
  };

  const _renderSublayers = () => {
    if (_.isEmpty(sublayers))
      return (
        <Button
          startIcon={<AddIcon />}
          onClick={addMoreSublayer}
          variant="contained"
          disabled={!!isLoading}
          fullWidth
          color="burntSienna"
        >
          Add Sub-Layer
        </Button>
      );
    return _.map(sublayers, (layer, index) => _renderFormLayer(layer, index));
  };

  const _renderForm = () => {
    return (
      <Grid container spacing={3} justifyContent="space-around">
        <Grid
          item
          xs={12}
          sx={{
            height: 400,
            p: 2,
            overflow: 'auto',
          }}
        >
          {_renderSublayers()}
        </Grid>
      </Grid>
    );
  };

  const _renderGroups = () => {
    return _.map(allGroups, (g, index) => {
      return (
        <MenuItem key={index} value={g?.id}>
          {g?.name}
        </MenuItem>
      );
    });
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => onClose()}
      TransitionComponent={TransitionSlide}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>Sublayers</DialogTitle>
      <form onSubmit={submitForm}>
        <DialogContent sx={{ overflow: 'unset' }}>
          {geojsonDetail && _renderForm()}
          <Divider sx={{ mb: 1, mt: 1 }} />
          <TextField
            sx={{ mb: 1, mt: 1 }}
            key="type"
            fullWidth
            select
            required
            value={fileType}
            disabled={!!isLoading}
            label="File type"
            onChange={(e) => {
              setFileType(e.target.value);
            }}
            size="small"
          >
            <MenuItem value="" disabled>
              -- Select File Type --
            </MenuItem>
            <MenuItem value={'line'}>Line</MenuItem>
            <MenuItem value={'point'}>Point</MenuItem>
            <MenuItem value={'polygon'}>Polygon</MenuItem>
          </TextField>
          <TextField
            sx={{ mb: 1 }}
            key="color"
            fullWidth
            placeholder="For example: red, #6096BA, rgb(123,211,155),..."
            value={parentColor}
            disabled={!!isLoading}
            label="Color"
            onChange={(e) => {
              setParentColor(e.target.value);
            }}
            size="small"
          />
          <TextField
            key="groupId"
            fullWidth
            select
            required
            value={groupId}
            disabled={!!isLoading}
            label="Groups"
            onChange={(e) => {
              setGroupId(e.target.value);
            }}
            size="small"
          >
            {_renderGroups()}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<CloseIcon />}
            onClick={() => onClose()}
            variant="contained"
            disabled={!!isLoading}
            color="fuzzyWuzzyBrown"
          >
            Close
          </Button>
          <LoadingButton
            type="submit"
            startIcon={<SaveIcon />}
            loading={isLoading}
            variant="contained"
            color="burntSienna"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default GeojsonInformationDialog;
