import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import {
  TextField,
  MenuItem,
  Divider,
  Grid,
  Typography,
  IconButton,
} from '@mui/material';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import SaveIcon from '@mui/icons-material/Save';
import { useTypedDispatch, RootState } from '@/store';
import { SidebarActions } from '@actions';
import StraightenIcon from '@mui/icons-material/Straighten';

import { LIST } from '@constants';

// Actions
const { setSidebarMeasure, setMeasureStatus } = SidebarActions;

interface SectionProps {}

const { MEASURE_LIST } = LIST.MENU_LIST;

const MeasureComponent: React.FC<SectionProps> = () => {
  // Constructors
  const dispatch = useTypedDispatch();
  const measures = useSelector((state: RootState) =>
    _.get(state.SIDEBAR, 'measures')
  );
  const isEditMeasure = useSelector((state: RootState) =>
    _.get(state.SIDEBAR, 'isEditMeasure')
  );

  useEffect(() => {}, []);

  // Events
  const handleSelectChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: string
  ) => {
    dispatch(setSidebarMeasure({ ...measures, [key]: event.target.value }));
  };

  // Renders
  const _renderMenu = (key: string, type: 'length' | 'area') => {
    let list = MEASURE_LIST.LENGTH_UNITS;
    if (type === 'area') list = MEASURE_LIST.AREA_UNITS;
    return _.map(list, (option, index) => (
      <MenuItem
        key={`${key}${index}`}
        value={option}
        sx={{ textTransform: 'capitalize' }}
      >
        {option}
      </MenuItem>
    ));
  };

  const _renderEditMeasure = () => {
    return (
      <Grid container spacing={3} justifyContent="space-around">
        <Grid item xs={6}>
          <TextField
            fullWidth
            select
            size="small"
            label="Primary length unit"
            value={measures?.primaryLengthUnit}
            onChange={(e) => handleSelectChange(e, 'primaryLengthUnit')}
            disabled={!isEditMeasure}
          >
            {_renderMenu('primaryLengthUnit', 'length')}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            select
            size="small"
            label="Secondary length unit"
            value={measures?.secondaryLengthUnit}
            onChange={(e) => handleSelectChange(e, 'secondaryLengthUnit')}
            disabled={!isEditMeasure}
          >
            {_renderMenu('secondaryLengthUnit', 'length')}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            select
            size="small"
            label="Primary area unit"
            value={measures?.primaryAreaUnit}
            onChange={(e) => handleSelectChange(e, 'primaryAreaUnit')}
            disabled={!isEditMeasure}
          >
            {_renderMenu('primaryAreaUnit', 'area')}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            select
            size="small"
            label="Secondary area unit"
            value={measures?.secondaryAreaUnit}
            onChange={(e) => handleSelectChange(e, 'secondaryAreaUnit')}
            disabled={!isEditMeasure}
          >
            {_renderMenu('secondaryAreaUnit', 'area')}
          </TextField>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container direction="column" paddingX={2}>
      <Grid item sx={{ mb: 2 }}>
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs={1}>
            <StraightenIcon />{' '}
          </Grid>
          <Grid item xs={8}>
            <Typography component="h1" variant="button">
              Measure:
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              onClick={() => dispatch(setMeasureStatus(!isEditMeasure))}
            >
              {!isEditMeasure ? <AppRegistrationIcon /> : <SaveIcon />}
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>{_renderEditMeasure()}</Grid>
      <Divider variant="middle" sx={{ marginY: 2 }} />
      {/* <Grid item sx={{ mb: 2 }}>
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs={1}>
            <PolylineIcon />{' '}
          </Grid>
          <Grid item xs={8}>
            <Typography component="h1" variant="button">
              Draw:
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              onClick={() => dispatch(setMeasureStatus(!isEditMeasure))}
            >
              {!isEditMeasure ? <AppRegistrationIcon /> : <SaveIcon />}
            </IconButton>
          </Grid>
        </Grid>
      </Grid> */}
      {/* <Grid item>Draw config sections</Grid> */}
    </Grid>
  );
};

export default MeasureComponent;
