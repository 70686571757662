import { CommonColors } from '@/Themes';
import { SxProps, Theme } from '@mui/system';

export const mainStyles: SxProps<Theme> = {
  background: 'white',
  color: CommonColors.bismark,
  width: 1,
  position: 'absolute',
  bottom: 0,
  zIndex: 1000,
  transition: 'all 0.25s',
  height: 'max-content',
  overflow: 'hidden',
};

export const tableStyles = (height: number): SxProps<Theme> => {
  return {
    color: CommonColors.bismark,
    overflow: 'auto',
    transition: 'all 0.25s',
    height: height - 100,
  };
};

export const gridStyles: SxProps<Theme> = {
  px: 2,
  pb: 1,
  position: 'relative',
};

export const resizeStyles: SxProps<Theme> = {
  position: 'sticky',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1001,
  textAlign: 'center',
  height: 15
};
