import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Typography, Box, LinearProgress } from '@mui/material';

import { useTypedDispatch, RootState } from '@/store';
import { GIS_TYPES } from '@constants';
import { IGeoJsonObjectCollection } from '@interfaces/GIS.interface';
import { ILayersSettings } from '@interfaces/Menu.interface';
import { GISActions } from '@actions';
import Utils from '@/Utils';

const { POSITION_GIS_CLASSES } = GIS_TYPES;

const { setGeojsonDownloaded } = GISActions;

const DownloadControl = () => {
  // Constructors
  const dispatch = useTypedDispatch();
  const selectedLayers: ILayersSettings[] =
    useSelector((state: RootState) =>
      _.get(state.GIS_DASHBOARD, 'selectedLayers')
    ) || [];
  const geojsonDownloaded: IGeoJsonObjectCollection[] =
    useSelector((state: RootState) =>
      _.get(state.GIS_DASHBOARD, 'geojsonDownloaded')
    ) || [];
  const [process, setProcess] = useState(0);
  const [message, setMessage] = useState('');
  const [listDownload, setListDownload] = useState<ILayersSettings[]>([]);

  const calculatePercent = (step: number, totalStep: number) => {
    return new Promise((resolve) => {
      let count = 0;
      const process = setInterval(() => {
        if (count <= 1) {
          step += 0.01;
          count += 0.01;
          const newPerent = ((step / totalStep) * 100).toFixed(0);
          setProcess(parseFloat(newPerent));
        }
        if (count > 1) {
          clearInterval(process);
          resolve(true);
        }
      }, 10);
    });
  };

  const downloadProgress = async () => {
    let currentStep = 0;
    const totalStep = listDownload.length + 1;
    const results = await Promise.all(
      _.map(listDownload, async (list) => {
        setMessage(`Start downloading file: ${list.parentLayer.layerName}`);
        await calculatePercent(currentStep, totalStep);
        currentStep += 1;
        const geojsonDownloaded = await Utils.GISTool.downloadGeojson(list.url);
        if (geojsonDownloaded) {
          setMessage(`Initialization layer: ${list.parentLayer.layerName}`);
          const geojson = await Utils.GISTool.convertToCRS84(
            geojsonDownloaded,
            list.id
          );
          await calculatePercent(currentStep, totalStep);
          currentStep += 1;
          return geojson;
        }
        setMessage(`Cannot download layer: ${list.parentLayer.layerName}`);
        await calculatePercent(currentStep, totalStep);
        currentStep += 1;
        return null;
      })
    );
    const removeNullGeojson = _.compact(results);
    if (currentStep === totalStep) {
      setListDownload([]);
      setProcess(0);
      dispatch(setGeojsonDownloaded(removeNullGeojson));
    }
  };

  useEffect(() => {
    if (_.isEmpty(selectedLayers)) {
      dispatch(setGeojsonDownloaded([]));
      return;
    }
    const list: ILayersSettings[] = [];
    _.forEach(selectedLayers, (layer) => {
      const { id, parentLayer } = layer;
      const isDownloaded = _.find(geojsonDownloaded, ['id', id]);
      if (!isDownloaded && parentLayer.isChecked) list.push(layer);
    });
    if (_.isEmpty(list)) setListDownload([]);
    else setListDownload(list);
  }, [selectedLayers]);

  useEffect(() => {
    if (listDownload.length > 0) downloadProgress();
  }, [listDownload]);

  // Renders
  const renderProgress = () => {
    return (
      <Box
        className={POSITION_GIS_CLASSES.topleft}
        sx={{
          marginTop: 'auto',
          marginRight: 'auto',
          top: 0,
          bottom: 0,
          width: 1,
          background: 'rgba(0,0,0, 0.7)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 3000,
        }}
      >
        <Box className="leaflet-control leaflet-bar" sx={{ width: '80%' }}>
          <Box sx={{ width: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: 1, mr: 1 }}>
                <LinearProgress variant="determinate" value={process} />
              </Box>
              <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="white">
                  {`${process}%`}
                </Typography>
              </Box>
            </Box>
            <Typography variant="body2" color="white">
              {message}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  if (listDownload.length === 0) return null;
  return renderProgress();
};

export default DownloadControl;
