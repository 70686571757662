import React, { useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router';
import _ from 'lodash';
import { Box, Tab, Tabs, Button } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { CSSObject } from '@mui/material/styles';

import {
  Drawer,
  DrawerHeader,
} from './Sidebar.styles';

import { LIST, ROUTERS, ENUMS } from '@constants';
import Utils from '@/Utils';
import GISBarComponent from './GISBar';
import UserManagementBar from './UserManagementBar';
import GFileManagementBar from './GFileManagementBar';

interface SectionProps {
  attributes?: CSSObject;
}

const { SIDEBAR_LIST } = LIST.MENU_LIST;
const { ROLES } = ENUMS;

const SideBarComponent: React.FC<SectionProps> = () => {
  // Constructors
  const pathname = useLocation().pathname;
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [tabValue, setTabValue] = useState(SIDEBAR_LIST[0].value);
  const [activeTab, setActiveTab] = useState<JSX.Element | null>(
    SIDEBAR_LIST[0].icon
  );
  const userRole = Utils.getUserRole() || ROLES.USER;

  useEffect(() => {
    if (pathname) setTabValue(pathname);
  }, []);

  // Events
  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    Utils.redirect(newValue);
  };

  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  // Renders
  const _renderTabs = useMemo(() => {
    return (
      <Box sx={{ maxHeight: '70%' }}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          centered
        >
          {_.map(SIDEBAR_LIST, (item, index) => {
            if (item.value !== ROUTERS.HOME && userRole === ROLES.USER)
              return null;
            return (
              <Tab
                key={index}
                icon={item.icon}
                label={item.label}
                value={item.value}
                onClick={() => setActiveTab(item.icon)}
              />
            );
          })}
        </Tabs>
      </Box>
    );
  }, [tabValue]);

  const _renderPanelItems = (value: string) => {
    switch (value) {
      case ROUTERS.HOME:
        return <GISBarComponent />;
      case ROUTERS.USER_MANAGEMENT:
        return <UserManagementBar />;
      case ROUTERS.FILE_MANAGEMENT:
        return <GFileManagementBar />;
      default:
        return null;
    }
  };

  const _renderPanels = () => {
    const values = _.map(SIDEBAR_LIST, (e) => e.value);
    return _.map(values, (item, index) => {
      return (
        <TabPanel sx={{ paddingX: 0 }} key={index} value={item}>
          {_renderPanelItems(item)}
        </TabPanel>
      );
    });
  };

  const _renderHeader = () => {
    return (
      <Box sx={{ width: 1 }}>
        {_renderTabs}
        <Box>
          <TabContext value={tabValue}>{_renderPanels()}</TabContext>
        </Box>
      </Box>
    );
  };

  return (
    <Drawer variant="permanent" open={isCollapsed}>
      <DrawerHeader>
        {isCollapsed ? (
          _renderHeader()
        ) : (
          <Button
            variant="contained"
            onClick={handleCollapse}
            color="burntSienna"
          >
            {activeTab}
          </Button>
        )}
      </DrawerHeader>
      <Button
        onClick={handleCollapse}
        variant="contained"
        sx={{
          mt: 'auto',
        }}
        endIcon={isCollapsed ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        color="pickledBluewood"
      >
        {isCollapsed ? 'Hide' : 'Show'}
      </Button>
    </Drawer>
  );
};

export default SideBarComponent;
