const USER_TABLE_HEADER = [
  {
    label: 'Full name',
    value: 'userData.fullName',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Phone Number',
    value: 'userData.phoneNumber',
  },
  {
    label: 'Role',
    value: 'role.name',
  },
  {
    label: 'Status',
    value: 'status',
  },
];

const GEOJSON_TABLE_HEADER = [
  {
    label: 'File name',
    value: 'file.nameOriginal',
  },
  {
    label: 'File Type',
    value: 'extraData.type',
  },
  {
    label: 'Group',
    value: 'groupId.name',
  },
  {
    label: 'Upload at',
    value: 'createdAt',
  },
  {
    label: 'Status',
    value: 'status',
  },
];

const GROUP_TABLE_HEADER = [
  {
    label: 'Group name',
    value: 'name',
  },
  {
    label: 'Status',
    value: 'status',
  },
];

export default { USER_TABLE_HEADER, GEOJSON_TABLE_HEADER, GROUP_TABLE_HEADER };
