import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import 'leaflet/dist/leaflet.css';
import { MapContainer } from 'react-leaflet';
import { Box } from '@mui/material';

import { mapContainerStyles } from './GISDashboard.styles';
import {
  InteractiveControls,
  LayerControls,
  DownloadControl,
} from '@/Components/LayoutPart';

import { GIS_TYPES } from '@constants';

// Declare constants
const { TITLE_LAYERS } = GIS_TYPES;
const DEFAULT_COORD: [Lat: number, Lng: number] = [41.327953, 19.819025];

const GISAdminMap: React.FC = () => {
  // Constructors
  const [titleLayer, setTitleLayer] = useState(TITLE_LAYERS.LIVE);

  useEffect(() => {}, []);

  return (
    <Box sx={mapContainerStyles}>
      <MapContainer
        className="map-content"
        center={DEFAULT_COORD}
        zoom={8}
        maxZoom={18}
        zoomControl={false}
        fadeAnimation
        zoomAnimation
        dragging
        easeLinearity={0.5}
        attributionControl={false}
        preferCanvas
      >
        <DownloadControl />
        <InteractiveControls.ZoomControls />
        <InteractiveControls.ToggleControls
          titleLayer={titleLayer}
          onChangeTitle={(t) => setTitleLayer(t)}
          starterCoords={DEFAULT_COORD}
        />
        <InteractiveControls.ScaleControls />
        <LayerControls titleLayer={titleLayer} />
      </MapContainer>
    </Box>
  );
};

export default GISAdminMap;
