import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useConfirm } from 'material-ui-confirm';
import _ from 'lodash';
import {
  Box,
  TableContainer,
  TableBody,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  TablePagination,
  IconButton,
  TableFooter,
  CircularProgress,
} from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { PaginationActions } from '@/Components/Common';

import { UserManagementActions } from '@actions';
import { useTypedDispatch, RootState } from '@/store';
import {
  IPaginationFilter,
  IPaginationMeta,
} from '@interfaces/PaginationMeta.interface';
import Utils from '@/Utils';
import { CommonColors } from '@/Themes';
import { TABLES, ENUMS } from '@constants';

const { USER_TABLE_HEADER } = TABLES;
// Declare actions
const { fetchUsers, getUserById, activeUser, deactiveUser, deleteUser } =
  UserManagementActions;

const TableAttribute: React.FC = () => {
  // Declare reducers, dispatch
  const dispatch = useTypedDispatch();
  const confirm = useConfirm();
  const userTable: any =
    useSelector((state: RootState) =>
      _.get(state.USER_MANAGEMENT, 'userTable')
    ) || [];
  const meta: IPaginationMeta = useSelector((state: RootState) =>
    _.get(state.USER_MANAGEMENT, 'meta')
  );
  const pagination: IPaginationFilter = useSelector((state: RootState) =>
    _.get(state.USER_MANAGEMENT, 'pagination')
  );
  const isLoading = useSelector((state: RootState) =>
    _.get(state.WIDGET, 'isLoading')
  );
  const userRole = Utils.getUserRole() || ENUMS.ROLES.USER;
  const colSpan = USER_TABLE_HEADER.length + 1;

  // Events
  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    let page = pagination.page;
    if (newPage === 0) page = 1;
    else if (newPage < page) page -= 1;
    else if (newPage >= page) page += 1;
    Utils.dispatchRequests(
      [dispatch(fetchUsers({ ...pagination, page }))],
      true
    );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const perPage = parseInt(event.target.value, 10);
    Utils.dispatchRequests(
      [dispatch(fetchUsers({ ...pagination, limit: perPage, page: 1 }))],
      true
    );
  };

  const handleDisable = (id: string) => {
    confirm({ description: `This will permanently deactive this user.` })
      .then(() => {
        Utils.dispatchRequests([dispatch(deactiveUser(id))]);
      })
      .then(async () => {
        await Utils.sleep(1000);
        Utils.dispatchRequests([dispatch(fetchUsers(pagination))], true);
      });
  };

  const handleActive = (id: string) => {
    confirm({ description: `This will permanently active this user.` })
      .then(() => {
        Utils.dispatchRequests([dispatch(activeUser(id))]);
      })
      .then(async () => {
        await Utils.sleep(1000);
        Utils.dispatchRequests([dispatch(fetchUsers(pagination))], true);
      });
  };

  const handleDelete = (id: string) => {
    confirm({ description: `This will permanently delete this user.` })
      .then(() => {
        Utils.dispatchRequests([dispatch(deleteUser(id))]);
      })
      .then(async () => {
        await Utils.sleep(1000);
        Utils.dispatchRequests([dispatch(fetchUsers(pagination))], true);
      });
  };

  // Renders
  const _renderTableHeader = useMemo(() => {
    return (
      <TableRow>
        {_.map(USER_TABLE_HEADER, (item) => (
          <TableCell key={`head-${item.value}`}>{item.label}</TableCell>
        ))}
        <TableCell align="center" sx={{ color: CommonColors.astronaut }}>
          Actions
        </TableCell>
      </TableRow>
    );
  }, [USER_TABLE_HEADER]);

  const _renderTableBody = () => {
    if (_.isEmpty(userTable))
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>There is no users to display</TableCell>
        </TableRow>
      );
    return _.map(userTable, (item, index) => {
      return (
        <TableRow hover key={index}>
          {_.map(USER_TABLE_HEADER, (head, innerKey) => (
            <TableCell key={`row${innerKey}-${index}`}>
              {_.get(item, head.value)}
            </TableCell>
          ))}
          <TableCell key={`action.${index}`} align="center">
            <IconButton
              sx={{ color: CommonColors.bismark }}
              onClick={() => {
                Utils.dispatchRequests([dispatch(getUserById(item.id))]);
              }}
              title="View profile"
            >
              <PreviewIcon />
            </IconButton>
            {item?.status === ENUMS.STATUS.ACTIVE &&
              userRole === ENUMS.ROLES.SUPER_ADMIN && (
                <IconButton
                  sx={{ color: CommonColors.oceanGreen }}
                  onClick={() => handleDisable(item.id)}
                  title="Active / Inactive"
                >
                  <ToggleOnIcon />
                </IconButton>
              )}
            {item?.status === ENUMS.STATUS.INACTIVE &&
              userRole === ENUMS.ROLES.SUPER_ADMIN && (
                <IconButton
                  sx={{ color: CommonColors.fuzzyWuzzyBrown }}
                  onClick={() => handleActive(item.id)}
                  title="Active / Inactive"
                >
                  <ToggleOffIcon />
                </IconButton>
              )}
            {userRole === ENUMS.ROLES.SUPER_ADMIN && (
              <IconButton
                title="Terminate"
                color="error"
                onClick={() => handleDelete(item.id)}
                disabled={
                  userRole === item?.role?.roleCode ||
                  item.status !== ENUMS.STATUS.INACTIVE
                }
              >
                <DeleteOutlineIcon />
              </IconButton>
            )}
          </TableCell>
        </TableRow>
      );
    });
  };

  const _renderFooter = () => {
    if (!meta) return null;
    return (
      <TableFooter
        sx={{
          left: 0,
          bottom: 0,
          zIndex: 2,
          position: 'sticky',
          background: 'white',
        }}
      >
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            colSpan={colSpan}
            count={meta.totalItems}
            rowsPerPage={meta.itemsPerPage}
            page={meta.currentPage - 1}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={PaginationActions}
          />
        </TableRow>
      </TableFooter>
    );
  };

  const _renderTable = () => {
    return (
      <TableContainer
        component={Paper}
        sx={{ maxHeight: 'calc(100vh - 200px)', overflow: 'auto' }}
      >
        <Table stickyHeader>
          <TableHead>{_renderTableHeader}</TableHead>
          <TableBody>
            {!isLoading ? (
              _renderTableBody()
            ) : (
              <TableRow>
                <TableCell colSpan={colSpan} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {_renderFooter()}
        </Table>
      </TableContainer>
    );
  };

  return <Box>{_renderTable()}</Box>;
};

export default TableAttribute;
