import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import AuthReducer from './Authentication.reducer';
import DevToolsReducer from './DevTool.reducer';
import SidebarReducer from './Sidebar.reducer';
import GISDashboardReducer from './GISDashboard.reducer';
import WidgetReducer from './Widget.reducer';
import UserReducer from './User.reducer';
import GeojsonReducer from './Gfile.reducer';
import GroupReducer from './Group.reducer';
import DownloadReducer from './Download.reducer';

const createRootReducer = (history: History) =>
  combineReducers({
    ROUTER: connectRouter(history),
    AUTH: AuthReducer,
    DEV_TOOL: DevToolsReducer,
    SIDEBAR: SidebarReducer,
    GIS_DASHBOARD: GISDashboardReducer,
    WIDGET: WidgetReducer,
    USER_MANAGEMENT: UserReducer,
    GEOJSON_MANAGEMENT: GeojsonReducer,
    GROUP: GroupReducer,
    DOWNLOAD: DownloadReducer,
  });
export default createRootReducer;
