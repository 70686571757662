import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  TextField,
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  IconButton,
  Collapse,
  Box,
} from '@mui/material';
import { TransitionGroup } from 'react-transition-group';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { TransitionSlide } from '@/Components/Common';
import { IWMSLayer } from '@interfaces/GIS.interface';

interface ISectionProps {
  open: boolean;
  handleClose(): void;
  callbackPayload(value: IWMSLayer): void;
}
const DEFAULT_WMS_PROPS = {
  FORMAT: 'image/png',
  TRANSPARENT: true,
  LAYERS: [{ name: '', isChecked: false }],
  SERVICE: 'WMS',
  REQUEST: 'GetMap',
  VERSION: '1.1.1',
  url: '',
  name: '',
};

const NewWMSLinks: React.FC<ISectionProps> = (props: ISectionProps) => {
  // Constructors
  const { open, handleClose, callbackPayload } = props;
  const [detailState, setDetailState] = useState<IWMSLayer>(DEFAULT_WMS_PROPS);
  const [keyRender, setKeyRender] = useState(Math.random());

  useEffect(() => {
    setKeyRender(Math.random());
  }, [handleClose]);

  // Events
  const onClose = () => {
    setDetailState(DEFAULT_WMS_PROPS);
    handleClose();
  };

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    callbackPayload(detailState);
    onClose();
  };

  const newLayer = () => {
    const newLayers = [...detailState.LAYERS];
    newLayers.push({ name: '', isChecked: false });
    setDetailState({ ...detailState, LAYERS: newLayers });
  };

  const removeLayer = (name: string) => {
    setDetailState({
      ...detailState,
      LAYERS: _.filter(detailState.LAYERS, (l) => l.name !== name),
    });
  };

  const handleOnchange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const newLayers = [...detailState.LAYERS];
    newLayers[index] = { ...newLayers[index], name: e.target.value };
    setDetailState({ ...detailState, LAYERS: newLayers });
  };

  // Renders
  const _renderLayers = () => {
    return (
      <Grid container spacing={2} direction="column">
        <Grid item>
          <TransitionGroup key="lst">
            {_.map(detailState.LAYERS, (item, innerKey) => (
              <Collapse key={innerKey}>
                <Box py={1}>
                  <TextField
                    key={`layer${innerKey}${keyRender}`}
                    size="small"
                    label="Layer"
                    required
                    onChange={(e) => handleOnchange(e, innerKey)}
                  />
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    title="Delete"
                    onClick={() => removeLayer(item.name)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Collapse>
            ))}
          </TransitionGroup>
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            color="burntSienna"
            onClick={() => newLayer()}
          >
            More layer
          </Button>
        </Grid>
      </Grid>
    );
  };

  const _renderForm = () => {
    return (
      <Grid container spacing={2} justifyContent="space-around">
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item>
              <TextField
                key={`${keyRender}name`}
                required
                fullWidth
                size="small"
                label="Name"
                onChange={(e) =>
                  setDetailState({ ...detailState, name: e.target.value })
                }
              />
            </Grid>
            <Grid item>
              <TextField
                key={`${keyRender}link`}
                required
                fullWidth
                size="small"
                label="Base Url"
                onChange={(e) =>
                  setDetailState({ ...detailState, url: e.target.value })
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          {_renderLayers()}
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => onClose()}
      TransitionComponent={TransitionSlide}
    >
      <DialogTitle>New WMS Link</DialogTitle>
      <form onSubmit={submitForm}>
        <DialogContent sx={{ overflow: 'unset' }}>
          {_renderForm()}
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<CloseIcon />}
            onClick={() => onClose()}
            variant="contained"
            color="fuzzyWuzzyBrown"
          >
            Close
          </Button>
          <Button
            type="submit"
            startIcon={<SaveIcon />}
            variant="contained"
            color="oceanGreen"
          >
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default NewWMSLinks;
