import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import {
  TextField,
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  MenuItem,
  Button
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { TransitionSlide } from '@/Components/Common';
import { useTypedDispatch, RootState } from '@/store';
import { UserManagementActions } from '@actions';
import { ENUMS } from '@constants';
import Utils from '@/Utils';

// Actions
const { createNewUser, fetchUsers } = UserManagementActions;
const { ROLES } = ENUMS;

interface SectionProps {
  open: boolean;
  handleClose(): void;
}
const DEFAULT_FORM = {
  email: '',
  password: '',
  roleCode: '',
};
const DEFAULT_DATA_FORM = {
  firstName: '',
  lastName: '',
};

const RegisterDialog: React.FC<SectionProps> = (props: SectionProps) => {
  // Constructors
  const { open, handleClose } = props;
  const dispatch = useTypedDispatch();
  const isLoading = useSelector((state: RootState) =>
    _.get(state.WIDGET, 'isLoading')
  );
  const pagination = useSelector((state: RootState) =>
    _.get(state.USER_MANAGEMENT, 'pagination')
  );
  const [accountForm, setAccountForm] = useState(DEFAULT_FORM);
  const [userDataForm, setUserDataForm] = useState(DEFAULT_DATA_FORM);

  useEffect(() => {}, []);

  // Events
  const registerUser = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    Utils.dispatchRequests(
      [
        dispatch(createNewUser({ ...accountForm, userData: userDataForm })),
        setAccountForm(DEFAULT_FORM),
        setUserDataForm(DEFAULT_DATA_FORM),
      ],
      true
    );
  };

  const onClose = () => {
    handleClose();
    setAccountForm(DEFAULT_FORM);
    setUserDataForm(DEFAULT_DATA_FORM);
    Utils.dispatchRequests([dispatch(fetchUsers(pagination))], true);
  };

  // Renders

  const _renderUserForm = () => {
    return (
      <Grid container spacing={3} justifyContent="space-around">
        <Grid item xs={6}>
          <TextField
            key="firstName"
            fullWidth
            required
            disabled={!!isLoading}
            value={userDataForm.firstName}
            label="First name"
            onChange={(e) => {
              setUserDataForm({ ...userDataForm, firstName: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            key="lastName"
            fullWidth
            required
            disabled={!!isLoading}
            label="Last name"
            value={userDataForm.lastName}
            onChange={(e) => {
              setUserDataForm({ ...userDataForm, lastName: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            key="email"
            fullWidth
            label="Email"
            type="email"
            autoComplete="email"
            autoFocus
            required
            value={accountForm.email}
            disabled={!!isLoading}
            onChange={(e) => {
              setAccountForm({ ...accountForm, email: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            key="password"
            fullWidth
            required
            disabled={!!isLoading}
            type="password"
            label="Password"
            value={accountForm.password}
            onChange={(e) => {
              setAccountForm({ ...accountForm, password: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            key="role"
            fullWidth
            select
            required
            disabled={!!isLoading}
            value={accountForm.roleCode || ''}
            label="Role"
            onChange={(e) => {
              setAccountForm({ ...accountForm, roleCode: e.target.value });
            }}
          >
            <MenuItem value={ROLES.ADMIN}>Admin</MenuItem>
            <MenuItem value={ROLES.USER}>Operator</MenuItem>
          </TextField>
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => onClose()}
      TransitionComponent={TransitionSlide}
    >
      <DialogTitle>Register</DialogTitle>
      <form onSubmit={registerUser}>
        <DialogContent>{_renderUserForm()}</DialogContent>
        <DialogActions>
          <Button
            startIcon={<CloseIcon />}
            onClick={() => onClose()}
            variant="contained"
            disabled={!!isLoading}
            color="fuzzyWuzzyBrown"
          >
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            startIcon={<SaveIcon />}
            loading={isLoading}
            variant="contained"
            color="burntSienna"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default RegisterDialog;
