import { Navigate, Outlet } from 'react-router-dom';
import { ROUTERS } from '@constants';

// Protected Route
import ProtectedRoute from './Protected.router';
import { AdminContainer } from '@containers';

const isLoggedIn = true;

const AdminRouters = {
  element: isLoggedIn ? <Outlet /> : <Navigate to={ROUTERS.FORBIDEN} />,
  path: ROUTERS.ADMIN_DASHBOARD,
  children: [
    {
      path: `${ROUTERS.USER_MANAGEMENT}`,
      element: (
        <ProtectedRoute location={ROUTERS.USER_MANAGEMENT}>
          <AdminContainer.UserManagement />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTERS.FILE_MANAGEMENT}`,
      element: (
        <ProtectedRoute location={ROUTERS.FILE_MANAGEMENT}>
          <AdminContainer.GfileManagement />
        </ProtectedRoute>
      ),
    },
  ],
};

export default AdminRouters;
