export default {
  SAVED_SECURE_TOKEN: '@PW:secure_token',
  SAVED_SECURE_REFRESH_TOKEN: '@PW:secure_refresh_token',
  CURRENT_LANGUAGE: '@PW:current_language',
  SAVED_USER_DATA: '@PW:udata',
  SAVED_FULL_NAME: '@PW:full_name',
  SAVED_REMEMBER: '@PW:remember',
  ROLE_KEY: '@PW:role_key',
  LOCALE_KEY: '@PW:locale',
  EXPANDED_SIDEBAR: '@PW:expanded_sidebar',
};
