import _ from 'lodash';
import { ACTION_TYPES } from '@constants';
import API from '@/APIs';
import Utils from '@utils';
import { IUpdateGeojson } from '@interfaces/Geojson.interface';
import {
  IPaginationFilter,
  IPaginationMeta,
} from '@interfaces/PaginationMeta.interface';

// SINGLE ACTIONS
const setGeojsonPagination = (payload: IPaginationFilter) => {
  return {
    type: ACTION_TYPES.SET_GEOJSON_PAGINATION,
    payload,
  };
};

const setGeojsonMeta = (payload: IPaginationMeta) => {
  return {
    type: ACTION_TYPES.SET_GEOJSON_META,
    payload,
  };
};

const setGeojsonDetail = (payload: IUpdateGeojson | null) => {
  return {
    type: ACTION_TYPES.SET_GEOJSON_DETAIL,
    payload,
  };
};

const resetGeojsonReducer = () => {
  return {
    type: ACTION_TYPES.RESET_GEOJSON_REDUCER,
  };
};

// ASYNC ACTIONS
const fetchSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_GEOJSONS_SUCCESS,
    payload,
  };
};

const fetchFail = () => {
  return {
    type: ACTION_TYPES.FETCH_GEOJSONS_FAILURE,
  };
};

const fetchGeojsons = (payload: IPaginationFilter) => {
  return async (dispatch: any) => {
    await API.fetchGeojsons(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchFail());
        else {
          const items = _.get(result, 'items');
          const meta = _.get(result, 'meta');
          const resolveItems = _.map(items, (item) => {
            let extraData = null;
            if (item?.extraData) {
              if (
                item.extraData === 'line' ||
                item.extraData === 'polygon' ||
                item.extraData === 'point'
              )
                extraData = { type: '', color: '' };
              else extraData = JSON.parse(item.extraData);
            }
            return { ...item, extraData };
          });
          dispatch(fetchSuccess(resolveItems));
          dispatch(setGeojsonMeta(meta));
          dispatch(setGeojsonPagination(payload));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(fetchFail());
      });
  };
};

const getByIdSuccess = (payload: IUpdateGeojson) => {
  return {
    type: ACTION_TYPES.GET_GEOJSON_BY_ID_SUCCESS,
    payload,
  };
};

const getByIdFail = () => {
  return {
    type: ACTION_TYPES.GET_GEOJSON_BY_ID_FAILURE,
  };
};

const getGeojsonById = (id: string) => {
  return async (dispatch: any) => {
    await API.getGeojsonById(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(getByIdFail());
        else {
          dispatch(getByIdSuccess(result));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(getByIdFail());
      });
  };
};

const createSuccess = () => {
  return {
    type: ACTION_TYPES.UPLOAD_GEOJSON_SUCCESS,
  };
};

const createFail = () => {
  return {
    type: ACTION_TYPES.UPLOAD_GEOJSON_FAILURE,
  };
};

const uploadGeojsons = (payload: FormData) => {
  return async (dispatch: any) => {
    await API.uploadGeojson(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(createFail());
        else {
          dispatch(createSuccess());
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(createFail());
      });
  };
};

const updateSuccess = () => {
  return {
    type: ACTION_TYPES.UPDATE_GEOJSON_SUCCESS,
  };
};

const updateFail = () => {
  return {
    type: ACTION_TYPES.UPDATE_GEOJSON_FAILURE,
  };
};

const updateGeojson = (payload: FormData, id: string) => {
  return async (dispatch: any) => {
    await API.updateGeojson(payload, id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(updateFail());
        else {
          dispatch(updateSuccess());
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(updateFail());
      });
  };
};

const activeSuccess = () => {
  return {
    type: ACTION_TYPES.ACTIVE_GEOJSON_SUCCESS,
  };
};

const activeFail = () => {
  return {
    type: ACTION_TYPES.ACTIVE_GEOJSON_FAILURE,
  };
};

const activeGeojson = (id: string) => {
  return async (dispatch: any) => {
    await API.activeGeojson(id)
      .then(async () => {
        dispatch(activeSuccess());
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(activeFail());
      });
  };
};

const deactiveSuccess = () => {
  return {
    type: ACTION_TYPES.DEACTIVE_GEOJSON_SUCCESS,
  };
};

const deactiveFail = () => {
  return {
    type: ACTION_TYPES.DEACTIVE_GEOJSON_FAILURE,
  };
};

const deactiveGeojson = (id: string) => {
  return async (dispatch: any) => {
    await API.deactiveGeojson(id)
      .then(async () => {
        dispatch(deactiveSuccess());
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(deactiveFail());
      });
  };
};

export default {
  setGeojsonPagination,
  fetchGeojsons,
  getGeojsonById,
  uploadGeojsons,
  updateGeojson,
  activeGeojson,
  deactiveGeojson,
  resetGeojsonReducer,
  setGeojsonDetail,
};
