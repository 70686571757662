import React, { useEffect } from 'react';
import { Typography, Grid } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { CommonColors } from '@/Themes';
import { ROUTERS } from '@constants';
import Utils from '@/Utils';

const VerifyAccountFail: React.FC = () => {
  // Constructors

  useEffect(() => {
    Utils.sleep(5000).then(() => Utils.redirect(ROUTERS.AUTH));
  }, []);

  // Events
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      bgcolor={CommonColors.mainBackground}
    >
      <Grid item>
        <HighlightOffIcon color="error" fontSize="large" />
      </Grid>
      <Grid item>
        <Typography variant="h4">
          Verify account failure! System will redirect after 5 seconds...
        </Typography>
      </Grid>
    </Grid>
  );
};

export default VerifyAccountFail;
