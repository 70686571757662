import _ from 'lodash';
import { ROUTERS, ACTION_TYPES } from '@constants';
import API from '@/APIs';
import Utils from '@utils';
import {
  ILoginStructure,
  IRefreshToken,
  IForgotPasswordStructure,
  IResetPasswordStructure,
  IVerifyAccount,
} from '@interfaces/Auth.interface';

// SINGLE ACTIONS
const keepLogged = () => {
  return {
    type: ACTION_TYPES.KEEP_LOGGED,
  };
};

const setLogged = () => {
  return {
    type: ACTION_TYPES.LOGOUT,
  };
};

const resetDashboard = () => {
  return {
    type: ACTION_TYPES.RESET_GIS_LAYERS,
  };
};

const logout = () => {
  return (dispatch: any) => {
    dispatch(setLogged());
    dispatch(resetDashboard());
    Utils.clearAllSavedData();
    Utils.redirect(ROUTERS.AUTH);
  };
};

// ASYNC ACTIONS
const loginSuccess = () => {
  return {
    type: ACTION_TYPES.LOGIN_SUCCESS,
  };
};

const loginFail = () => {
  return {
    type: ACTION_TYPES.LOGIN_FAILURE,
  };
};

const login = (payload: ILoginStructure) => {
  return async (dispatch: any) => {
    await API.login(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(loginFail());
        else {
          const token = _.get(result, 'accessToken.token');
          const refreshToken = _.get(result, 'accessToken.refreshToken');
          const userPayload = _.get(result, 'user');
          const userRole = _.get(result, 'user.role.roleCode');
          const fullname = _.get(result, 'user.userData.fullName');
          Utils.saveToken(token);
          Utils.saveRefreshToken(refreshToken);
          Utils.saveUserData(userPayload);
          Utils.saveUserRole(userRole);
          Utils.saveFullName(fullname);
          await Utils.sleep(4000);
          Utils.redirect(ROUTERS.HOME);
          dispatch(loginSuccess());
        }
        return true;
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(loginFail());
      });
  };
};

const refreshTokenSuccess = () => {
  return {
    type: ACTION_TYPES.REFRESH_TOKEN_SUCCESS,
  };
};

const refreshTokenFailure = () => {
  return {
    type: ACTION_TYPES.REFRESH_TOKEN_FAILURE,
  };
};

const checkRefreshToken = (payload: IRefreshToken) => {
  return async (dispatch: any) => {
    await API.checkRefreshToken(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(refreshTokenFailure());
        else {
          const token = _.get(result, 'accessToken.token');
          Utils.saveToken(token);
          await dispatch(refreshTokenSuccess());
        }
        return true;
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        dispatch(refreshTokenFailure());
      });
  };
};

const requestForgotPasswordSuccess = (payload: string) => {
  return {
    type: ACTION_TYPES.REQUEST_FORGOT_PASSWORD_SUCCESS,
    payload,
  };
};

const requestForgotPasswordFailure = () => {
  return {
    type: ACTION_TYPES.REQUEST_FORGOT_PASSWORD_FAILURE,
  };
};

const requestForgotPassword = (payload: IForgotPasswordStructure) => {
  return async (dispatch: any) => {
    await API.forgotPassword(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) await dispatch(requestForgotPasswordFailure());
        else {
          await dispatch(requestForgotPasswordSuccess(payload.email));
          Utils.redirect(ROUTERS.CHANGE_PASSWORD);
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        dispatch(requestForgotPasswordFailure());
      });
  };
};

const resetPasswordSuccess = () => {
  return {
    type: ACTION_TYPES.REQUEST_FORGOT_PASSWORD_SUCCESS,
  };
};

const resetPasswordFailure = () => {
  return {
    type: ACTION_TYPES.REQUEST_FORGOT_PASSWORD_FAILURE,
  };
};

const resetPassword = (payload: IResetPasswordStructure) => {
  return async (dispatch: any) => {
    await API.resetPassword(payload)
      .then(async (response: any) => {
        if (!response?.data?.status) await dispatch(resetPasswordFailure());
        else {
          await dispatch(resetPasswordSuccess());
          Utils.redirect(ROUTERS.AUTH);
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        dispatch(resetPasswordFailure());
      });
  };
};

const verifySuccess = () => {
  return {
    type: ACTION_TYPES.VERIFY_ACCOUNT_SUCCESS,
  };
};

const verifyFailure = () => {
  return {
    type: ACTION_TYPES.VERIFY_ACCOUNT_FAILURE,
  };
};

const verifyAccount = (payload: IVerifyAccount) => {
  return async (dispatch: any) => {
    await API.verifyAccount(payload)
      .then(async (response: any) => {
        if (!response?.data?.status) {
          await dispatch(verifyFailure());
          Utils.sleep(5000).then(() =>
            Utils.redirect(ROUTERS.VERIFY_ACCOUNT_FAIL)
          );
        } else {
          await dispatch(verifySuccess());
          Utils.sleep(5000).then(() =>
            Utils.redirect(ROUTERS.VERIFY_ACCOUNT_SUCCESS)
          );
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        dispatch(verifyFailure());
        Utils.sleep(5000).then(() =>
          Utils.redirect(ROUTERS.VERIFY_ACCOUNT_FAIL)
        );
      });
  };
};

export default {
  login,
  logout,
  keepLogged,
  setLogged,
  checkRefreshToken,
  requestForgotPassword,
  resetPassword,
  verifyAccount,
};
