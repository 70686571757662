import { sendRequest } from '@/Configs';
import { API } from '@constants';
import { ICreateGroup, IUpdateGroup } from '@interfaces/Group.interface';
import { IPaginationFilter } from '@interfaces/PaginationMeta.interface';

const { GEOJSON_GROUP } = API;

export const createGroup = async (payload: ICreateGroup) => {
  return await sendRequest(GEOJSON_GROUP.ROOT, 'POST', payload);
};

export const updateGroup = async (payload: IUpdateGroup) => {
  return await sendRequest(`${GEOJSON_GROUP.ROOT}${payload.id}`, 'PUT', payload);
};

export const fetchGroups = async (payload: IPaginationFilter) => {
  return await sendRequest(GEOJSON_GROUP.ROOT, 'GET', payload);
};

export const getGroupById = async (id: string) => {
  return await sendRequest(`${GEOJSON_GROUP.GET_BY_ID}${id}`, 'GET');
};

export const activeGroup = async (id: string) => {
  return await sendRequest(`${GEOJSON_GROUP.ACTIVE}${id}`, 'PUT');
};

export const deactiveGroup = async (id: string) => {
  return await sendRequest(`${GEOJSON_GROUP.DEACTIVE}${id}`, 'PUT');
};
