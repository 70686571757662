import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import _ from 'lodash';

import DefaultLayout from '@/Components/DefaultLayout';
import { TableAttributes } from '@/Components/LayoutPart';
import { mainStyles } from './GISDashboard.styles';
import GISMap from './GISDashboard.map';

import { IGeoJsonObjectCollection } from '@interfaces/GIS.interface';
import { GISActions } from '@actions';
import { useTypedDispatch, RootState } from '@/store';

// Declare constants
const { setTableAttributeData, setMoveToTargetCoordinates } = GISActions;

const GISDashboard: React.FC = () => {
  // Constructors
  const dispatch = useTypedDispatch();
  const attributesData: IGeoJsonObjectCollection | null = useSelector(
    (state: RootState) => _.get(state.GIS_DASHBOARD, 'tableAttributesData')
  );

  useEffect(() => {
    return () => {
      if (attributesData) {
        dispatch(setTableAttributeData(null));
        setMoveToTargetCoordinates(null);
      }
    };
  }, []);

  // Renders
  const renderMain = () => {
    return (
      <Box sx={mainStyles}>
        <GISMap />
        {attributesData && <TableAttributes />}
      </Box>
    );
  };

  return <DefaultLayout mode="LANDING" content={renderMain()} />;
};

export default GISDashboard;
