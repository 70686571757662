import { ACTION_TYPES, ENUMS } from '@constants';

const DEFAULT_PAGINATION = {
  page: 1,
  limit: 10,
  orderBy: 'ASC',
  sortBy: '',
  keyword: '',
  status: ENUMS.STATUS.ACTIVE
};

const initialState = {
  pagination: DEFAULT_PAGINATION,
  meta: null,
  geojsonDetail: null,
  geojsonTable: [],
};

export default (
  state = initialState,
  action: { type: string; payload: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.SET_GEOJSON_PAGINATION:
      return {
        ...state,
        pagination: payload,
      };
    case ACTION_TYPES.SET_GEOJSON_META:
      return {
        ...state,
        meta: payload,
      };
    case ACTION_TYPES.SET_GEOJSON_DETAIL:
      return {
        ...state,
        geojsonDetail: payload,
      };
    case ACTION_TYPES.RESET_GEOJSON_REDUCER:
      return {
        selectedTool: '',
        pagination: DEFAULT_PAGINATION,
        meta: null,
        geojsonTable: [],
        geojsonDetail: null,
      };

    case ACTION_TYPES.FETCH_GEOJSONS_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: true,
        geojsonTable: payload,
      };
    case ACTION_TYPES.FETCH_GEOJSONS_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
      };
    case ACTION_TYPES.GET_GEOJSON_BY_ID_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: true,
        geojsonDetail: payload,
      };
    case ACTION_TYPES.GET_GEOJSON_BY_ID_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
      };
    case ACTION_TYPES.UPLOAD_GEOJSON_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: true,
      };
    case ACTION_TYPES.UPLOAD_GEOJSON_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
      };
    case ACTION_TYPES.UPDATE_GEOJSON_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: true,
      };
    case ACTION_TYPES.UPDATE_GEOJSON_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
      };
    case ACTION_TYPES.DEACTIVE_GEOJSON_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: true,
      };
    case ACTION_TYPES.DEACTIVE_GEOJSON_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
      };
    case ACTION_TYPES.ACTIVE_GEOJSON_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: true,
      };
    case ACTION_TYPES.ACTIVE_GEOJSON_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
      };
    default:
      return state;
  }
};
