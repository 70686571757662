import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import {
  Box,
  Collapse,
  FormControlLabel,
  Checkbox,
  List,
  ListItemButton,
  ListItemText,
  CircularProgress,
  Grid,
  Divider,
  TextField,
  InputAdornment,
  Typography,
  IconButton,
  LinearProgress,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { listStyles } from './Download.styles';

import { RootState } from '@/store';
import { IDownloadOption } from '@interfaces/Download.interface';
import Utils from '@utils';

// Declare actions

const DownloadGeojsons: React.FC = () => {
  // Declare reducers, dispatch
  const allCloundFiles: IDownloadOption[] =
    useSelector((state: RootState) =>
      _.get(state.DOWNLOAD, 'allCloundFiles')
    ) || null;
  const isLoading = useSelector((state: RootState) =>
    _.get(state.WIDGET, 'isLoading')
  );
  const [originalList, setOriginalList] = useState<IDownloadOption[]>([]);
  const [downloadList, setDownloadList] = useState<string[]>([]);
  const [openItems, setOpenItems] = useState<string[]>([]);
  const [keyword, setKeyword] = useState('');
  const [isOnDownload, setIsOnDownload] = useState(false);
  const [message, setMessage] = useState('');
  const [process, setProcess] = useState(0);

  useEffect(() => {
    if (allCloundFiles) setOriginalList(allCloundFiles);
    else {
      setOriginalList([]);
      setDownloadList([]);
      setOpenItems([]);
      setIsOnDownload(false);
      setKeyword('');
      setProcess(0);
    }
  }, [allCloundFiles]);

  // Events
  const handleOpenItems = (value: string) => {
    let currentItems = [...openItems];
    if (_.includes(openItems, value))
      currentItems = _.filter(currentItems, (el) => el !== value);
    else currentItems.push(value);
    setOpenItems(currentItems);
  };

  const onFilterItem = () => {
    const results = _.filter(allCloundFiles, (file) => {
      const { name } = file;
      return _.includes(_.toLower(name), _.toLower(keyword));
    });
    setOriginalList(results);
  };

  const addToDownloadList = (id: string, isChecked: boolean) => {
    let currentItems = [...downloadList];
    if (_.includes(downloadList, id) && !isChecked)
      currentItems = _.filter(currentItems, (el) => el !== id);
    else if (!_.includes(downloadList, id) && isChecked) {
      currentItems.push(id);
    }
    setDownloadList(currentItems);
  };

  const handleCheckboxParent = (
    e: React.ChangeEvent<HTMLInputElement>,
    group: string
  ) => {
    let currentItems = [...downloadList];
    _.forEach(originalList, (list) => {
      const { id } = list;
      if (list.group === group) {
        const isChecked = e.target.checked;
        if (_.includes(downloadList, id) && !isChecked)
          currentItems = _.filter(currentItems, (el) => el !== id);
        else if (!_.includes(downloadList, id) && isChecked) {
          currentItems.push(id);
        }
      }
    });
    setDownloadList(currentItems);
  };

  const onDownloadFiles = async () => {
    if (_.isEmpty(downloadList)) {
      setMessage('No file selected!');
    } else {
      const list = _.filter(allCloundFiles, (file) =>
        _.includes(downloadList, file.id)
      );
      const totalStep = list.length * 2;
      await Utils.sleep(1000);
      setIsOnDownload(true);
      await Utils.GISTool.downloadMultipleGeojsons({
        geojsons: list,
        totalStep,
        callbackProgress: (message) => {
          setMessage(message);
        },
        callbackRunProcess: (p) => {
          setProcess(p);
        },
        callbackEndProcess: () => {
          setIsOnDownload(false);
          setMessage('');
          setProcess(0);
        },
      });
    }
  };

  // Renders
  const _renderLayers = (layers: IDownloadOption[]) => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
        {_.map(layers, (layer) => {
          const { id, name } = layer;
          const key = `o-${id}`;
          const isChecked = _.includes(downloadList, id);
          return (
            <FormControlLabel
              key={key}
              label={
                <Typography
                  variant="body2"
                  sx={{ textTransform: 'capitalize' }}
                >
                  {name}
                </Typography>
              }
              control={
                <Checkbox
                  key={`${name}${key}`}
                  checked={isChecked}
                  onChange={(e) => addToDownloadList(id, e.target.checked)}
                  disabled={isOnDownload}
                />
              }
            />
          );
        })}
      </Box>
    );
  };

  const _renderOriginalList = useMemo(() => {
    const groupLayers = _.groupBy(originalList, 'group');
    return _.map(groupLayers, (group, index) => {
      const totalChecked = _.filter(group, (el) =>
        _.includes(downloadList, el.id)
      );
      const isChecked = totalChecked.length === group.length;
      return (
        <Box key={index}>
          <ListItemButton onClick={() => handleOpenItems(index)}>
            <FormControlLabel
              label=""
              control={
                <Checkbox
                  key={index}
                  checked={isChecked}
                  indeterminate={!isChecked}
                  onChange={(e) => handleCheckboxParent(e, index)}
                  color="primary"
                  disabled={isOnDownload}
                />
              }
            />
            <ListItemText
              primary={
                <Typography
                  variant="subtitle2"
                  sx={{ textTransform: 'capitalize' }}
                >
                  {index} ({group.length} files)
                </Typography>
              }
            />
            {_.includes(openItems, index) ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          {!_.isEmpty(group) && (
            <Collapse
              in={_.includes(openItems, index)}
              timeout="auto"
              unmountOnExit
              key={index}
            >
              <List component="div" disablePadding sx={{ pl: 2 }}>
                {_renderLayers(group)}
              </List>
            </Collapse>
          )}
          <Divider />
        </Box>
      );
    });
  }, [originalList, openItems, downloadList]);

  return (
    <Grid container direction="column" rowSpacing={2}>
      <Grid item>
        <Grid container columnSpacing={4}>
          <Grid item xs={7}>
            <TextField
              fullWidth
              size="small"
              label="Geojsons"
              helperText="Enter name of geojson"
              onChange={(e) => {
                setKeyword(e.target.value);
                if (!e.target.value) onFilterItem();
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') onFilterItem();
              }}
              onBlur={() => onFilterItem()}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => onFilterItem()}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={5} />
        </Grid>
      </Grid>
      <Grid item>
        <Grid container columnSpacing={4}>
          <Grid item xs={7}>
            {isLoading && <CircularProgress />}
            {!isLoading && (
              <List component="nav" key="originalList" sx={listStyles}>
                {_renderOriginalList}
              </List>
            )}
          </Grid>
          <Grid item xs={5}>
            <LoadingButton
              fullWidth
              startIcon={<CloudDownloadIcon />}
              variant="contained"
              color="oceanGreen"
              loading={isOnDownload}
              onClick={() => onDownloadFiles()}
            >
              Download ({downloadList.length}) files
            </LoadingButton>
            <Typography mt={2}>{message}</Typography>
            {isOnDownload && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: 1, mr: 1 }}>
                  <LinearProgress variant="determinate" value={process} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                  <Typography variant="body2">{`${process}%`}</Typography>
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DownloadGeojsons;
