import * as AuthApi from './Authentication.api';
import * as UserApi from './User.api';
import * as RoleApi from './Role.api';
import * as GeojsonApi from './Geojson.api';
import * as GroupApi from './Group.api';

export default {
  ...AuthApi,
  ...UserApi,
  ...RoleApi,
  ...GeojsonApi,
  ...GroupApi,
};
