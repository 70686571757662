import AccountBoxIcon from '@mui/icons-material/PasswordSharp';
import LogoutIcon from '@mui/icons-material/Logout';

import { IMenuSettings } from '@/Interfaces/Menu.interface';
import { ROUTERS } from '@constants';

const USER_SETTINGS: IMenuSettings[] = [
  {
    label: 'Change password',
    value: 'changePassword',
    icon: <AccountBoxIcon sx={{ mr: 2 }} />,
  },
  {
    label: 'logout',
    value: ROUTERS.AUTH,
    icon: <LogoutIcon sx={{ mr: 2 }} />,
  },
];

export default USER_SETTINGS;
