import { sendRequest } from '@/Configs';
import { API } from '@constants';
import {
  ILoginStructure,
  IForgotPasswordStructure,
  IRefreshToken,
  IResetPasswordStructure,
  IVerifyAccount
} from '@interfaces/Auth.interface';

const { AUTH } = API;

export const login = async (payload: ILoginStructure) => {
  return await sendRequest(AUTH.LOGIN, 'POST', payload);
};

export const checkRefreshToken = async (payload: IRefreshToken) => {
  return sendRequest(AUTH.REFRESH_TOKEN, 'POST', payload);
};

export const forgotPassword = async (payload: IForgotPasswordStructure) => {
  return await sendRequest(AUTH.FORGOT_PASSWORD, 'POST', payload);
};

export const resetPassword = async (payload: IResetPasswordStructure) => {
  return await sendRequest(AUTH.RESET_PASSWORD, 'POST', payload);
};

export const verifyAccount = async (payload: IVerifyAccount) => {
  return await sendRequest(AUTH.VERIFY_USER, 'POST', payload);
};
