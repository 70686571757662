import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import {
  TextField,
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  MenuItem,
  Button
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
// import Visibility from '@mui/icons-material/Visibility';
// import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { TransitionSlide } from '@/Components/Common';
import { useTypedDispatch, RootState } from '@/store';
import { UserManagementActions } from '@actions';
import Utils from '@/Utils';
import { IUpdateUser, IUserData } from '@interfaces/User.interface';
import { ENUMS } from '@constants';

// Actions
const { updateUser, fetchUsers } = UserManagementActions;
const { ROLES } = ENUMS;

interface SectionProps {
  open: boolean;
  handleClose(): void;
}

const UserInformationDialog: React.FC<SectionProps> = (props: SectionProps) => {
  // Constructors
  const { open, handleClose } = props;
  const dispatch = useTypedDispatch();
  const isLoading = useSelector((state: RootState) =>
    _.get(state.WIDGET, 'isLoading')
  );
  const pagination = useSelector((state: RootState) =>
    _.get(state.USER_MANAGEMENT, 'pagination')
  );
  const userProfile: IUpdateUser = useSelector((state: RootState) =>
    _.get(state.USER_MANAGEMENT, 'userProfile')
  );
  const [userDataForm, setUserDataForm] = useState<IUserData>({
    firstName: '',
    lastName: '',
  });
  const userRole = Utils.getUserRole() || ROLES.USER;
  const [roleCode, setRoleCode] = useState('');
  // const [password, setPassword] = useState('');

  useEffect(() => {
    if (userProfile) {
      const userData = _.get(userProfile, 'userData');
      const role = _.get(userProfile, 'role.roleCode');
      setUserDataForm(userData);
      setRoleCode(role);
    }
  }, [userProfile]);

  // Events
  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (userProfile)
      Utils.dispatchRequests(
        [
          dispatch(
            updateUser({
              ...userProfile,
              userData: userDataForm,
              roleCode,
            })
          ),
          dispatch(fetchUsers(pagination)),
        ],
        true
      );
  };

  const onClose = () => {
    Utils.dispatchRequests([dispatch(fetchUsers(pagination))], true);
    setUserDataForm({
      firstName: '',
      lastName: '',
    });
    setRoleCode('');
    handleClose();
  };

  // Renders
  const _renderUserForm = () => {
    return (
      <Grid container spacing={3} justifyContent="space-around">
        <Grid item xs={12}>
          <TextField
            key="email"
            fullWidth
            label="Email"
            type="email"
            value={userProfile?.email || ''}
            disabled
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        {userRole === ROLES.SUPER_ADMIN && (
          <Grid item xs={6}>
            <TextField
              key="role"
              fullWidth
              select
              required
              disabled={!!isLoading || roleCode === ROLES.SUPER_ADMIN}
              label="Role"
              value={roleCode || ''}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                setRoleCode(e.target.value);
              }}
            >
              {roleCode === ROLES.SUPER_ADMIN && (
                <MenuItem value={ROLES.SUPER_ADMIN}>Super Admin</MenuItem>
              )}
              <MenuItem value={ROLES.ADMIN}>Admin</MenuItem>
              <MenuItem value={ROLES.USER}>Operator</MenuItem>
            </TextField>
          </Grid>
        )}
        <Grid item xs={6}>
          <TextField
            key="firstName"
            fullWidth
            required
            value={userDataForm?.firstName || ''}
            disabled={!!isLoading}
            label="First name"
            onChange={(e) => {
              setUserDataForm({ ...userDataForm, firstName: e.target.value });
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            key="lastName"
            fullWidth
            required
            value={userDataForm?.lastName || ''}
            disabled={!!isLoading}
            label="Last name"
            onChange={(e) => {
              setUserDataForm({ ...userDataForm, lastName: e.target.value });
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            key="gender"
            fullWidth
            select
            value={userDataForm?.gender || ''}
            disabled={!!isLoading}
            label="Gender"
            onChange={(e) => {
              setUserDataForm({ ...userDataForm, gender: e.target.value });
            }}
          >
            <MenuItem value="male">Male</MenuItem>
            <MenuItem value="female">Female</MenuItem>
            <MenuItem value="other">Other</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            key="phoneNumber"
            value={userDataForm?.phoneNumber || ''}
            disabled={!!isLoading}
            label="Phone Number"
            onChange={(e) => {
              setUserDataForm({ ...userDataForm, phoneNumber: e.target.value });
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            key="address"
            fullWidth
            value={userDataForm?.address || ''}
            disabled={!!isLoading}
            label="Address"
            onChange={(e) => {
              setUserDataForm({ ...userDataForm, address: e.target.value });
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            key="city"
            fullWidth
            value={userDataForm?.city || ''}
            disabled={!!isLoading}
            label="City"
            onChange={(e) => {
              setUserDataForm({ ...userDataForm, city: e.target.value });
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            key="zipCode"
            value={userDataForm?.zipCode || ''}
            disabled={!!isLoading}
            label="Zip code"
            onChange={(e) => {
              setUserDataForm({ ...userDataForm, zipCode: e.target.value });
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            key="regionArea"
            value={userDataForm?.regionArea || ''}
            disabled={!!isLoading}
            label="Region Area"
            onChange={(e) => {
              setUserDataForm({ ...userDataForm, regionArea: e.target.value });
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => onClose()}
      TransitionComponent={TransitionSlide}
    >
      <DialogTitle>Profile</DialogTitle>
      <form onSubmit={submitForm}>
        <DialogContent>{_renderUserForm()}</DialogContent>
        <DialogActions>
          <Button
            startIcon={<CloseIcon />}
            onClick={() => onClose()}
            variant="contained"
            disabled={!!isLoading}
            color="fuzzyWuzzyBrown"
          >
            Cancel
          </Button>
          {userRole === ROLES.SUPER_ADMIN && (
            <LoadingButton
              type="submit"
              startIcon={<SaveIcon />}
              loading={isLoading}
              variant="contained"
              color="burntSienna"
            >
              Save
            </LoadingButton>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UserInformationDialog;
