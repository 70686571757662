import { CommonColors } from '@/Themes';
import { SxProps, Theme } from '@mui/system';

export const controlStyles: SxProps<Theme> = {
  background: 'white',
  color: CommonColors.bismark,
  width: 30,
  '&:hover': {
    background: CommonColors.burntSienna,
  },
};
