import _ from 'lodash';
import { ACTION_TYPES, ENUMS } from '@constants';
import API from '@/APIs';
import Utils from '@utils';
import { IUpdateGroup, ICreateGroup } from '@interfaces/Group.interface';
import {
  IPaginationFilter,
  IPaginationMeta,
} from '@interfaces/PaginationMeta.interface';

// SINGLE ACTIONS
const setGroupPagination = (payload: IPaginationFilter) => {
  return {
    type: ACTION_TYPES.SET_GROUP_PAGINATION,
    payload,
  };
};

const setGroupMeta = (payload: IPaginationMeta) => {
  return {
    type: ACTION_TYPES.SET_GROUP_META,
    payload,
  };
};

const setGroupDetail = (payload: IUpdateGroup | null) => {
  return {
    type: ACTION_TYPES.SET_GROUP_DETAIL,
    payload,
  };
};

const resetGroupReducer = () => {
  return {
    type: ACTION_TYPES.RESET_GROUP_REDUCER,
  };
};

// ASYNC ACTIONS
const fetchSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_GROUPS_SUCCESS,
    payload,
  };
};

const fetchFail = () => {
  return {
    type: ACTION_TYPES.FETCH_GROUPS_FAILURE,
  };
};

const fetchGroups = (payload: IPaginationFilter) => {
  return async (dispatch: any) => {
    await API.fetchGroups(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchFail());
        else {
          const items = _.get(result, 'items');
          const meta = _.get(result, 'meta');
          dispatch(fetchSuccess(items));
          dispatch(setGroupMeta(meta));
          dispatch(setGroupPagination(payload));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(fetchFail());
      });
  };
};

const getByIdSuccess = (payload: IUpdateGroup) => {
  return {
    type: ACTION_TYPES.GET_GROUP_BY_ID_SUCCESS,
    payload,
  };
};

const getByIdFail = () => {
  return {
    type: ACTION_TYPES.GET_GROUP_BY_ID_FAILURE,
  };
};

const getGroupById = (id: string) => {
  return async (dispatch: any) => {
    await API.getGroupById(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(getByIdFail());
        else {
          dispatch(getByIdSuccess(result));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(getByIdFail());
      });
  };
};

const createSuccess = () => {
  return {
    type: ACTION_TYPES.CREATE_GROUP_SUCCESS,
  };
};

const createFail = () => {
  return {
    type: ACTION_TYPES.CREATE_GROUP_FAILURE,
  };
};

const createNewGroup = (payload: ICreateGroup) => {
  return async (dispatch: any) => {
    await API.createGroup(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(createFail());
        else {
          dispatch(createSuccess());
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(createFail());
      });
  };
};

const updateSuccess = () => {
  return {
    type: ACTION_TYPES.UPDATE_GROUP_SUCCESS,
  };
};

const updateFail = () => {
  return {
    type: ACTION_TYPES.UPDATE_GROUP_FAILURE,
  };
};

const updateGroup = (payload: IUpdateGroup) => {
  return async (dispatch: any) => {
    await API.updateGroup(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(updateFail());
        else {
          dispatch(updateSuccess());
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(updateFail());
      });
  };
};

const activeSuccess = () => {
  return {
    type: ACTION_TYPES.ACTIVE_GROUP_SUCCESS,
  };
};

const activeFail = () => {
  return {
    type: ACTION_TYPES.ACTIVE_GROUP_FAILURE,
  };
};

const activeGroup = (id: string) => {
  return async (dispatch: any) => {
    await API.activeGroup(id)
      .then(async () => {
        dispatch(activeSuccess());
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(activeFail());
      });
  };
};

const deactiveSuccess = () => {
  return {
    type: ACTION_TYPES.DEACTIVE_GROUP_SUCCESS,
  };
};

const deactiveFail = () => {
  return {
    type: ACTION_TYPES.DEACTIVE_GROUP_FAILURE,
  };
};

const deactiveGroup = (id: string) => {
  return async (dispatch: any) => {
    await API.deactiveGroup(id)
      .then(async () => {
        dispatch(deactiveSuccess());
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(deactiveFail());
      });
  };
};

const fetchAllSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_ALL_GROUP_SUCCESS,
    payload,
  };
};

const fetchAllFail = () => {
  return {
    type: ACTION_TYPES.FETCH_ALL_GROUP_FAILURE,
  };
};

const fetchAllGroups = () => {
  return async (dispatch: any) => {
    await API.fetchGroups({ page: 0, limit: 0, status: ENUMS.STATUS.ACTIVE })
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchAllFail());
        else {
          const items = _.get(result, 'items');
          dispatch(fetchAllSuccess(items));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(fetchAllFail());
      });
  };
};

export default {
  setGroupPagination,
  fetchGroups,
  getGroupById,
  createNewGroup,
  updateGroup,
  activeGroup,
  deactiveGroup,
  resetGroupReducer,
  setGroupDetail,
  fetchAllGroups,
};
