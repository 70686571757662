import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useConfirm } from 'material-ui-confirm';
import _ from 'lodash';
import {
  Box,
  TableContainer,
  TableBody,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  TablePagination,
  IconButton,
  TableFooter,
  CircularProgress,
  Button,
} from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';

import { PaginationActions } from '@/Components/Common';
import NewGroupDialog from '../NewGroupDialog';

import { GroupActions } from '@actions';
import { useTypedDispatch, RootState } from '@/store';
import {
  IPaginationFilter,
  IPaginationMeta,
} from '@interfaces/PaginationMeta.interface';
import Utils from '@/Utils';
import { CommonColors } from '@/Themes';
import { TABLES, ENUMS } from '@constants';

const { GROUP_TABLE_HEADER } = TABLES;
// Declare actions
const { fetchGroups, getGroupById, activeGroup, deactiveGroup } = GroupActions;

const GroupTable: React.FC = () => {
  // Declare reducers, dispatch
  const dispatch = useTypedDispatch();
  const confirm = useConfirm();
  const groupTable: any =
    useSelector((state: RootState) => _.get(state.GROUP, 'groupTable')) || [];
  const meta: IPaginationMeta = useSelector((state: RootState) =>
    _.get(state.GROUP, 'meta')
  );
  const pagination: IPaginationFilter = useSelector((state: RootState) =>
    _.get(state.GROUP, 'pagination')
  );
  const isLoading = useSelector((state: RootState) =>
    _.get(state.WIDGET, 'isLoading')
  );
  const [isOpen, setIsOpen] = useState(false);
  const colSpan = GROUP_TABLE_HEADER.length + 2;

  // Events
  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    let page = pagination.page;
    if (newPage === 0) page = 1;
    else if (newPage < page) page -= 1;
    else if (newPage >= page) page += 1;
    Utils.dispatchRequests(
      [dispatch(fetchGroups({ ...pagination, page }))],
      true
    );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const perPage = parseInt(event.target.value, 10);
    Utils.dispatchRequests(
      [dispatch(fetchGroups({ ...pagination, limit: perPage, page: 1  }))],
      true
    );
  };

  const handleDisable = (id: string) => {
    confirm({ description: `This will permanently deactive this group.` })
      .then(() => {
        Utils.dispatchRequests([dispatch(deactiveGroup(id))]);
      })
      .then(async () => {
        await Utils.sleep(1000);
        Utils.dispatchRequests([dispatch(fetchGroups(pagination))], true);
      });
  };

  const handleActive = (id: string) => {
    confirm({ description: `This will permanently active this group.` })
      .then(() => {
        Utils.dispatchRequests([dispatch(activeGroup(id))]);
      })
      .then(async () => {
        await Utils.sleep(1000);
        Utils.dispatchRequests([dispatch(fetchGroups(pagination))], true);
      });
  };

  // Renders
  const _renderTableHeader = useMemo(() => {
    return (
      <TableRow>
        {_.map(GROUP_TABLE_HEADER, (item) => (
          <TableCell key={`head-${item.value}`}>{item.label}</TableCell>
        ))}
        <TableCell align="center" sx={{ color: CommonColors.astronaut }}>
          Total files
        </TableCell>
        <TableCell align="center" sx={{ color: CommonColors.astronaut }}>
          Actions
        </TableCell>
      </TableRow>
    );
  }, [GROUP_TABLE_HEADER]);

  const _renderTableBody = () => {
    if (_.isEmpty(groupTable))
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>There is no groups to display</TableCell>
        </TableRow>
      );
    return _.map(groupTable, (item, index) => {
      return (
        <TableRow hover key={index}>
          {_.map(GROUP_TABLE_HEADER, (head, innerKey) => (
            <TableCell key={`row${innerKey}-${index}`}>
              {_.get(item, head.value)}
            </TableCell>
          ))}
          <TableCell key={`total.${index}`} align="center">
            {_.get(item, 'geojson').length}
          </TableCell>
          <TableCell key={`action.${index}`} align="center">
            <IconButton
              sx={{ color: CommonColors.bismark }}
              onClick={() => {
                Utils.dispatchRequests([dispatch(getGroupById(item.id))]);
              }}
              title="View detail"
            >
              <PreviewIcon />
            </IconButton>
            {item?.status === ENUMS.STATUS.ACTIVE && (
              <IconButton
                sx={{ color: CommonColors.oceanGreen }}
                onClick={() => handleDisable(item.id)}
                title="Active / Inactive"
              >
                <ToggleOnIcon />
              </IconButton>
            )}
            {item?.status === ENUMS.STATUS.INACTIVE && (
              <IconButton
                sx={{ color: CommonColors.fuzzyWuzzyBrown }}
                onClick={() => handleActive(item.id)}
                title="Active / Inactive"
              >
                <ToggleOffIcon />
              </IconButton>
            )}
          </TableCell>
        </TableRow>
      );
    });
  };

  const _renderFooter = () => {
    if (!meta) return null;
    return (
      <TableFooter
        sx={{
          left: 0,
          bottom: 0,
          zIndex: 2,
          position: 'sticky',
          background: 'white',
        }}
      >
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            colSpan={colSpan - 1}
            count={meta.totalItems}
            rowsPerPage={meta.itemsPerPage}
            page={meta.currentPage - 1}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={PaginationActions}
          />
          <TableCell>
            <Button
              color="oceanGreen"
              variant="contained"
              onClick={() => setIsOpen(true)}
            >
              New Group
            </Button>
          </TableCell>
        </TableRow>
      </TableFooter>
    );
  };

  const _renderTable = () => {
    return (
      <TableContainer
        component={Paper}
        sx={{ maxHeight: 'calc(100vh - 200px)', overflow: 'auto' }}
      >
        <Table stickyHeader>
          <TableHead>{_renderTableHeader}</TableHead>
          <TableBody>
            {!isLoading ? (
              _renderTableBody()
            ) : (
              <TableRow>
                <TableCell colSpan={colSpan} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {_renderFooter()}
        </Table>
      </TableContainer>
    );
  };

  return (
    <Box>
      <NewGroupDialog
        open={isOpen}
        handleClose={() => {
          setIsOpen(false);
        }}
      />
      {_renderTable()}
    </Box>
  );
};

export default GroupTable;
