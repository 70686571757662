import { ACTION_TYPES } from '@constants';
import { CommonColors } from '@/Themes';

const DEFAULT_MEASURE = {
  position: 'topright',
  primaryLengthUnit: 'meters',
  secondaryLengthUnit: 'kilometers',
  primaryAreaUnit: 'sqmeters',
  secondaryAreaUnit: 'acres',
  activeColor: CommonColors.burntSienna,
  completedColor: CommonColors.mystic,
};

const initialState = {
  measures: DEFAULT_MEASURE,
  isEditMeasure: false,
  selectedLayers: [],
};

export default (
  state = initialState,
  action: { type: string; payload: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.SET_SIDEBAR_MEASURE:
      return {
        ...state,
        measures: payload,
      };
    case ACTION_TYPES.SET_MEASURE_STATUS:
      return {
        ...state,
        isEditMeasure: payload,
      };
    default:
      return state;
  }
};
