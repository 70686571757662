import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import {
  TextField,
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { TransitionSlide } from '@/Components/Common';
import { useTypedDispatch, RootState } from '@/store';
import { GroupActions } from '@actions';
import Utils from '@/Utils';
import { IUpdateGroup } from '@interfaces/Group.interface';

// Actions
const { updateGroup, fetchGroups, setGroupDetail } = GroupActions;

interface SectionProps {
  open: boolean;
  handleClose(): void;
}
const NEW_FORM = {
  id: '',
  name: '',
};

type FORM_KEYS = 'name';

const GroupInformationDialog: React.FC<SectionProps> = (
  props: SectionProps
) => {
  // Constructors
  const { open, handleClose } = props;
  const dispatch = useTypedDispatch();
  const isLoading = useSelector((state: RootState) =>
    _.get(state.WIDGET, 'isLoading')
  );
  const pagination = useSelector((state: RootState) =>
    _.get(state.GROUP, 'pagination')
  );
  const groupDetail: IUpdateGroup = useSelector((state: RootState) =>
    _.get(state.GROUP, 'groupDetail')
  );
  const [detailState, setDetailState] = useState<IUpdateGroup>(NEW_FORM);

  useEffect(() => {
    if (groupDetail) setDetailState(groupDetail);
  }, [groupDetail]);

  // Events
  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (groupDetail) {
      Utils.dispatchRequests(
        [dispatch(updateGroup({ ...groupDetail, ...detailState }))],
        true
      );
    }
  };

  const onChangeForm = (key: FORM_KEYS, value: string) => {
    const newPayload = { ...detailState, [key]: value };
    setDetailState(newPayload);
  };

  const onClose = () => {
    if (isLoading) return null;
    setDetailState(NEW_FORM);
    dispatch(setGroupDetail(null));
    Utils.dispatchRequests([dispatch(fetchGroups(pagination))], true);
    return handleClose();
  };

  // Renders
  const _renderForm = () => {
    return (
      <Grid container spacing={3} justifyContent="space-around">
        <Grid item xs={12}>
          <TextField
            key={`name`}
            value={detailState.name}
            fullWidth
            disabled={!!isLoading}
            label="Group name"
            helperText="Name will display"
            required
            size="small"
            onChange={(e) => {
              onChangeForm('name', e.target.value);
            }}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => onClose()}
      TransitionComponent={TransitionSlide}
    >
      <DialogTitle>Group Detail</DialogTitle>
      <form onSubmit={submitForm}>
        <DialogContent sx={{ overflow: 'unset' }}>
          {groupDetail && _renderForm()}
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<CloseIcon />}
            onClick={() => onClose()}
            variant="contained"
            disabled={!!isLoading}
            color="fuzzyWuzzyBrown"
          >
            Close
          </Button>
          <LoadingButton
            type="submit"
            startIcon={<SaveIcon />}
            loading={isLoading}
            variant="contained"
            color="burntSienna"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default GroupInformationDialog;
