import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import {
  Box,
  Grid,
  TableContainer,
  TableBody,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  Typography,
  IconButton,
  CircularProgress,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  mainStyles,
  tableStyles,
  gridStyles,
  resizeStyles,
} from './TableAttribute.styles';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { GISActions } from '@actions';
import { useTypedDispatch, RootState } from '@/store';
import {
  IGeoJsonObjectCollection,
  IGISFeature,
} from '@interfaces/GIS.interface';
import Utils from '@/Utils';
import { CommonColors } from '@/Themes';

// Declare actions
const { setTableAttributeData, setMoveToTargetCoordinates } = GISActions;
const MIN_HEIGHT = 50;
const LIMIT_ROW = 15;
const DEFAULT_HEIGHT = 400;

const TableAttribute: React.FC = () => {
  // Declare reducers, dispatch
  const dispatch = useTypedDispatch();
  const attributesData: IGeoJsonObjectCollection = useSelector(
    (state: RootState) => _.get(state.GIS_DASHBOARD, 'tableAttributesData')
  );
  const [headers, setHeaders] = useState<string[]>([]);
  const [totalFeatures, setTotalFeatures] = useState<IGISFeature[]>([]);
  const [featuresChunks, setFeaturesChunks] = useState<IGISFeature[]>([]);
  const [height, setHeight] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null>(null);

  const calculateItemsToShow = async () => {
    return new Promise(async () => {
      setIsLoading(true);
      await Utils.sleep(500);
      if (featuresChunks.length < totalFeatures.length) {
        const newChunks = _.slice(
          totalFeatures,
          0,
          featuresChunks.length + LIMIT_ROW
        );
        await Utils.sleep(500);
        setFeaturesChunks(newChunks);
        setIsLoading(false);
      } else {
        await Utils.sleep(500);
        setIsLoading(false);
        setMessage('All up to date!');
      }
    });
  };

  useEffect(() => {
    if (attributesData) {
      const newData = attributesData?.features || [];
      if (!_.isEmpty(newData)) setHeaders(_.keys(_.first(newData)?.properties));
      setTotalFeatures(newData);
      (async () => {
        await Utils.sleep(1000);
        setHeight(DEFAULT_HEIGHT);
      })();
    }
  }, [attributesData]);

  useEffect(() => {
    if (message) {
      const execute = async () => {
        await Utils.sleep(1000);
        setMessage(null);
      };
      execute();
    }
  }, [message]);

  useEffect(() => {
    calculateItemsToShow();
  }, [totalFeatures]);

  // Events
  const toggleClose = async () => {
    setHeight(0);
    await Utils.sleep(500);
    dispatch(setTableAttributeData(null));
  };

  const handleResize = useCallback((e: { clientY: number }) => {
    const maxHeight = document.body.offsetHeight;
    const newHeight = maxHeight - e.clientY;
    if (newHeight > MIN_HEIGHT && newHeight < maxHeight) {
      setHeight(newHeight);
    }
  }, []);

  const handleMouseDown = () => {
    document.addEventListener('mouseup', handleMouseUp, true);
    document.addEventListener('mousemove', handleResize, true);
  };

  const handleMouseUp = () => {
    document.removeEventListener('mouseup', handleMouseUp, true);
    document.removeEventListener('mousemove', handleResize, true);
  };

  const handleScroll = async (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    e.preventDefault();
    const node = e.currentTarget;
    const isAtBottom =
      node.scrollHeight - node.clientHeight <= node.scrollTop + 10;
    if (isAtBottom && !isLoading) await calculateItemsToShow();
  };

  const exportToCSV = () => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const totalRow: any = [];
    _.forEach(totalFeatures, (el) => {
      totalRow.push(el.properties);
    });
    const ws = XLSX.utils.json_to_sheet(totalRow);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, attributesData?.name + fileExtension);
  };

  // Renders
  const _renderTableHeader = useMemo(() => {
    if (_.isEmpty(headers)) return;
    return (
      <TableRow>
        <TableCell align="center" sx={{ color: CommonColors.astronaut }}>
          <LocationOnIcon />
        </TableCell>
        {_.map(headers, (item) => (
          <TableCell key={`head-${item}`}>{item}</TableCell>
        ))}
      </TableRow>
    );
  }, [headers]);

  const _renderTableBody = useMemo(() => {
    if (_.isEmpty(featuresChunks))
      return (
        <TableRow hover>
          <TableCell colSpan={headers.length}>
            There is no attributes to display
          </TableCell>
        </TableRow>
      );
    return _.map(featuresChunks, (item, index) => {
      return (
        <TableRow hover key={index}>
          <TableCell key={`location.${index}`} align="center">
            <IconButton
              sx={{ color: CommonColors.fuzzyWuzzyBrown }}
              onClick={() => {
                const centerOfitem = Utils.GISTool.getCenterOfFeature(
                  item.geometry
                );
                dispatch(setMoveToTargetCoordinates(centerOfitem));
              }}
            >
              <MyLocationIcon />
            </IconButton>
          </TableCell>
          {_.map(item.properties, (value, innerKey) => (
            <TableCell key={`row${innerKey}-${index}`}>{value}</TableCell>
          ))}
        </TableRow>
      );
    });
  }, [featuresChunks, headers]);

  const _renderTable = () => {
    return (
      <Grid item sx={{ width: 1, overflow: 'auto' }}>
        <TableContainer
          component={Paper}
          sx={tableStyles(height)}
          onScroll={(e) => handleScroll(e)}
        >
          <Table stickyHeader size="small">
            <TableHead>{_renderTableHeader}</TableHead>
            <TableBody>{_renderTableBody}</TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ fontSize: '0.8em' }}>
          Show up {featuresChunks.length}/{totalFeatures.length} attributes{' '}
          {isLoading && <CircularProgress size="0.8em" />}
        </Box>
      </Grid>
    );
  };

  return (
    <Box sx={{ ...mainStyles, height }}>
      <Box sx={resizeStyles}>
        <MoreHorizIcon
          sx={{ cursor: 'ns-resize' }}
          onMouseDown={handleMouseDown}
        />
      </Box>
      <Grid container spacing={2} flexDirection="column" sx={gridStyles}>
        <Grid item>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography component="h1" variant="h6">
                Layer: {attributesData?.name}{' '}
              </Typography>
            </Grid>
            <Grid item sx={{ ml: 'auto' }}>
              <Button variant="contained" onClick={() => exportToCSV()}>
                Export XLS
              </Button>
            </Grid>
            <Grid item>
              <IconButton onClick={() => toggleClose()}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        {_renderTable()}
      </Grid>
    </Box>
  );
};

export default TableAttribute;
