import _ from 'lodash';
import { ACTION_TYPES } from '@constants';
import API from '@/APIs';
import Utils from '@utils';
import {
  IUpdateUser,
  ICreateUser,
  IUpdateSelfPassword,
} from '@interfaces/User.interface';
import {
  IPaginationFilter,
  IPaginationMeta,
} from '@interfaces/PaginationMeta.interface';

// SINGLE ACTIONS
const setUserPagination = (payload: IPaginationFilter) => {
  return {
    type: ACTION_TYPES.SET_USER_PAGINATION,
    payload,
  };
};

const setUserMeta = (payload: IPaginationMeta) => {
  return {
    type: ACTION_TYPES.SET_USER_META,
    payload,
  };
};

const setUserProfile = (payload: IUpdateUser | null) => {
  return {
    type: ACTION_TYPES.SET_USER_PROFILE,
    payload,
  };
};

const resetUserReducer = () => {
  return {
    type: ACTION_TYPES.RESET_USER_REDUCER,
  };
};

// ASYNC ACTIONS
const fetchSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_USERS_SUCCESS,
    payload,
  };
};

const fetchFail = () => {
  return {
    type: ACTION_TYPES.FETCH_USERS_FAILURE,
  };
};

const fetchUsers = (payload: IPaginationFilter) => {
  return async (dispatch: any) => {
    await API.fetchUsers({
      ...payload,
      status: payload?.status === 'all' ? '' : payload?.status,
    })
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchFail());
        else {
          const items = _.get(result, 'items');
          const meta = _.get(result, 'meta');
          dispatch(fetchSuccess(items));
          dispatch(setUserMeta(meta));
          dispatch(setUserPagination(payload));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(fetchFail());
      });
  };
};

const getByIdSuccess = (payload: IUpdateUser) => {
  return {
    type: ACTION_TYPES.GET_USER_BY_ID_SUCCESS,
    payload,
  };
};

const getByIdFail = () => {
  return {
    type: ACTION_TYPES.GET_USER_BY_ID_FAILURE,
  };
};

const getUserById = (id: string) => {
  return async (dispatch: any) => {
    await API.getUserById(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(getByIdFail());
        else {
          dispatch(getByIdSuccess(result));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(getByIdFail());
      });
  };
};

const createSuccess = () => {
  return {
    type: ACTION_TYPES.CREATE_USER_SUCCESS,
  };
};

const createFail = () => {
  return {
    type: ACTION_TYPES.CREATE_USER_FAILURE,
  };
};

const createNewUser = (payload: ICreateUser) => {
  return async (dispatch: any) => {
    await API.createUser(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(createFail());
        else {
          dispatch(createSuccess());
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(createFail());
      });
  };
};

const updateSuccess = () => {
  return {
    type: ACTION_TYPES.UPDATE_USER_SUCCESS,
  };
};

const updateFail = () => {
  return {
    type: ACTION_TYPES.UPDATE_USER_FAILURE,
  };
};

const updateUser = (payload: IUpdateUser) => {
  return async (dispatch: any) => {
    await API.updateUser(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(updateFail());
        else {
          dispatch(updateSuccess());
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(updateFail());
      });
  };
};

const activeSuccess = () => {
  return {
    type: ACTION_TYPES.ACTIVE_USER_SUCCESS,
  };
};

const activeFail = () => {
  return {
    type: ACTION_TYPES.ACTIVE_USER_FAILURE,
  };
};

const activeUser = (id: string) => {
  return async (dispatch: any) => {
    await API.activeUser(id)
      .then(async (response: any) => {
        await Utils.resolveResponse(response);
        dispatch(activeSuccess());
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(activeFail());
      });
  };
};

const deactiveSuccess = () => {
  return {
    type: ACTION_TYPES.DEACTIVE_USER_SUCCESS,
  };
};

const deactiveFail = () => {
  return {
    type: ACTION_TYPES.DEACTIVE_USER_FAILURE,
  };
};

const deactiveUser = (id: string) => {
  return async (dispatch: any) => {
    await API.deactiveUser(id)
      .then(async (response: any) => {
        await Utils.resolveResponse(response);
        dispatch(deactiveSuccess());
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(deactiveFail());
      });
  };
};

const deleteSuccess = () => {
  return {
    type: ACTION_TYPES.DELETE_ACCOUNT_SUCCESS,
  };
};

const deleteFail = () => {
  return {
    type: ACTION_TYPES.DELETE_ACCOUNT_FAILURE,
  };
};

const updateSelfPasswordSuccess = () => {
  return {
    type: ACTION_TYPES.UPDATE_SELF_PROFILE_SUCCESS,
  };
};

const deleteUser = (id: string) => {
  return async (dispatch: any) => {
    await API.deleteUser(id)
      .then(async (response: any) => {
        await Utils.resolveResponse(response);
        dispatch(deleteSuccess());
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(deleteFail());
      });
  };
};

const updateSelfPasswordFail = () => {
  return {
    type: ACTION_TYPES.UPDATE_SELF_PROFILE_FAILURE,
  };
};

const updateSelfPassword = (payload: IUpdateSelfPassword) => {
  return async (dispatch: any) => {
    await API.updateSelfPassword(payload)
      .then(async (response: any) => {
        await Utils.resolveResponse(response);
        dispatch(updateSelfPasswordSuccess());
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(updateSelfPasswordFail());
      });
  };
};

export default {
  setUserPagination,
  fetchUsers,
  getUserById,
  createNewUser,
  updateUser,
  activeUser,
  deactiveUser,
  resetUserReducer,
  setUserProfile,
  updateSelfPassword,
  deleteUser,
};
