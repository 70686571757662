import Cookies from 'universal-cookie';
import { COOKIE_KEYS, LOCALE } from '@constants';

const cookies = new Cookies();

const saveLocale = (locale: string) => {
  localStorage.setItem(COOKIE_KEYS.LOCALE_KEY, JSON.stringify(locale));
};

const getSaveLocale = () => {
  const locale = localStorage.getItem(COOKIE_KEYS.LOCALE_KEY);
  if (!locale) return LOCALE.EN;

  saveLocale(JSON.parse(locale));
  return JSON.parse(locale);
};

const saveIsRemember = (isRemember: boolean) => {
  localStorage.setItem(COOKIE_KEYS.SAVED_REMEMBER, JSON.stringify(isRemember));
};

const getSaveIsRemember = () => {
  const isRemember = localStorage.getItem(COOKIE_KEYS.SAVED_REMEMBER);
  if (isRemember) return JSON.parse(isRemember);

  return false;
};

const saveToken = (token: string) => {
  cookies.set(COOKIE_KEYS.SAVED_SECURE_TOKEN, token, { path: '/' });
};

const getSavedToken = () => {
  const token = cookies.get(COOKIE_KEYS.SAVED_SECURE_TOKEN);
  if (token) return token;
};

const saveRefreshToken = (token: string) => {
  cookies.set(COOKIE_KEYS.SAVED_SECURE_REFRESH_TOKEN, token, {
    maxAge: 60 * 60 * 6,
    path: '/',
  });
};

const getSavedRefreshToken = () => {
  return cookies.get(COOKIE_KEYS.SAVED_SECURE_REFRESH_TOKEN);
};

const saveFullName = (name: string) => {
  cookies.set(COOKIE_KEYS.SAVED_FULL_NAME, name);
};

const getFullName = () => {
  return cookies.get(COOKIE_KEYS.SAVED_FULL_NAME);
};

const getSavedUserData = () => {
  const userData = localStorage.getItem(COOKIE_KEYS.SAVED_USER_DATA);
  if (userData && userData !== 'undefined') return JSON.parse(userData);
  return null;
};

const saveUserData = (userData: any) => {
  localStorage.setItem(COOKIE_KEYS.SAVED_USER_DATA, JSON.stringify(userData));
};

const saveUserRole = (role: string) => {
  cookies.set(COOKIE_KEYS.ROLE_KEY, role);
};

const getUserRole = () => {
  return cookies.get(COOKIE_KEYS.ROLE_KEY);
};

const saveExpandedSidebar = (isExpanded: number) => {
  localStorage.setItem(
    COOKIE_KEYS.EXPANDED_SIDEBAR,
    JSON.stringify(isExpanded)
  );
};

const isExpandedSidebar = () => {
  const isExpanded = localStorage.getItem(COOKIE_KEYS.EXPANDED_SIDEBAR);
  if (!isExpanded) return 1;
  return parseInt(isExpanded);
};

const clearAllSavedData = () => {
  cookies.remove(COOKIE_KEYS.SAVED_SECURE_REFRESH_TOKEN);
  cookies.remove(COOKIE_KEYS.SAVED_SECURE_TOKEN);
  cookies.remove(COOKIE_KEYS.SAVED_FULL_NAME);
  cookies.remove(COOKIE_KEYS.ROLE_KEY);
  localStorage.removeItem(COOKIE_KEYS.SAVED_USER_DATA);
};

export {
  saveToken,
  getSavedToken,
  saveRefreshToken,
  getSavedRefreshToken,
  saveFullName,
  getFullName,
  clearAllSavedData,
  saveUserData,
  getSavedUserData,
  saveUserRole,
  getUserRole,
  saveIsRemember,
  getSaveIsRemember,
  saveLocale,
  getSaveLocale,
  saveExpandedSidebar,
  isExpandedSidebar,
};
