import { ACTION_TYPES, ENUMS } from '@constants';

const DEFAULT_PAGINATION = {
  page: 1,
  limit: 25,
  orderBy: 'ASC',
  sortBy: '',
  keyword: '',
  status: ENUMS.STATUS.ACTIVE,
};

const initialState = {
  pagination: DEFAULT_PAGINATION,
  meta: null,
  userTable: [],
  userProfile: null,
};

export default (
  state = initialState,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.SET_USER_PAGINATION:
      return {
        ...state,
        pagination: payload,
      };
    case ACTION_TYPES.SET_USER_META:
      return {
        ...state,
        meta: payload,
      };
    case ACTION_TYPES.SET_USER_PROFILE:
      return {
        ...state,
        userProfile: payload,
      };
    case ACTION_TYPES.RESET_USER_REDUCER:
      return {
        pagination: DEFAULT_PAGINATION,
        meta: null,
        userTable: [],
        userProfile: null,
      };

    case ACTION_TYPES.FETCH_USERS_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: true,
        userTable: payload,
      };
    case ACTION_TYPES.FETCH_USERS_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
      };
    case ACTION_TYPES.GET_USER_BY_ID_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: true,
        userProfile: payload,
      };
    case ACTION_TYPES.GET_USER_BY_ID_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
      };
    case ACTION_TYPES.CREATE_USER_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: true,
      };
    case ACTION_TYPES.CREATE_USER_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
      };
    case ACTION_TYPES.UPDATE_USER_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: true,
      };
    case ACTION_TYPES.UPDATE_USER_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
      };
    case ACTION_TYPES.DEACTIVE_USER_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: true,
      };
    case ACTION_TYPES.DEACTIVE_USER_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
      };
    case ACTION_TYPES.DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: true,
      };
    case ACTION_TYPES.DELETE_ACCOUNT_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
      };
    case ACTION_TYPES.ACTIVE_USER_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: true,
      };
    case ACTION_TYPES.ACTIVE_USER_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
      };
    case ACTION_TYPES.UPDATE_SELF_PROFILE_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
      };
    case ACTION_TYPES.UPDATE_SELF_PROFILE_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
      };
    default:
      return state;
  }
};
