import React, { useEffect, useState, useMemo } from 'react';
import _ from 'lodash';
import { Grid, Tabs, Tab, Divider } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import { tabContentStyles } from './Sidebar.styles';
import GISBarSettings from './GISBarSettings';
import GISBarLayers from './GISBarLayers';
import WMSLayers from './GISBarWMS';

import { LIST } from '@constants';

interface SectionProps {}

const { GISBAR_LIST } = LIST.MENU_LIST;

const LayersComponent: React.FC<SectionProps> = () => {
  // Constructors
  const [tabValue, setTabValue] = useState(GISBAR_LIST[0].value);

  useEffect(() => {}, []);

  // Events
  const handleChangeTab = (_event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };
  // Renders

  const _renderTabItems = useMemo(() => {
    return (
      <Tabs value={tabValue} onChange={handleChangeTab} centered>
        {_.map(GISBAR_LIST, (item, index) => {
          return (
            <Tab
              key={index}
              icon={item.icon}
              title={item.label}
              value={item.value}
            />
          );
        })}
      </Tabs>
    );
  }, [tabValue]);

  const _renderPannelContent = (value: string) => {
    switch (value) {
      case 'GIS':
        return <GISBarLayers />;
      case 'settings':
        return <GISBarSettings />;
      case 'WMS':
        return <WMSLayers />;
      case 'print':
        return <div>Print Section</div>;
      default:
        return null;
    }
  };

  const _renderTabPanels = () => {
    const values = _.map(GISBAR_LIST, (e) => e.value);
    return _.map(values, (item, index) => {
      return (
        <TabPanel sx={{ padding: 0 }} key={index} value={item}>
          {_renderPannelContent(item)}
        </TabPanel>
      );
    });
  };

  return (
    <Grid container direction="column" justifyContent="space-between">
      <Grid item sx={tabContentStyles}>
        <TabContext value={tabValue}>{_renderTabPanels()}</TabContext>
      </Grid>
      <Grid item>
        <Divider />
        {_renderTabItems}
      </Grid>
    </Grid>
  );
};

export default LayersComponent;
