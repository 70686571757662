import { createTheme, PaletteColorOptions } from '@mui/material/styles';
import CommonColor from './CommonColor';

declare module '@mui/material/styles' {
  interface CustomPalette {
    burntSienna: PaletteColorOptions;
    bismark: PaletteColorOptions;
    pickledBluewood: PaletteColorOptions;
    loblolly: PaletteColorOptions;
    oceanGreen: PaletteColorOptions;
    fuzzyWuzzyBrown: PaletteColorOptions;
    diSerria: PaletteColorOptions;
    mystic: PaletteColorOptions;
    astronaut: PaletteColorOptions;
    hippieBlue: PaletteColorOptions;
    perano: PaletteColorOptions;
    lemonGrass: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    burntSienna: true;
    bismark: true;
    pickledBluewood: true;
    loblolly: true;
    oceanGreen: true;
    fuzzyWuzzyBrown: true;
    diSerria: true;
    mystic: true;
    astronaut: true;
    hippieBlue: true;
    perano: true;
    lemonGrass: true;
  }
}

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: string) =>
  augmentColor({ color: { main: mainColor, contrastText: '#fff' } });

const CoreTheme = createTheme({
  palette: {
    burntSienna: createColor(CommonColor.burntSienna),
    bismark: createColor(CommonColor.bismark),
    pickledBluewood: createColor(CommonColor.pickledBluewood),
    loblolly: createColor(CommonColor.loblolly),
    oceanGreen: createColor(CommonColor.oceanGreen),
    fuzzyWuzzyBrown: createColor(CommonColor.fuzzyWuzzyBrown),
    diSerria: createColor(CommonColor.diSerria),
    mystic: createColor(CommonColor.mystic),
    astronaut: createColor(CommonColor.astronaut),
    hippieBlue: createColor(CommonColor.hippieBlue),
    perano: createColor(CommonColor.perano),
    lemonGrass: createColor(CommonColor.lemonGrass),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: '#6b6b6b #2b2b2b',
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            height: 5,
            width: 5,
            cursor: 'pointer',
            backgroundColor: CommonColor.loblolly,
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            backgroundColor: CommonColor.bismark,
            outline: 'none',
          },
          '&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.2) !important',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: CommonColor.burntSienna,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiTabs-indicator': {
            backgroundColor: CommonColor.burntSienna,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: CommonColor.burntSienna,
          },
          '&.MuiCheckbox-indeterminate': {
            color: CommonColor.bismark,
          },
        },
      },
    },
  },
});
export default CoreTheme;
