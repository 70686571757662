import React, { useState, useEffect } from 'react';
import { TextField, Autocomplete, CircularProgress } from '@mui/material';

import { IAutoCompleteOptions } from '@interfaces/Autocomplete.interface';
import Utils from '@/Utils';
import { mainStyles, inputStyles } from './AutocompleteInput.styles';

interface ISectionProps {
  data: IAutoCompleteOptions[];
  onChangeCallback?(value: string): void;
  placeholder?: string;
}

const AutoCompleteInput: React.FC<ISectionProps> = (props: ISectionProps) => {
  // Constructors
  const { data, onChangeCallback, placeholder } = props;
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<IAutoCompleteOptions[]>([]);
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;
    if (!loading) return undefined;
    (async () => {
      await Utils.sleep(1e3);
      if (active) setOptions([...data]);
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) setOptions([]);
  }, [open]);

  // Events
  const handleOnChange = (value: string | null | undefined) => {
    if (onChangeCallback && value) onChangeCallback(value);
  };

  return (
    <Autocomplete
      sx={mainStyles}
      fullWidth
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.title === value.title}
      getOptionLabel={(option) => option.title}
      options={options}
      loading={loading}
      onChange={(_e, option) => handleOnChange(option?.value)}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={inputStyles}
          label={placeholder}
          variant="filled"
          size="small"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default AutoCompleteInput;
