const ADMIN_ROUTER_ROOT = '/admin';
const USER_ROUTER_ROOT = '/';

const BASIC_ROUTERS = {
  FORBIDEN: '/forbiden',
  NOT_FOUND: '*',
  AUTH: '/auth',
  CHANGE_PASSWORD: '/auth/change-password',
  FORGOT_PASSWORD: '/auth/forgot-password',
  PROFILE: '/profile',
  VERIFY_ACCOUNT: '/verify-account',
  VERIFY_ACCOUNT_SUCCESS: '/verify-account-success',
  VERIFY_ACCOUNT_FAIL: '/verify-account-failure',
};

const ADMIN_ROUTERS = {
  ADMIN_DASHBOARD: ADMIN_ROUTER_ROOT,
  USER_MANAGEMENT: `${ADMIN_ROUTER_ROOT}/users`,
  FILE_MANAGEMENT: `${ADMIN_ROUTER_ROOT}/files`,
};

const USER_ROUTERS = {
  HOME: USER_ROUTER_ROOT,
};

export { BASIC_ROUTERS, ADMIN_ROUTERS, USER_ROUTERS };

export default {
  ...BASIC_ROUTERS,
  ...ADMIN_ROUTERS,
  ...USER_ROUTERS,
};
