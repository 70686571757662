import { ACTION_TYPES } from '@constants';

const initialState = {
  tableAttributesData: null,
  selectedLayers: [],
  moveToCoordinates: null,
  layers: [],
  geojsonDownloaded: [],
  WMSLayers: [],
};

export default (
  state = initialState,
  action: { type: string; payload: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.RESET_GIS_LAYERS:
      return {
        ...state,
        tableAttributesData: null,
        selectedLayers: [],
        moveToCoordinates: null,
        layers: [],
        geojsonDownloaded: [],
        WMSLayers: [],
      };
    case ACTION_TYPES.SET_GIS_LAYERS:
      return {
        ...state,
        layers: payload,
      };
    case ACTION_TYPES.SET_TABLE_ATTRIBUTES_DATA:
      return {
        ...state,
        tableAttributesData: payload,
      };
    case ACTION_TYPES.SET_SELECTED_GIS_LAYERS:
      return {
        ...state,
        selectedLayers: payload,
      };
    case ACTION_TYPES.SET_MOVE_TO_COORDINATES:
      return {
        ...state,
        moveToCoordinates: payload,
      };
    case ACTION_TYPES.SET_GEOJSON_DOWNLOADED:
      return {
        ...state,
        geojsonDownloaded: [...state.geojsonDownloaded, ...payload],
      };
    case ACTION_TYPES.SET_WMS_LAYERS:
      return {
        ...state,
        WMSLayers: payload,
      };

    case ACTION_TYPES.FETCH_ALL_GEOJSONS_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: true,
        layers: payload,
      };
    case ACTION_TYPES.FETCH_ALL_GEOJSONS_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
      };
    default:
      return state;
  }
};
