import _ from 'lodash';
import { ACTION_TYPES, ENUMS } from '@constants';
import API from '@/APIs';
import Utils from '@utils';

// SINGLE ACTIONS
const refreshDownloadList = () => {
  return {
    type: ACTION_TYPES.REFRESH_DOWNLOAD_LIST,
  };
};

// ASYNC ACTIONS
const fetchAllSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_ALL_DOWNLOAD_LIST_SUCCESS,
    payload,
  };
};

const fetchAllFail = () => {
  return {
    type: ACTION_TYPES.FETCH_ALL_DOWNLOAD_LIST_FAILURE,
  };
};

const fetchAllDownloadList = () => {
  return async (dispatch: any) => {
    await API.fetchGeojsons({ page: 0, limit: 0, status: ENUMS.STATUS.ACTIVE })
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchAllFail());
        else {
          const items = _.get(result, 'items');
          const results = Utils.GISTool.generateDownloadOptions(items);
          dispatch(fetchAllSuccess(results));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(fetchAllFail());
      });
  };
};

export default {
  fetchAllDownloadList,
  refreshDownloadList
};
