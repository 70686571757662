import React, { useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import {
  TextField,
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  InputAdornment,
  IconButton,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { TransitionSlide } from '@/Components/Common';
import { useTypedDispatch, RootState } from '@/store';
import { UserManagementActions } from '@actions';
import Utils from '@/Utils';
import { IUpdateSelfPassword } from '@interfaces/User.interface';

// Actions
const { updateSelfPassword } = UserManagementActions;

interface SectionProps {
  open: boolean;
  handleClose(): void;
}
const NEW_FORM: IUpdateSelfPassword = {
  oldPassword: '',
  newPassword: '',
  confirmationNewPassword: '',
};

type FORM_KEYS = 'oldPassword' | 'newPassword' | 'confirmationNewPassword';

const ChangePassworDialog: React.FC<SectionProps> = (props: SectionProps) => {
  // Constructors
  const { open, handleClose } = props;
  const dispatch = useTypedDispatch();
  const isLoading = useSelector((state: RootState) =>
    _.get(state.WIDGET, 'isLoading')
  );
  const [detailState, setDetailState] = useState<IUpdateSelfPassword>(NEW_FORM);
  const [isViewPassword, setIsViewPassword] = useState(false);

  // Events
  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    Utils.dispatchRequests([dispatch(updateSelfPassword(detailState))], true);
  };

  const onChangeForm = (key: FORM_KEYS, value: string) => {
    const newPayload = { ...detailState, [key]: value };
    setDetailState(newPayload);
  };

  const onClose = () => {
    if (isLoading) return null;
    setDetailState(NEW_FORM);
    return handleClose();
  };

  // Renders
  const _renderForm = () => {
    return (
      <Grid container spacing={3} justifyContent="space-around">
        <Grid item xs={12}>
          <TextField
            key={`oldPassword`}
            fullWidth
            type={isViewPassword ? 'text' : 'password'}
            disabled={!!isLoading}
            label="Old password"
            required
            size="small"
            onChange={(e) => {
              onChangeForm('oldPassword', e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setIsViewPassword(!isViewPassword)}
                  >
                    {isViewPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            key={`newPassword`}
            fullWidth
            type={isViewPassword ? 'text' : 'password'}
            disabled={!!isLoading}
            label="New password"
            required
            size="small"
            onChange={(e) => {
              onChangeForm('newPassword', e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setIsViewPassword(!isViewPassword)}
                  >
                    {isViewPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            key={`confirmationNewPassword`}
            fullWidth
            type={isViewPassword ? 'text' : 'password'}
            disabled={!!isLoading}
            label="Confirm new password"
            required
            size="small"
            onChange={(e) => {
              onChangeForm('confirmationNewPassword', e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setIsViewPassword(!isViewPassword)}
                  >
                    {isViewPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => onClose()}
      TransitionComponent={TransitionSlide}
    >
      <DialogTitle>Change password</DialogTitle>
      <form onSubmit={submitForm}>
        <DialogContent sx={{ overflow: 'unset' }}>
          {_renderForm()}
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<CloseIcon />}
            onClick={() => onClose()}
            variant="contained"
            disabled={!!isLoading}
            color="fuzzyWuzzyBrown"
          >
            Close
          </Button>
          <LoadingButton
            type="submit"
            startIcon={<SaveIcon />}
            loading={isLoading}
            variant="contained"
            color="burntSienna"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ChangePassworDialog;
