import React, { useState } from 'react';
import {
  Avatar,
  Container,
  Typography,
  Box,
  Grid,
  TextField,
  Button,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Widgets from '@/Components/Widgets';

import { CommonColors } from '@/Themes';
import { AuthActions } from '@actions';
import { useTypedDispatch } from '@/store';
import Utils from '@/Utils';

const { requestForgotPassword } = AuthActions;

const ForgotPassword: React.FC = () => {
  // Constructors
  const dispatch = useTypedDispatch();
  const [forgotForm, setForgotForm] = useState({
    email: '',
  });

  // Events
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    Utils.dispatchRequests([dispatch(requestForgotPassword(forgotForm))]);
  };

  const _renderInputs = () => (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email address"
        name="email"
        autoComplete="email"
        autoFocus
        onChange={(e) =>
          setForgotForm({ ...forgotForm, email: e.target.value })
        }
      />
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Send
      </Button>
    </Box>
  );

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      bgcolor={CommonColors.mainBackground}
    >
      <Grid item>
        <Container maxWidth="xs" sx={{ border: 'solid 1px', padding: 3 }}>
          <Avatar sx={{ m: 1, bgcolor: CommonColors.fuzzyWuzzyBrown }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Forgot Password
          </Typography>
          {_renderInputs()}
        </Container>
      </Grid>
      <Widgets.Notification />
      <Widgets.Loading />
      <Widgets.Alert />
    </Grid>
  );
};

export default ForgotPassword;
