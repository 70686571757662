import { sendRequest } from '@/Configs';
import { API } from '@constants';
import { IUpdateUser, IUpdateSelfPassword, ICreateUser } from '@interfaces/User.interface';
import { IPaginationFilter } from '@interfaces/PaginationMeta.interface';

const { USER } = API;

export const fetchUsers = async (payload: IPaginationFilter) => {
  return await sendRequest(USER.FETCH, 'GET', payload);
};

export const getUserById = async (id: string) => {
  return await sendRequest(`${USER.GET_BY_ID}${id}`, 'GET');
};

export const getSelfProfile = async () => {
  return await sendRequest(USER.GET_SELF, 'GET');
};

export const createUser = async (payload: ICreateUser) => {
  return await sendRequest(`${USER.REGISTER}`, 'POST', payload);
};

export const updateUser = async (payload: IUpdateUser) => {
  return await sendRequest(`${USER.UPDATE}${payload.id}`, 'PUT', payload);
};

export const updateSelfProfile = async (payload: IUpdateUser) => {
  return await sendRequest(USER.UPDATE_SELF, 'PUT', payload);
};

export const updateSelfPassword = async (payload: IUpdateSelfPassword) => {
  return await sendRequest(USER.UPDATE_SELF_PASSWORD, 'PUT', payload);
};

export const activeUser = async (id: string) => {
  return await sendRequest(`${USER.ACTIVE}${id}`, 'PUT');
};

export const deactiveUser = async (id: string) => {
  return await sendRequest(`${USER.DEACTIVE}${id}`, 'PUT');
};

export const deleteUser = async (id: string) => {
  return await sendRequest(`${USER.DELETE}${id}`, 'PUT');
};
