import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import {
  TextField,
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  MenuItem,
  Button,
} from '@mui/material';
import { Dropzone, FileItem, FileValidated } from '@dropzone-ui/react';

import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { TransitionSlide } from '@/Components/Common';
import { ISublayers } from '@interfaces/Geojson.interface';
import { useTypedDispatch, RootState } from '@/store';
import { GFileManagementActions, GISActions, GroupActions } from '@actions';
import Utils from '@/Utils';
import { ROUTERS } from '@constants';

// Actions
const { uploadGeojsons, fetchGeojsons } = GFileManagementActions;
const { fetchAllLayers } = GISActions;
const { fetchAllGroups } = GroupActions;

interface SectionProps {
  open: boolean;
  handleClose(): void;
}

const NEW_LAYER_FORM = {
  name: '',
  key: '',
  value: '',
  color: '',
  descriptions: '',
};

type FORM_KEYS = 'name' | 'key' | 'value' | 'color' | 'descriptions';

const UploadModal: React.FC<SectionProps> = (props: SectionProps) => {
  // Constructors
  const { open, handleClose } = props;
  const dispatch = useTypedDispatch();
  const pathname = useLocation().pathname;
  const isLoading = useSelector((state: RootState) =>
    _.get(state.WIDGET, 'isLoading')
  );
  const filePagination = useSelector((state: RootState) =>
    _.get(state.GEOJSON_MANAGEMENT, 'pagination')
  );
  const allGroups: any =
    useSelector((state: RootState) => _.get(state.GROUP, 'allGroups')) || [];
  const [files, setFiles] = useState<FileValidated[]>([]);
  const [sublayers, setSublayers] = useState<ISublayers[]>([]);
  const [groupId, setGroupId] = useState<string>('');
  const [fileType, setFileType] = useState<string>('');
  const [parentColor, setParentColor] = useState<string>('');

  useEffect(() => {
    if (open) Utils.dispatchRequests([dispatch(fetchAllGroups())], true);
  }, [open]);

  // Events
  const uploadFiles = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData();
    _.map(files, (f) => {
      formData.append(`files`, f.file);
    });
    _.map(sublayers, (sub, index) => {
      _.map(sub, (val, key) => {
        if (val) formData.append(`sublayers[${index}][${key}]`, val);
      });
    });
    formData.append(`groupId`, groupId);
    formData.append(`extraData`, JSON.stringify({type: fileType, color: parentColor}));
    Utils.dispatchRequests(
      [
        dispatch(uploadGeojsons(formData)),
        setGroupId(''),
        setSublayers([]),
        setFiles([]),
      ],
      true
    );
  };

  const addMoreSublayer = () => {
    setSublayers([...sublayers, NEW_LAYER_FORM]);
  };

  const removeLayer = (index: number) => {
    const newLayers = _.filter(sublayers, (_e, i) => i !== index);
    setSublayers(newLayers);
  };

  const onChangeForm = (
    key: FORM_KEYS,
    value: string,
    index: number,
    original: ISublayers
  ) => {
    const newPayload = { ...original, [key]: value };
    const newLayers = [...sublayers];
    newLayers[index] = newPayload;
    setSublayers(newLayers);
  };

  const onClose = () => {
    if (isLoading) return null;
    setSublayers([]);
    setFiles([]);
    if (pathname === ROUTERS.FILE_MANAGEMENT)
      Utils.dispatchRequests([dispatch(fetchGeojsons(filePagination))], true);
    else Utils.dispatchRequests([dispatch(fetchAllLayers())], true);
    return handleClose();
  };

  // Renders
  const _renderFormLayer = (layer: ISublayers, index: number) => {
    return (
      <Grid
        container
        spacing={2}
        justifyContent="space-around"
        key={index}
        sx={{ py: 2, borderBottom: '1px solid' }}
      >
        <Grid item xs={12}>
          <TextField
            fullWidth
            disabled={!!isLoading}
            label="Sub-layers name"
            helperText="Name will display"
            required
            size="small"
            onChange={(e) => {
              onChangeForm('name', e.target.value, index, layer);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            disabled={!!isLoading}
            label="Key"
            helperText="Name of column"
            required
            size="small"
            onChange={(e) => {
              onChangeForm('key', e.target.value, index, layer);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            disabled={!!isLoading}
            label="Value"
            helperText="Value of Key"
            required
            size="small"
            onChange={(e) => {
              onChangeForm('value', e.target.value, index, layer);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            disabled={!!isLoading}
            label="Color"
            helperText="Color of Sub-Layer"
            placeholder="#00000, black, etc,..."
            required
            size="small"
            onChange={(e) => {
              onChangeForm('color', e.target.value, index, layer);
            }}
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            disabled={!!isLoading}
            label="Descriptions"
            size="small"
            onChange={(e) => {
              onChangeForm('descriptions', e.target.value, index, layer);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Button
            startIcon={<RemoveCircleIcon />}
            onClick={() => removeLayer(index)}
            variant="contained"
            disabled={!!isLoading}
            fullWidth
            color="fuzzyWuzzyBrown"
          >
            Remove
          </Button>
        </Grid>
        {index + 1 === sublayers.length && (
          <Grid item xs={4}>
            <Button
              startIcon={<AddIcon />}
              onClick={addMoreSublayer}
              variant="contained"
              disabled={!!isLoading}
              fullWidth
              color="oceanGreen"
            >
              More sub-Layer
            </Button>
          </Grid>
        )}
      </Grid>
    );
  };

  const _renderSublayers = () => {
    if (_.isEmpty(sublayers))
      return (
        <Button
          startIcon={<AddIcon />}
          onClick={addMoreSublayer}
          variant="contained"
          disabled={!!isLoading}
          fullWidth
          color="oceanGreen"
        >
          Add Sub-Layer
        </Button>
      );
    return _.map(sublayers, (layer, index) => _renderFormLayer(layer, index));
  };

  const _renderGroups = () => {
    return _.map(allGroups, (g, index) => {
      return (
        <MenuItem key={index} value={g?.id}>
          {g?.name}
        </MenuItem>
      );
    });
  };

  const _renderForm = () => {
    return (
      <Grid container spacing={3} justifyContent="space-around">
        <Grid item xs={4}>
          <Dropzone
            onChange={(files) => {
              setFiles(files);
            }}
            accept=".geojson"
            label="Drag or Drop your files here"
            value={files}
            disableRipple
          >
            {!_.isEmpty(files) &&
              _.map(files, (file, index) => (
                <FileItem key={index} {...file} preview />
              ))}
          </Dropzone>
          <TextField
            sx={{ mt: 1 }}
            key="type"
            fullWidth
            select
            required
            value={fileType}
            disabled={!!isLoading}
            label="File type"
            onChange={(e) => {
              setFileType(e.target.value);
            }}
            size="small"
          >
            <MenuItem value="" disabled>
              -- Select File Type --
            </MenuItem>
            <MenuItem value={'line'}>Line</MenuItem>
            <MenuItem value={'point'}>Point</MenuItem>
            <MenuItem value={'polygon'}>Polygon</MenuItem>
          </TextField>
          <TextField
            sx={{ mt: 1 }}
            key="color"
            fullWidth
            placeholder="For example: red, #6096BA, rgb(123,211,155),..."
            value={parentColor}
            disabled={!!isLoading}
            label="Color"
            onChange={(e) => {
              setParentColor(e.target.value);
            }}
            size="small"
          />
          <TextField
            sx={{ mt: 1 }}
            key="groupId"
            fullWidth
            select
            required
            value={groupId}
            disabled={!!isLoading}
            label="Groups"
            onChange={(e) => {
              setGroupId(e.target.value);
            }}
            size="small"
          >
            {_renderGroups()}
          </TextField>
        </Grid>
        <Grid
          item
          xs={8}
          sx={{
            height: 400,
            p: 2,
            overflow: 'auto',
          }}
        >
          {_renderSublayers()}
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => onClose()}
      TransitionComponent={TransitionSlide}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>Upload Geojsons</DialogTitle>
      <form onSubmit={uploadFiles}>
        <DialogContent sx={{ overflow: 'unset' }}>
          {_renderForm()}
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<CloseIcon />}
            onClick={() => onClose()}
            variant="contained"
            disabled={!!isLoading}
            color="fuzzyWuzzyBrown"
          >
            Close
          </Button>
          <LoadingButton
            type="submit"
            startIcon={<SaveIcon />}
            loading={isLoading}
            variant="contained"
            color="burntSienna"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UploadModal;
