import React, { useState } from 'react';
import {
  Avatar,
  Container,
  Typography,
  Box,
  Grid,
  Link,
  Checkbox,
  FormControlLabel,
  TextField,
  Button,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Widgets from '@/Components/Widgets';

import { CommonColors } from '@/Themes';
import { AuthActions } from '@actions';
import { useTypedDispatch } from '@/store';
import { ROUTERS } from '@constants';
import Utils from '@/Utils';

const { login } = AuthActions;

const SignIn: React.FC = () => {
  // Constructors
  const dispatch = useTypedDispatch();
  const [loginForm, setLoginForm] = useState({
    account: '',
    password: '',
  });

  // Events
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    Utils.dispatchRequests([dispatch(login(loginForm))]);
  };

  const _renderCopyRight = () => (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      sx={{ mt: 8, mb: 4 }}
    >
      {'Copyright © '}
      <Link color="inherit" href="https://www.idracompany.com/">
        IDRA
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );

  const _renderInputs = () => (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus
        onChange={(e) =>
          setLoginForm({ ...loginForm, account: e.target.value })
        }
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        onChange={(e) =>
          setLoginForm({ ...loginForm, password: e.target.value })
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            value="remember"
            color="primary"
            onChange={(e) => Utils.saveIsRemember(e.target.checked)}
          />
        }
        label="Remember me"
      />
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Sign In
      </Button>
      <Grid container>
        <Grid item xs>
          <Link href={ROUTERS.FORGOT_PASSWORD} variant="body2">
            Forgot password?
          </Link>
        </Grid>
        {/* <Grid item>
          <Link href="#" variant="body2">
            {"Don't have an account? Sign Up"}
          </Link>
        </Grid> */}
      </Grid>
    </Box>
  );

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      bgcolor={CommonColors.mainBackground}
    >
      <Grid item>
        <Container maxWidth="xs" sx={{ border: 'solid 1px', padding: 3 }}>
          <Avatar sx={{ m: 1, bgcolor: CommonColors.fuzzyWuzzyBrown }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          {_renderInputs()}
        </Container>
      </Grid>
      {_renderCopyRight()}
      <Widgets.Notification />
      <Widgets.Loading />
      <Widgets.Alert />
    </Grid>
  );
};

export default SignIn;
