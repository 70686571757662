import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import {
  Avatar,
  Container,
  Typography,
  Box,
  Grid,
  TextField,
  Button,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Widgets from '@/Components/Widgets';

import { CommonColors } from '@/Themes';
import { AuthActions } from '@actions';
import { useTypedDispatch, RootState } from '@/store';
import { ROUTERS } from '@constants';
import Utils from '@/Utils';

const { resetPassword } = AuthActions;

const ResetPassword: React.FC = () => {
  // Constructors
  const email = useSelector((state: RootState) =>
    _.get(state.AUTH, 'forgotPasswordEmail')
  );
  const dispatch = useTypedDispatch();
  const [resetForm, setResetForm] = useState({
    code: '',
    newPassword: '',
    confirmationNewPassword: '',
  });

  useEffect(() => {
    if (!email) Utils.redirect(ROUTERS.AUTH);
  }, [email]);

  // Events
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    Utils.dispatchRequests([dispatch(resetPassword({ ...resetForm, email }))]);
  };

  const _renderInputs = () => (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email address"
        name="email"
        autoComplete="email"
        autoFocus
        value={email}
        disabled
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="code"
        label="Verify Code"
        id="code"
        autoComplete="current-password"
        onChange={(e) => setResetForm({ ...resetForm, code: e.target.value })}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        onChange={(e) =>
          setResetForm({ ...resetForm, newPassword: e.target.value })
        }
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="confirmationNewPassword"
        label="Confirmation New Password"
        type="password"
        id="confirmationNewPassword"
        autoComplete="current-password"
        onChange={(e) =>
          setResetForm({
            ...resetForm,
            confirmationNewPassword: e.target.value,
          })
        }
      />
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Send
      </Button>
    </Box>
  );

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      bgcolor={CommonColors.mainBackground}
    >
      <Grid item>
        <Container maxWidth="xs" sx={{ border: 'solid 1px', padding: 3 }}>
          <Avatar sx={{ m: 1, bgcolor: CommonColors.fuzzyWuzzyBrown }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          {_renderInputs()}
        </Container>
      </Grid>
      <Widgets.Notification />
      <Widgets.Loading />
      <Widgets.Alert />
    </Grid>
  );
};

export default ResetPassword;
