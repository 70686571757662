import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Breadcrumbs, Typography } from '@mui/material';
import _ from 'lodash';

import DefaultLayout from '@/Components/DefaultLayout';
import {
  UserManagementTable,
  UserInformationDialog,
} from '@/Components/LayoutPart';

import { UserManagementActions } from '@actions';
import { useTypedDispatch, RootState } from '@/store';
import Utils from '@/Utils';

// Declare constants
const { fetchUsers, resetUserReducer, setUserProfile } = UserManagementActions;

const UserManagement: React.FC = () => {
  // Constructors
  const dispatch = useTypedDispatch();
  const pagination = useSelector((state: RootState) =>
    _.get(state.USER_MANAGEMENT, 'pagination')
  );
  const userProfile = useSelector((state: RootState) =>
    _.get(state.USER_MANAGEMENT, 'userProfile')
  );

  useEffect(() => {
    Utils.dispatchRequests([dispatch(fetchUsers(pagination))], true);
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetUserReducer());
    };
  }, []);

  // Renders
  const renderMain = () => {
    return (
      <Grid container direction="column" padding={2} >
        <UserInformationDialog
          open={Boolean(userProfile)}
          userProfile={userProfile}
          handleClose={() => {
            dispatch(setUserProfile(null));
          }}
        />
        <Grid item mb={2}>
          <Breadcrumbs>
            <Typography variant="h5">Users Management</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <UserManagementTable />
        </Grid>
      </Grid>
    );
  };

  return <DefaultLayout mode="LANDING" content={renderMain()} />;
};

export default UserManagement;
