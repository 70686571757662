import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import {
  Box,
  Collapse,
  FormControlLabel,
  Checkbox,
  List,
  ListItemButton,
  ListItemText,
  CircularProgress,
  Divider,
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import TimelineIcon from '@mui/icons-material/Timeline';
import RectangleIcon from '@mui/icons-material/Rectangle';
import PlaceIcon from '@mui/icons-material/Place';

import { RootState, useTypedDispatch } from '@/store';
import { ILayersSettings } from '@interfaces/Menu.interface';
import { IGeojsonGroups } from '@interfaces/GIS.interface';
import { ISublayers } from '@interfaces/Geojson.interface';
import Utils from '@/Utils';
import { GISActions } from '@actions';

interface SectionProps {}

const { setSelectedGISLayers, fetchAllLayers } = GISActions;

const GISBarLayers: React.FC<SectionProps> = () => {
  // Constructors
  const dispatch = useTypedDispatch();
  const groupsStorage: IGeojsonGroups[] =
    useSelector((state: RootState) => _.get(state.GIS_DASHBOARD, 'layers')) ||
    {};
  const isLoadingRequest = useSelector((state: RootState) =>
    _.get(state.WIDGET, 'isLoading')
  );
  const selectedLayers: ILayersSettings[] =
    useSelector((state: RootState) =>
      _.get(state.GIS_DASHBOARD, 'selectedLayers')
    ) || [];
  const [groups, setGroups] = useState<IGeojsonGroups[]>();
  const [openItems, setOpenItems] = useState<string[]>([]);

  useEffect(() => {
    Utils.dispatchRequests([dispatch(fetchAllLayers())], true);
  }, []);

  useEffect(() => {
    setGroups(groupsStorage);
    let newSelectedLayers: ILayersSettings[] = [];
    _.forEach(groupsStorage, (l) => {
      newSelectedLayers = [...newSelectedLayers, ...l.layers];
    });
    dispatch(setSelectedGISLayers(newSelectedLayers));
  }, [groupsStorage]);

  // Events
  const handleOpenItems = (value: string) => {
    let currentItems = [...openItems];
    if (_.includes(openItems, value))
      currentItems = _.filter(currentItems, (el) => el !== value);
    else currentItems.push(value);
    setOpenItems(currentItems);
  };

  const handleCheckboxParent = (
    e: React.ChangeEvent<HTMLInputElement>,
    childId: string,
    parentId: string
  ) => {
    const newSelectedLayers: ILayersSettings[] = [...selectedLayers];
    const newGroups = _.map(groups, (group) => {
      const { id, layers } = group;
      if (id === parentId) {
        const newCheckedList = _.map(layers, (layer) => {
          if (layer.id === childId) {
            const { subLayers, parentLayer } = layer;
            const newSubLayers = _.map(subLayers, (i) => {
              return { ...i, isChecked: e.target.checked };
            });
            const newLayer = {
              ...layer,
              key: Math.random(),
              parentLayer: { ...parentLayer, isChecked: e.target.checked },
              subLayers: newSubLayers,
            };
            const index = _.findIndex(selectedLayers, layer);
            if (index === -1) newSelectedLayers.push(newLayer);
            else newSelectedLayers[index] = newLayer;
            return newLayer;
          }
          return layer;
        });
        return { ...group, layers: newCheckedList };
      }
      return group;
    });
    setGroups(newGroups);
    dispatch(setSelectedGISLayers(newSelectedLayers));
  };

  const handleCheckboxChildren = (
    e: React.ChangeEvent<HTMLInputElement>,
    original: ISublayers,
    childId: string,
    parentId: string
  ) => {
    const newSelectedLayers: ILayersSettings[] = [...selectedLayers];
    const newGroups = _.map(groups, (group) => {
      const { id, layers } = group;
      if (id === parentId) {
        const newCheckedList = _.map(layers, (layer) => {
          if (layer.id === childId) {
            const { subLayers, parentLayer } = layer;
            const index = _.findIndex(subLayers, original);
            const newSubLayers = [...subLayers];
            newSubLayers[index] = {
              ...original,
              isChecked: e.target.checked,
            };
            const totalSubItems = newSubLayers.length;
            const totalFalseItems = _.filter(newSubLayers, (i) => !i.isChecked);
            const isAllFalse = totalFalseItems.length === totalSubItems;
            const newLayer = {
              ...layer,
              key: Math.random(),
              parentLayer: { ...parentLayer, isChecked: !isAllFalse },
              subLayers: newSubLayers,
            };
            const parentIndex = _.findIndex(selectedLayers, layer);
            if (parentIndex === -1) newSelectedLayers.push(newLayer);
            else newSelectedLayers[parentIndex] = newLayer;
            return newLayer;
          }
          return layer;
        });
        return { ...group, layers: newCheckedList };
      }
      return group;
    });
    setGroups(newGroups);
    dispatch(setSelectedGISLayers(newSelectedLayers));
  };

  // Renders
  const _renderLegend = (type: string, color: string) => {
    switch (type) {
      case 'line':
        return <TimelineIcon sx={{ color }} />;
      case 'point':
        return <PlaceIcon sx={{ color }} />;
      default:
        return <RectangleIcon sx={{ color }} />;
    }
  };

  const _renderChildrens = (
    list: ISublayers[],
    parentId: string,
    outSideId: string,
    parentType?: string
  ) => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
        {_.map(list, (item, key) => {
          const { isChecked, name, color } = item;
          return (
            <FormControlLabel
              sx={{
                textTransform: 'capitalize',
              }}
              key={`${name}${key}`}
              label={
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  title={name}
                >
                  {_renderLegend(parentType || '', color)}
                  <Box
                    sx={{
                      ml: 2,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: 200,
                      lineClamp: 2,
                      WebkitLineClamp: 2,
                    }}
                  >
                    {name}
                  </Box>
                </Box>
              }
              control={
                <Checkbox
                  key={`${name}${key}`}
                  checked={isChecked}
                  onChange={(e) =>
                    handleCheckboxChildren(e, item, parentId, outSideId)
                  }
                />
              }
            />
          );
        })}
      </Box>
    );
  };

  const _renderLayers = (layers: ILayersSettings[], parentId: string) => {
    if (_.isEmpty(layers)) return null;
    return _.map(layers, (layer) => {
      const { id, subLayers, parentLayer } = layer;
      return (
        <Box key={id}>
          <ListItemButton
            onClick={() => handleOpenItems(id)}
            title={parentLayer.layerName}
          >
            <FormControlLabel
              label={
                _.isEmpty(subLayers) ? (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    title={parentLayer.layerName}
                  >
                    {_renderLegend(
                      parentLayer?.extraData?.type || '',
                      parentLayer?.color || ''
                    )}
                  </Box>
                ) : (
                  ''
                )
              }
              control={
                <Checkbox
                  key={id}
                  checked={parentLayer.isChecked}
                  indeterminate={!parentLayer.isChecked}
                  onChange={(e) => handleCheckboxParent(e, id, parentId)}
                  color="primary"
                />
              }
            />
            <ListItemText
              sx={{
                textTransform: 'capitalize',
              }}
              primary={
                <Box
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: 200,
                    lineClamp: 2,
                    WebkitLineClamp: 2,
                  }}
                >
                  {parentLayer.layerName}
                </Box>
              }
            />
            {!_.isEmpty(subLayers) &&
              (_.includes(openItems, id) ? <ExpandLess /> : <ExpandMore />)}
          </ListItemButton>
          {subLayers && (
            <Collapse
              in={_.includes(openItems, id)}
              timeout="auto"
              unmountOnExit
              key={id}
            >
              <List component="div" disablePadding>
                {_renderChildrens(
                  subLayers,
                  id,
                  parentId,
                  parentLayer?.extraData?.type
                )}
              </List>
            </Collapse>
          )}
        </Box>
      );
    });
  };

  const _renderGroups = useMemo(() => {
    if (_.isEmpty(groups)) return null;
    return _.map(groups, (group) => {
      const { id, name, layers } = group;
      return (
        <Box key={id}>
          <ListItemButton onClick={() => handleOpenItems(id)}>
            <ListItemText sx={{ textTransform: 'capitalize' }} primary={name} />
            {_.includes(openItems, id) ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          {!_.isEmpty(layers) && (
            <Collapse
              in={_.includes(openItems, id)}
              timeout="auto"
              unmountOnExit
              key={id}
            >
              <List component="div" disablePadding>
                {_renderLayers(layers, id)}
              </List>
            </Collapse>
          )}
          <Divider variant="middle" />
        </Box>
      );
    });
  }, [groups, openItems]);

  return (
    <List component="nav">
      {!isLoadingRequest && _renderGroups}
      {isLoadingRequest && <CircularProgress />}
    </List>
  );
};

export default GISBarLayers;
