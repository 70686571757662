import { SxProps, Theme } from '@mui/system';
import { CommonColors } from '@/Themes';

export const mainStyles: SxProps<Theme> = {
  color: CommonColors.bismark,
};

export const inputStyles: SxProps<Theme> = {
  background: 'white',
  borderRadius: 1,
};
