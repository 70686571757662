import LayersRoundedIcon from '@mui/icons-material/LayersRounded';
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';
// import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import PublicIcon from '@mui/icons-material/Public';
import LinkIcon from '@mui/icons-material/Link';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { IMenuSettings } from '@interfaces/Menu.interface';
import { ROUTERS } from '@constants';

const GISBAR_LIST: IMenuSettings[] = [
  {
    label: 'GIS',
    value: 'GIS',
    icon: <LayersRoundedIcon fontSize="small" />,
  },
  {
    label: 'settings',
    value: 'settings',
    icon: <PermDataSettingIcon fontSize="small" />,
  },
  {
    label: 'WMS links',
    value: 'WMS',
    icon: <LinkIcon fontSize="small" />,
  },
  // {
  //   label: 'print',
  //   value: 'print',
  //   icon: <LocalPrintshopIcon fontSize="small" />,
  // },
];

const SIDEBAR_LIST: IMenuSettings[] = [
  {
    label: 'GIS',
    value: ROUTERS.HOME,
    icon: <PublicIcon />,
  },
  {
    label: 'Users',
    value: ROUTERS.USER_MANAGEMENT,
    icon: <ManageAccountsIcon />,
  },
  {
    label: 'GFiles',
    value: ROUTERS.FILE_MANAGEMENT,
    icon: <CloudUploadIcon />,
  },
];

const MEASURE_LIST = {
  LENGTH_UNITS: ['feet', 'meters', 'miles', 'kilometers'],
  AREA_UNITS: ['acres', 'hectares', 'sqfeet', 'sqmeters', 'sqmiles'],
};

export default { GISBAR_LIST, MEASURE_LIST, SIDEBAR_LIST };
