import { ACTION_TYPES, ENUMS } from '@constants';

const DEFAULT_PAGINATION = {
  page: 1,
  limit: 25,
  orderBy: 'ASC',
  sortBy: '',
  keyword: '',
  status: ENUMS.STATUS.ACTIVE,
};

const initialState = {
  pagination: DEFAULT_PAGINATION,
  meta: null,
  groupTable: [],
  groupDetail: null,
  allGroups: []
};

export default (
  state = initialState,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.SET_GROUP_PAGINATION:
      return {
        ...state,
        pagination: payload,
      };
    case ACTION_TYPES.SET_GROUP_META:
      return {
        ...state,
        meta: payload,
      };
    case ACTION_TYPES.SET_GROUP_DETAIL:
      return {
        ...state,
        groupDetail: payload,
      };
    case ACTION_TYPES.RESET_GROUP_REDUCER:
      return {
        pagination: DEFAULT_PAGINATION,
        meta: null,
        groupTable: [],
        groupDetail: null,
      };

    case ACTION_TYPES.FETCH_GROUPS_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: true,
        groupTable: payload,
      };
    case ACTION_TYPES.FETCH_GROUPS_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
      };
    case ACTION_TYPES.GET_GROUP_BY_ID_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: true,
        groupDetail: payload,
      };
    case ACTION_TYPES.GET_GROUP_BY_ID_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
      };
    case ACTION_TYPES.CREATE_GROUP_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: true,
      };
    case ACTION_TYPES.CREATE_GROUP_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
      };
    case ACTION_TYPES.UPDATE_GROUP_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: true,
      };
    case ACTION_TYPES.UPDATE_GROUP_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
      };
    case ACTION_TYPES.DEACTIVE_GROUP_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: true,
      };
    case ACTION_TYPES.DEACTIVE_GROUP_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
      };
    case ACTION_TYPES.ACTIVE_GROUP_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: true,
      };
    case ACTION_TYPES.ACTIVE_GROUP_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
      };

    case ACTION_TYPES.FETCH_ALL_GROUP_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: true,
        allGroups: payload,
      };
    case ACTION_TYPES.FETCH_ALL_GROUP_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
      };
    default:
      return state;
  }
};
