import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import {
  Box,
  TableContainer,
  TableBody,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  TablePagination,
  IconButton,
  TableFooter,
  CircularProgress,
} from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useConfirm } from 'material-ui-confirm';
import { PaginationActions } from '@/Components/Common';

import { GFileManagementActions } from '@actions';
import { useTypedDispatch, RootState } from '@/store';
import {
  IPaginationFilter,
  IPaginationMeta,
} from '@interfaces/PaginationMeta.interface';
import Utils from '@/Utils';
import { CommonColors } from '@/Themes';
import { TABLES } from '@constants';

const { GEOJSON_TABLE_HEADER } = TABLES;
// Declare actions
const { fetchGeojsons, getGeojsonById, deactiveGeojson } =
  GFileManagementActions;

const GeojsonTable: React.FC = () => {
  // Declare reducers, dispatch
  const dispatch = useTypedDispatch();
  const confirm = useConfirm();
  const geojsonTable: any =
    useSelector((state: RootState) =>
      _.get(state.GEOJSON_MANAGEMENT, 'geojsonTable')
    ) || [];
  const meta: IPaginationMeta = useSelector((state: RootState) =>
    _.get(state.GEOJSON_MANAGEMENT, 'meta')
  );
  const pagination: IPaginationFilter = useSelector((state: RootState) =>
    _.get(state.GEOJSON_MANAGEMENT, 'pagination')
  );
  const isLoading = useSelector((state: RootState) =>
    _.get(state.WIDGET, 'isLoading')
  );
  const colSpan = GEOJSON_TABLE_HEADER.length + 1;

  // Events
  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    let page = pagination.page;
    if (newPage === 0) page = 1;
    else if (newPage < page) page -= 1;
    else if (newPage >= page) page += 1;
    Utils.dispatchRequests(
      [dispatch(fetchGeojsons({ ...pagination, page }))],
      true
    );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const perPage = parseInt(event.target.value, 10);
    Utils.dispatchRequests(
      [dispatch(fetchGeojsons({ ...pagination, limit: perPage, page: 1 }))],
      true
    );
  };

  const handleRemove = (id: string) => {
    confirm({ description: `This will permanently delete this file.` })
      .then(() => {
        Utils.dispatchRequests([dispatch(deactiveGeojson(id))]);
      })
      .then(async () => {
        await Utils.sleep(1000);
        Utils.dispatchRequests([dispatch(fetchGeojsons(pagination))], true);
      });
  };

  // Renders
  const _renderTableHeader = useMemo(() => {
    return (
      <TableRow>
        {_.map(GEOJSON_TABLE_HEADER, (item) => (
          <TableCell key={`head-${item.value}`}>{item.label}</TableCell>
        ))}
        <TableCell align="center" sx={{ color: CommonColors.astronaut }}>
          Actions
        </TableCell>
      </TableRow>
    );
  }, [GEOJSON_TABLE_HEADER]);

  const _renderTableBody = () => {
    if (_.isEmpty(geojsonTable))
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>There is no users to display</TableCell>
        </TableRow>
      );
    return _.map(geojsonTable, (item, index) => {
      return (
        <TableRow hover key={index}>
          {_.map(GEOJSON_TABLE_HEADER, (head, innerKey) => (
            <TableCell key={`row${innerKey}-${index}`} >
              {_.get(item, head.value)}
            </TableCell>
          ))}
          <TableCell key={`preview.${index}`} align="center">
            <IconButton
              sx={{ color: CommonColors.bismark }}
              onClick={() => {
                Utils.dispatchRequests([dispatch(getGeojsonById(item.id))]);
              }}
              title="Edit sublayers"
            >
              <ModeEditIcon />
            </IconButton>
            <IconButton
              sx={{ color: CommonColors.bismark }}
              onClick={() => handleRemove(item.id)}
              title="Remove"
            >
              <DeleteForeverIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    });
  };

  const _renderFooter = () => {
    if (!meta) return null;
    return (
      <TableFooter
        sx={{
          left: 0,
          bottom: 0,
          zIndex: 2,
          position: 'sticky',
          background: 'white',
        }}
      >
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            colSpan={colSpan}
            count={meta.totalItems}
            rowsPerPage={meta.itemsPerPage}
            page={meta.currentPage - 1}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={PaginationActions}
          />
        </TableRow>
      </TableFooter>
    );
  };

  const _renderTable = () => {
    return (
      <TableContainer
        component={Paper}
        sx={{ maxHeight: 'calc(100vh - 250px)', overflow: 'auto' }}
      >
        <Table stickyHeader>
          <TableHead>{_renderTableHeader}</TableHead>
          <TableBody>
            {!isLoading ? (
              _renderTableBody()
            ) : (
              <TableRow>
                <TableCell colSpan={colSpan} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {_renderFooter()}
        </Table>
      </TableContainer>
    );
  };

  return <Box>{_renderTable()}</Box>;
};

export default GeojsonTable;
