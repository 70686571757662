import React, { useState } from 'react';
import _ from 'lodash';
import {
  Box,
  Toolbar,
  Typography,
  MenuItem,
  Menu,
  Avatar,
  Tooltip,
  Button,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import UploadIcon from '@mui/icons-material/Upload';
import { CSSObject } from '@mui/material/styles';
import UploadModal from '../UploadModal';
import ChangePasswordDialog from '../ChangePassword';

import { appBarStyles, AppBar } from './AppBar.styles';
import Utils from '@utils';
import { LIST, ROUTERS, ENUMS } from '@constants';
import { useTypedDispatch } from '@/store';
import { AuthActions } from '@actions';

interface SectionProps {
  attributes?: CSSObject;
}

const { logout } = AuthActions;

const { USER_SETTINGS } = LIST;
const { ROLES } = ENUMS;

const AppBarComponent: React.FC<SectionProps> = () => {
  // Constructors
  const dispatch = useTypedDispatch();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const userData = Utils.getSavedUserData();
  const userRole = Utils.getUserRole() || ROLES.USER;

  // Events
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleClickSetting = (value: string) => {
    if (value === ROUTERS.AUTH) dispatch(logout());
    if (value === 'changePassword') setOpenChangePassword(true);
    else Utils.redirect(value);
  };

  // Renders
  const _renderSettings = () => {
    return _.map(USER_SETTINGS, (setting, index) => {
      return (
        <MenuItem key={index} onClick={() => handleClickSetting(setting.value)}>
          {setting.icon}
          <Typography align="center" sx={{ textTransform: 'capitalize' }}>
            {setting.label}
          </Typography>
        </MenuItem>
      );
    });
  };

  const _renderUserSettings = () => {
    return (
      <Box sx={{ flexGrow: 0, display: ' flex', alignItems: 'center' }}>
        {(userRole === ROLES.ADMIN || userRole === ROLES.SUPER_ADMIN) && (
          <Button
            sx={{ mr: 1, fontWeight: 'bold' }}
            startIcon={<UploadIcon />}
            onClick={() => setOpen(true)}
            variant="contained"
            color="burntSienna"
          >
            Upload
          </Button>
        )}
        <Typography sx={{ mr: 2 }}>{userData?.email || 'Amonyous'}</Typography>
        <Tooltip title="Open settings">
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar
              alt="userAvatar"
              src={userData?.avatar || 'https://picsum.photos/200'}
            />
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: '50px' }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={() => setAnchorElUser(null)}
        >
          {_renderSettings()}
        </Menu>
      </Box>
    );
  };

  return (
    <AppBar position="static" sx={appBarStyles}>
      <UploadModal open={open} handleClose={() => setOpen(false)} />
      <ChangePasswordDialog
        open={openChangePassword}
        handleClose={() => setOpenChangePassword(false)}
      />
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h5" component="div" fontWeight="bold">
            {import.meta.env.VITE_APP_TITLE.replace('_', '')}
          </Typography>
        </Box>
        {_renderUserSettings()}
      </Toolbar>
    </AppBar>
  );
};

export default AppBarComponent;
