import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import {
  Divider,
  Collapse,
  Button,
  FormControlLabel,
  Checkbox,
  List,
  ListItemText,
  ListItemButton,
  Box,
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import NewWMSLink from '../NewWMSLink';

import { useTypedDispatch, RootState } from '@/store';
import { GISActions } from '@actions';

import { IWMSLayer } from '@interfaces/GIS.interface';

// Actions
const { setWMSLayers } = GISActions;

const WMSLayers: React.FC = () => {
  // Constructors
  const dispatch = useTypedDispatch();
  const links: IWMSLayer[] =
    useSelector((state: RootState) =>
      _.get(state.GIS_DASHBOARD, 'WMSLayers')
    ) || [];
  const [linkState, setLinksState] = useState<IWMSLayer[]>([]);
  const [open, setIsOpen] = useState(false);
  const [openItems, setOpenItems] = useState<number[]>([]);

  useEffect(() => {
    setLinksState(links);
  }, [links]);

  // Events
  const handleRemoveLink = (item: IWMSLayer) => {
    const currentItems = [...linkState];
    const newItems = _.filter(currentItems, (i) => !_.isEqual(i, item));
    setLinksState(newItems);
    dispatch(setWMSLayers(newItems));
  };

  const addNewLink = (item: IWMSLayer) => {
    const newItems = [...linkState];
    newItems.push(item);
    setLinksState(newItems);
    const currentOpenItems = [...openItems];
    currentOpenItems.push(newItems.length - 1);
    setOpenItems(currentOpenItems);
  };

  const handleOpenItems = (value: number) => {
    let currentItems = [...openItems];
    if (_.includes(openItems, value))
      currentItems = _.filter(currentItems, (el) => el !== value);
    else currentItems.push(value);
    setOpenItems(currentItems);
  };

  const onShowLayer = (
    e: React.ChangeEvent<HTMLInputElement>,
    parentIndex: number,
    innerKey: number
  ) => {
    const newUpdateLinks = _.map(linkState, (link, index) => {
      if (parentIndex === index) {
        const newLayer = _.map(link.LAYERS, (l, childKey) => {
          if (childKey === innerKey)
            return { ...l, isChecked: e.target.checked };
          return l;
        });
        return { ...link, LAYERS: newLayer };
      }
      return link;
    });
    dispatch(setWMSLayers(newUpdateLinks));
  };

  // Renders

  const _renderLayers = (
    layers: { name: string; isChecked: boolean }[],
    parentIndex: number
  ) => {
    return _.map(layers, (layer, innerKey) => {
      return (
        <Box key={layer.name}>
          <ListItemButton>
            <FormControlLabel
              label=""
              control={
                <Checkbox
                  key={`checkbox${innerKey}${parentIndex}`}
                  checked={layer.isChecked}
                  onChange={(e) => onShowLayer(e, parentIndex, innerKey)}
                  color="primary"
                />
              }
            />
            <ListItemText
              primary={layer.name}
              title={layer.name}
              sx={{
                maxWidth: 250,
              }}
              primaryTypographyProps={{
                sx: {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                },
              }}
            />
          </ListItemButton>
        </Box>
      );
    });
  };

  const renderMain = () => {
    return _.map(linkState, (link, index) => {
      const { name, LAYERS } = link;
      return (
        <Box key={index}>
          <ListItemButton onClick={() => handleOpenItems(index)}>
            <ListItemText
              sx={{ fontWeight: 'bold' }}
              primaryTypographyProps={{ sx: { fontWeight: 'bold' } }}
              primary={`${index + 1}. ${name}`}
            />
            {_.includes(openItems, index) ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          {!_.isEmpty(LAYERS) && (
            <Collapse
              in={_.includes(openItems, index)}
              timeout="auto"
              unmountOnExit
              key={index}
            >
              <List component="div" disablePadding>
                {_renderLayers(LAYERS, index)}
              </List>
            </Collapse>
          )}
          <Button
            variant="contained"
            color="fuzzyWuzzyBrown"
            onClick={() => handleRemoveLink(link)}
          >
            <DeleteIcon />
          </Button>
          <Divider variant="middle" sx={{ marginY: 1 }} />
        </Box>
      );
    });
  };

  return (
    <List component="nav">
      <NewWMSLink
        open={open}
        handleClose={() => setIsOpen(false)}
        callbackPayload={(p) => addNewLink(p)}
      />
      {renderMain()}
      <Button
        variant="contained"
        color="burntSienna"
        fullWidth
        onClick={() => setIsOpen(true)}
      >
        New Link
      </Button>
    </List>
  );
};

export default WMSLayers;
