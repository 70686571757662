import _ from 'lodash';
import { ACTION_TYPES } from '@constants';

// SINGLE ACTIONS
const setSidebarMeasure = (payload: { [key: string]: string }) => {
  return {
    type: ACTION_TYPES.SET_SIDEBAR_MEASURE,
    payload,
  };
};

const setMeasureStatus = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_MEASURE_STATUS,
    payload,
  };
};

export default {
  setSidebarMeasure,
  setMeasureStatus,
};
