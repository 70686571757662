export default {
  mainBackground: 'linear-gradient(60deg, #29323c 0%, #485563 100%)',
  mainColor: 'rgb(52, 71, 103)',
  burntSienna: '#E87844',
  bismark: '#517284',
  pickledBluewood: '#324A58',
  loblolly: '#C2D2D6',
  oceanGreen: '#4BB180',
  fuzzyWuzzyBrown: '#C95454',
  diSerria: '#DA9555',
  mystic: '#E7ECEF',
  astronaut: '#274C77',
  hippieBlue: '#6096BA',
  perano: '#A3CEF1',
  lemonGrass: '#8B8C89',
};
