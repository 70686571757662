const TITLE_LAYERS = {
  LIVE: 'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
  BLACK_WHITE: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
  STREET: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
};

const CRS_CODES = {
  DEFAULT_CODE: {
    CODE: 'EPSG:4236',
    PROPERTIES: '+proj=longlat +datum=WGS84 +no_defs',
  },
  AL_CODE: {
    CODE: 'EPSG:6870',
    PROPERTIES:
      '+proj=tmerc +lat_0=0 +lon_0=20 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
  },
  WGS84_32634_CODE: {
    CODE: 'EPSG:32634',
    PROPERTIES: '+proj=utm +zone=34 +datum=WGS84 +units=m +no_defs +type=crs',
  },
};

const DEFAULT_WGS84 = {
  type: 'name',
  properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' },
};

const POSITION_GIS_CLASSES = {
  bottomleft: 'leaflet-bottom leaflet-left',
  bottomright: 'leaflet-bottom leaflet-right',
  topleft: 'leaflet-top leaflet-left',
  topright: 'leaflet-top leaflet-right',
};

export default { TITLE_LAYERS, CRS_CODES, POSITION_GIS_CLASSES, DEFAULT_WGS84 };
