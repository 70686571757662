import { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import {
  useMap,
  LayersControl,
  GeoJSON,
  Popup,
  TileLayer,
  WMSTileLayer,
  useMapEvents,
} from 'react-leaflet';
import * as L from 'leaflet';

import {
  Grid,
  TableContainer,
  TableBody,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  Button,
} from '@mui/material';
import TableChartIcon from '@mui/icons-material/TableChart';

import { GIS_TYPES } from '@constants';
import { useTypedDispatch, RootState } from '@/store';
import { GISActions } from '@actions';
import {
  IGeoJsonObjectCollection,
  IGISFeature,
  IWMSLayer,
} from '@interfaces/GIS.interface';
import { ILayersSettings } from '@interfaces/Menu.interface';
import { ISublayers } from '@interfaces/Geojson.interface';

// Declare constants
const { TITLE_LAYERS } = GIS_TYPES;
const { setTableAttributeData, setMoveToTargetCoordinates } = GISActions;

interface ILayerControls {
  titleLayer: string;
}

// const DEFAULT_ICON_URL =
//   'https://img.icons8.com/external-flat-icons-inmotus-design/67/000000/external-Dot-geo-points-flat-icons-inmotus-design.png';

const LayerControls = (props: ILayerControls) => {
  const { titleLayer } = props;
  const parentMap = useMap();
  const dispatch = useTypedDispatch();
  const geojsonDownloaded: IGeoJsonObjectCollection[] =
    useSelector((state: RootState) =>
      _.get(state.GIS_DASHBOARD, 'geojsonDownloaded')
    ) || [];
  const selectedLayers: ILayersSettings[] =
    useSelector((state: RootState) =>
      _.get(state.GIS_DASHBOARD, 'selectedLayers')
    ) || [];
  const moveToCoordinates: any | null = useSelector((state: RootState) =>
    _.get(state.GIS_DASHBOARD, 'moveToCoordinates')
  );
  const WMSLinks: IWMSLayer[] =
    useSelector((state: RootState) =>
      _.get(state.GIS_DASHBOARD, 'WMSLayers')
    ) || [];
  const [selectedFeature, setSelectedFeature] = useState<IGISFeature | null>(
    null
  );
  const [isShowMap, setIsShowMap] = useState<boolean>(true);
  const [selectedGeojson, setSelectedGeojson] =
    useState<IGeoJsonObjectCollection | null>(null);
  let intervalResize: any = null;

  useEffect(() => {
    intervalResize = setInterval(() => {
      parentMap.invalidateSize();
    }, 1000);
    return () => {
      if (intervalResize) clearInterval(intervalResize);
    };
  }, []);

  useEffect(() => {
    if (moveToCoordinates) {
      const coords = L.GeoJSON.coordsToLatLng(moveToCoordinates);
      parentMap.flyTo(coords, 17);
      dispatch(setMoveToTargetCoordinates(null));
    }
  }, [moveToCoordinates]);

  useMapEvents({
    zoomstart: () => {
      setIsShowMap(false);
    },
    zoomend: () => {
      setIsShowMap(true);
    },
    dragstart: () => {
      setIsShowMap(false);
    },
    dragend: () => {
      setIsShowMap(true);
    },
  });

  // Events
  const setPolyLineStyles = (
    feature: IGISFeature,
    subLayers: ISublayers[],
    parentColor?: string
  ): any => {
    const { properties } = feature;
    if (!_.isEmpty(subLayers)) {
      let color = 'none';
      let opacity = 0;
      let fillOpacity = 0;
      let isHide = true;
      let display = 'none';
      _.forEach(subLayers, (sub) => {
        if (_.get(properties, sub.key) === sub.value && sub.isChecked) {
          color = sub.color;
          opacity = 1;
          fillOpacity = 0.4;
          isHide = false;
        }
      });
      return {
        color,
        fillColor: color,
        opacity,
        weight: 2,
        fillOpacity,
        isHide,
      };
    }
    return {
      color: parentColor || 'aqua',
      fillColor: parentColor || 'aqua',
      weight: 2,
      opacity: 1,
      fillOpacity: 0.4,
      isHide: false,
    };
  };

  const generateIcon = (iconColor: string) => {
    const svgIcon = `
      <svg xmlns="http://www.w3.org/2000/svg" fill=${iconColor} width="25" height="25" viewBox="0 0 24 24" >
        <path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"/>
      </svg>
    `;
    return L.divIcon({
      className: 'icon-marker',
      iconSize: [25, 25],
      html: svgIcon,
    });
  };

  const getMarkerOpacity = (feature: IGISFeature, subLayers: ISublayers[]) => {
    const { properties } = feature;
    let opacity = 1;
    if (!_.isEmpty(subLayers)) {
      let flag = 0;
      _.forEach(subLayers, (sub) => {
        if (_.get(properties, sub.key) === sub.value && sub.isChecked) flag = 1;
      });
      opacity = flag;
    }
    return opacity;
  };

  const setPointIcon = (
    feature: IGISFeature,
    latlng: L.LatLng,
    subLayers: ISublayers[],
    parentColor?: string
  ) => {
    const { properties } = feature;
    let iconColor = parentColor || 'red';
    if (!_.isEmpty(subLayers)) {
      _.forEach(subLayers, (sub) => {
        if (_.get(properties, sub.key) === sub.value) iconColor = sub.color;
      });
      return L.marker(latlng, { icon: generateIcon(iconColor) });
    }
    return L.marker(latlng, { icon: generateIcon(iconColor) });
  };

  const handleOpenTable = () => {
    if (selectedGeojson) dispatch(setTableAttributeData(selectedGeojson));
  };

  // Renders
  const _renderTitleLayers = useMemo(() => {
    return _.map(TITLE_LAYERS, (title, key) => {
      return (
        <LayersControl.BaseLayer
          key={title}
          name={key}
          checked={titleLayer === title}
        >
          <TileLayer url={title} noWrap={true} />
        </LayersControl.BaseLayer>
      );
    });
  }, [titleLayer]);

  const _renderFeatureProperties = () => {
    const properties = selectedFeature?.properties || [];
    if (_.isEmpty(properties))
      return (
        <TableRow hover>
          <TableCell colSpan={2}>No properties</TableCell>
        </TableRow>
      );
    return _.map(properties, (item, index) => {
      return (
        <TableRow hover key={index}>
          <TableCell>{index}</TableCell>
          <TableCell align="left">{item}</TableCell>
        </TableRow>
      );
    });
  };

  const _renderPopup = () => {
    return (
      <Popup closeButton={false}>
        <Grid container flexDirection="column">
          <Grid item>
            <TableContainer
              component={Paper}
              sx={{
                width: 300,
                boxShadow: 'none',
                maxHeight: 400,
                overflow: 'auto',
              }}
            >
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Attributes</TableCell>
                    <TableCell align="left">Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{_renderFeatureProperties()}</TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item>
            <Button
              endIcon={<TableChartIcon />}
              fullWidth
              sx={{ mt: 1 }}
              size="small"
              variant="contained"
              onClick={() => handleOpenTable()}
              color="info"
            >
              View full table of attributes
            </Button>
          </Grid>
        </Grid>
      </Popup>
    );
  };

  const onEachFeature = (
    feature: IGISFeature,
    layer: L.Layer,
    json: IGeoJsonObjectCollection,
    subLayers: ISublayers[],
    parentColor?: string
  ) => {
    let isCanClick = true;
    if (layer instanceof L.Polygon) {
      const styles = setPolyLineStyles(feature, subLayers, parentColor);
      layer.setStyle(styles);
      if (!styles?.isHide) {
        layer.on('mouseout', () => {
          layer.setStyle(styles);
        });
        layer.on('mouseover', () => {
          layer.setStyle({
            fillColor: 'yellow',
            fillOpacity: 0.8,
          });
        });
      } else isCanClick = false;
    }

    if (layer instanceof L.Polyline) {
      const styles = setPolyLineStyles(feature, subLayers, parentColor);
      layer.setStyle({ ...styles, weight: 2 });
      if (!styles?.isHide) {
        layer.on('mouseout', () => {
          layer.setStyle({ ...styles, weight: 2 });
        });
        layer.on('mouseover', () => {
          layer.setStyle({
            ...styles,
            color: 'yellow',
          });
        });
      } else isCanClick = false;
    }

    if (layer instanceof L.Marker) {
      const opacity = getMarkerOpacity(feature, subLayers);
      layer.setOpacity(opacity);
    }

    layer.on('click', () => {
      if (isCanClick) {
        setSelectedFeature(feature);
        setSelectedGeojson(json);
      }
    });

    layer.on('popupclose', () => {
      setSelectedFeature(null);
      setSelectedGeojson(null);
    });
  };

  const _renderLayerGeojsons = () => {
    if (!isShowMap) return null;
    return _.map(selectedLayers, (layer) => {
      const { id, subLayers, parentLayer, key } = layer;
      const geojson = _.find(geojsonDownloaded, (l) => l.id === id);
      if (geojson) {
        return (
          <LayersControl.Overlay
            key={key}
            name={geojson.name}
            checked={parentLayer.isChecked}
          >
            <GeoJSON
              data={geojson}
              onEachFeature={(f: IGISFeature, l) =>
                onEachFeature(f, l, geojson, subLayers, parentLayer?.color)
              }
              pointToLayer={(f, l) =>
                setPointIcon(f, l, subLayers, parentLayer?.color)
              }
            >
              {_renderPopup()}
            </GeoJSON>
          </LayersControl.Overlay>
        );
      }
      return null;
    });
  };

  const _renderWMSLayers = useMemo(() => {
    return _.map(WMSLinks, (title) => {
      const { name, url, LAYERS } = title;
      let layerWillShow = '';
      _.each(LAYERS, (l) => {
        if (l.isChecked)
          layerWillShow = layerWillShow.concat(
            layerWillShow ? ',' : '',
            l.name
          );
      });
      const titleProps = {
        ..._.omit(title, ['name', 'url', 'LAYERS']),
        LAYERS: layerWillShow,
      };
      return (
        <LayersControl.Overlay key={Math.random()} name={name} checked>
          <WMSTileLayer key={name} {...titleProps} url={url} crossOrigin />
        </LayersControl.Overlay>
      );
    });
  }, [WMSLinks]);

  return (
    <LayersControl>
      {_renderTitleLayers}
      {_renderWMSLayers}
      {_renderLayerGeojsons()}
    </LayersControl>
  );
};

export default LayerControls;
