import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Navigate, useLocation } from 'react-router';
import jwt_decode from 'jwt-decode';
import { useSelector } from 'react-redux';
import i18next from 'i18next';

import { ROUTERS, ENUMS, ADMIN_ROUTERS } from '@constants';

import { RootState, useTypedDispatch } from '@/store';
import { AuthActions } from '@actions';
import Utils from '@utils';

interface ISectionProps {
  children: JSX.Element;
  location: string;
}

// Declare constants
const { ROLES } = ENUMS;

// Declare actions
const { checkRefreshToken } = AuthActions;

const ProtectedRoute: React.FC<ISectionProps> = ({ children, location }) => {
  // Constructors
  const dispatch = useTypedDispatch();
  const pathname = useLocation().pathname;
  const token = Utils.getSavedToken();
  const isRemember = Utils.getSaveIsRemember();
  const refreshSecureToken = Utils.getSavedRefreshToken();
  const locale = Utils.getSaveLocale();
  const userRole = Utils.getUserRole() || ROLES.USER;
  const isRefreshTokenSuccess = useSelector((state: RootState) =>
    _.get(state.AUTH, 'isRefreshTokenSuccess')
  );
  const [isValidRoute, setIsValidRoute] = useState(true);

  const checkTokenLife = (t: string) => {
    const decodedToken: any = jwt_decode(t);
    const dateNow = new Date();
    if (decodedToken.exp < Math.floor(dateNow.getTime() / 1000)) return false;
    return true;
  };

  useEffect(() => {
    const currentLocale = i18next.language;
    if (currentLocale !== locale) {
      Utils.saveLocale(locale);
      i18next.changeLanguage(locale);
    }
    if (!userRole || (isRemember && !refreshSecureToken)) {
      Utils.clearAllSavedData();
      setIsValidRoute(false);
    }
  }, []);

  useEffect(() => {
    let isTokenValid = true;
    if (token) isTokenValid = checkTokenLife(token);
    if (
      ((!isRefreshTokenSuccess && !token) || !isTokenValid) &&
      refreshSecureToken
    )
      dispatch(checkRefreshToken({ refreshToken: refreshSecureToken }));
  }, [isRefreshTokenSuccess]);

  const checkPathPermission = () => {
    const isAdminPath = _.find(
      ADMIN_ROUTERS,
      (route: string) => route === location
    );
    if (userRole === ROLES.USER && isAdminPath) return false;
    return true;
  };

  if (!isValidRoute || !checkPathPermission())
    return <Navigate to={`${ROUTERS.AUTH}?redirect=${pathname}`} replace />;

  return children;
};

export default ProtectedRoute;
