import { sendRequest } from '@/Configs';
import { API } from '@constants';
import { IPaginationFilter } from '@interfaces/PaginationMeta.interface';

const { GEOJSON } = API;

export const fetchGeojsons = async (payload: IPaginationFilter) => {
  return await sendRequest(GEOJSON.ROOT, 'GET', payload);
};

export const getGeojsonById = async (id: string) => {
  return await sendRequest(`${GEOJSON.GET_BY_ID}${id}`, 'GET');
};

export const uploadGeojson = async (payload: FormData) => {
  return await sendRequest(`${GEOJSON.ROOT}`, 'FORM_DATA', payload);
};

export const updateGeojson = async (payload: FormData, id: string) => {
  return await sendRequest(`${GEOJSON.ROOT}${id}`, 'PUT_FORM_DATA', payload);
};

export const activeGeojson = async (id: string) => {
  return await sendRequest(`${GEOJSON.ACTIVE}${id}`, 'PUT');
};

export const deactiveGeojson = async (id: string) => {
  return await sendRequest(`${GEOJSON.DEACTIVE}${id}`, 'PUT');
};
