import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import {
  TextField,
  MenuItem,
  Grid,
  Typography,
  InputAdornment,
  Button,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import { LIST } from '@constants';
import { useTypedDispatch, RootState } from '@/store';
import { GFileManagementActions } from '@actions';
import { IPaginationFilter } from '@interfaces/PaginationMeta.interface';
import Utils from '@/Utils';

// Actions
const { setGeojsonPagination, fetchGeojsons } = GFileManagementActions;

interface SectionProps {}

const GFileManagementBar: React.FC<SectionProps> = () => {
  // Constructors
  const dispatch = useTypedDispatch();
  const paginationFilter: IPaginationFilter = useSelector((state: RootState) =>
    _.get(state.GEOJSON_MANAGEMENT, 'pagination')
  );
  const [keyword, setKeyword] = useState<string>('');

  useEffect(() => {}, []);

  // Events

  // Renders
  // const _renderMenu = () => {
  //   return _.map(LIST.FILTER_LIST.GEOJSON_SORT_FILTERS, (option, index) => (
  //     <MenuItem
  //       key={index}
  //       value={option.value}
  //       sx={{ textTransform: 'capitalize' }}
  //     >
  //       {option.label}
  //     </MenuItem>
  //   ));
  // };

  const _renderFilterOptions = () => {
    return (
      <Grid container spacing={3} justifyContent="space-around">
        <Grid item xs={12}>
          <TextField
            fullWidth
            size="small"
            label="Keywords...."
            value={keyword}
            onChange={(e) => {
              setKeyword(e.target.value);
              // dispatch(
              //   setGeojsonPagination({
              //     ...paginationFilter,
              //     keyword: e.target.value,
              //   })
              // );
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon
                    onClick={() => {
                      Utils.dispatchRequests([
                        dispatch(
                          fetchGeojsons({ ...paginationFilter, keyword })
                        ),
                      ]);
                    }}
                  />
                </InputAdornment>
              ),
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter')
                Utils.dispatchRequests([
                  dispatch(fetchGeojsons({ ...paginationFilter, keyword })),
                ]);
            }}
          />
        </Grid>
        {/* <Grid item xs={6}>
          <TextField
            fullWidth
            size="small"
            label="Date from"
            value={paginationFilter?.dateFrom}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            size="small"
            label="Date to"
            value={paginationFilter?.dateTo}
            disabled
          />
        </Grid> */}
        {/* <Grid item xs={6}>
          <TextField
            fullWidth
            select
            size="small"
            label="Status"
            value={paginationFilter?.status}
            onChange={(e) => {
              dispatch(
                setGeojsonPagination({
                  ...paginationFilter,
                  status: e.target.value,
                })
              );
            }}
          >
            {_renderMenu()}
          </TextField>
        </Grid> */}
        <Grid item xs={6}>
          <TextField
            fullWidth
            select
            size="small"
            label="Order By"
            value={paginationFilter?.orderBy}
            onChange={(e) => {
              dispatch(
                setGeojsonPagination({
                  ...paginationFilter,
                  orderBy: e.target.value,
                })
              );
            }}
          >
            <MenuItem value="ASC">ASC</MenuItem>
            <MenuItem value="DESC">DESC</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={12}>
          <Button
            startIcon={<SearchIcon />}
            onClick={() => {
              Utils.dispatchRequests([
                dispatch(fetchGeojsons({ ...paginationFilter, keyword })),
              ]);
            }}
            variant="contained"
            color="burntSienna"
          >
            Apply Now
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container direction="column" paddingX={2}>
      <Grid item sx={{ mb: 2 }}>
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs={1}>
            <FilterAltIcon />
          </Grid>
          <Grid item xs={8}>
            <Typography component="h1" variant="button">
              Filters:
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>{_renderFilterOptions()}</Grid>
    </Grid>
  );
};

export default GFileManagementBar;
